<template>
  <div class="content">
    <h1 class="f-bold">IN A PATIENT SATISFACTION SURVEY:</h1>
    <div>
      <img class="daily-improvement-img" src="@/assets/images/jpg-png/daily-improvement-img.png" alt="" />
      <img class="number-93-percent" src="@/assets/images/jpg-png/93-percent.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.roughbumpymodalone > .modal-layout__content {
    background: url(~@/assets/images/jpg-png/upper-bg-home.png) no-repeat;
    border: 1px solid #fff;
    border-radius: 8px;
    display: flex;
    .content {
        width: 800px;
        display: inline-block;
        margin: 20px;
        h1 {
            font-size: 30px;
            margin: 20px;
        }
        .number-93-percent {
            width: 360px;
            margin-top: 30px;
            margin-left: 40px;
            margin-bottom: -50px;        
        }
        .daily-improvement-img {
          width: 290px;
          margin-left: 80px;
        }
    }
}

</style>