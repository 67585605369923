<template>
  <div class="content">
    <h1 class="f-bold">
      SIGNIFICANTLY IMPROVES SKIN BARRIER FUNCTION<br>FOR UP TO 4 HOURS
    </h1>
    <img src="@/assets/images/svg/tewl-measurements-after-single-application.svg" alt="" />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.soothingmoisturizinglotionmodal {
  .modal-layout__content {
    padding: 25px 38px;
    h1 {
      font-size: 26px;
      margin: 0;
      letter-spacing: 0.2px;
      text-transform: uppercase;
      line-height: 30px;
      margin-top: 4px;
    }
    img {
      width: 591px;
      display: block;
      margin: 40px auto;
      margin-top: 40px;
      margin-bottom: 0;
    }
  }
}
</style>
