var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-layout',[_c('div',{staticClass:"content"},[_c('div',{staticClass:"main-heading"},[_c('h1',[_vm._v("Sun, Baby & Special Benefits")])]),_c('div',{staticClass:"secondary-heading"},[_c('h4',{staticClass:"text-short-description"},[_vm._v(" Cetaphil's Special Skin Benefits offer patients added skin benefits to help them manage "),_c('br'),_vm._v(" their sensitive skin ")])]),_c('div',{staticClass:"content-products",staticStyle:{"margin-top":"35px"}},[_c('div',{staticClass:"products-card-one-upper",staticStyle:{"display":"flex"}},[_c('div',{staticClass:"container-img-product"},[_c('a',{on:{"click":function($event){return _vm.openDetail('special-benefits:sheer-mineral-sun-screen-face')}}},[_c('img',{staticClass:"img-product",staticStyle:{"margin-top":"-13px","margin-left":"-25px"},attrs:{"src":require("@/assets/images/jpg-png/smflsf50-img.png"),"height":"119","alt":""}}),_c('img',{staticClass:"internal-icon",staticStyle:{"margin-bottom":"-23px","margin-left":"-32px","cursor":"pointer"},attrs:{"src":require("@/assets/images/jpg-png/int-link-icon.png"),"alt":""}})])]),_c('div',{staticClass:"container-img-product"},[_c('a',{on:{"click":function($event){return _vm.openDetail('special-benefits:sheer-mineral-stick')}}},[_c('img',{staticClass:"img-product",staticStyle:{"margin-top":"-3px","margin-left":"-20px"},attrs:{"src":require("@/assets/images/jpg-png/smsssp50-img.png"),"width":"53","alt":""}}),_c('img',{staticClass:"internal-icon",staticStyle:{"width":"19px","margin-bottom":"-25px","margin-left":"-35px","cursor":"pointer"},attrs:{"src":require("@/assets/images/jpg-png/int-link-icon.png"),"alt":""}})])]),_c('div',{staticClass:"container-img-product"},[_c('a',{attrs:{"target":"_system"},on:{"click":function($event){return _vm.openLink(
                'https://www.cetaphil.com/us/sunscreens/sheer-mineral-sunscreen-broad-spectrum-spf-30/302994111008.html'
              )},"touchend":function($event){return _vm.openLink(
                'https://www.cetaphil.com/us/sunscreens/sheer-mineral-sunscreen-broad-spectrum-spf-30/302994111008.html'
              )}}},[_c('img',{staticClass:"img-product",staticStyle:{"margin-top":"-15px","margin-left":"-10px"},attrs:{"src":require("@/assets/images/jpg-png/csf30ltn-img.png"),"width":"44","alt":""}}),_c('br'),_c('img',{staticClass:"external-icon",staticStyle:{"margin-top":"-4px","margin-left":"-5px","cursor":"pointer"},attrs:{"src":require("@/assets/images/jpg-png/ext-link-icon.png"),"alt":""}})])]),_c('div',{staticClass:"container-img-product"},[_c('a',{attrs:{"target":"_system"},on:{"click":function($event){return _vm.openLink(
                'https://www.cetaphil.com/us/sunscreens/sheer-mineral-sunscreen-broad-spectrum-spf-50/302994112005.html'
              )},"touchend":function($event){return _vm.openLink(
                'https://www.cetaphil.com/us/sunscreens/sheer-mineral-sunscreen-broad-spectrum-spf-50/302994112005.html'
              )}}},[_c('img',{staticClass:"img-product",staticStyle:{"margin-top":"-15px","margin-left":"-10px"},attrs:{"src":require("@/assets/images/jpg-png/spf50-lotion.png"),"width":"44","alt":""}}),_c('br'),_c('img',{staticClass:"external-icon",staticStyle:{"margin-top":"-5px","margin-left":"-6px","cursor":"pointer"},attrs:{"src":require("@/assets/images/jpg-png/ext-link-icon.png"),"alt":""}})])])]),_c('div',{staticClass:"products-card-one-main",staticStyle:{"display":"flex"}},[_c('a',{staticClass:"archor-card-1",attrs:{"href":"#"}},[_vm._v("Mineral Sunscreen for Sensitive Skin")])]),_c('div',{staticClass:"products-card-two-upper",staticStyle:{"display":"flex"}},[_c('div',{staticClass:"container-img-product"},[_c('a',{attrs:{"target":"_system"},on:{"click":function($event){return _vm.openLink(
                'https://www.cetaphil.com/us/moisturizers/facial-moisturizers-serums/deep-hydration-skin-restoring-water-gel/302990117073.html'
              )},"touchend":function($event){return _vm.openLink(
                'https://www.cetaphil.com/us/moisturizers/facial-moisturizers-serums/deep-hydration-skin-restoring-water-gel/302990117073.html'
              )}}},[_c('img',{staticClass:"img-product",staticStyle:{"margin-top":"35px","margin-left":"-25px"},attrs:{"src":require("@/assets/images/jpg-png/water-gel.png"),"width":"88","alt":""}}),_c('br'),_c('img',{staticClass:"external-icon",staticStyle:{"margin-top":"-5px","margin-left":"2px","cursor":"pointer"},attrs:{"src":require("@/assets/images/jpg-png/ext-link-icon.png"),"alt":""}})])]),_c('div',{staticClass:"container-img-product"},[_c('a',{attrs:{"target":"_system"},on:{"click":function($event){return _vm.openLink(
                'https://www.cetaphil.com/us/moisturizers/deep-hydration-48-hour-activation-serum/302990117004.html'
              )},"touchend":function($event){return _vm.openLink(
                'https://www.cetaphil.com/us/moisturizers/deep-hydration-48-hour-activation-serum/302990117004.html'
              )}}},[_c('img',{staticClass:"img-product",staticStyle:{"margin-top":"-30px","margin-left":"-8px"},attrs:{"src":require("@/assets/images/jpg-png/dhs48-img.png"),"width":"37","alt":""}}),_c('br'),_c('img',{staticClass:"external-icon",staticStyle:{"margin-top":"-5px","margin-left":"-6px","cursor":"pointer"},attrs:{"src":require("@/assets/images/jpg-png/ext-link-icon.png"),"alt":""}})])]),_c('div',{staticClass:"container-img-product"},[_c('a',{attrs:{"target":"_system"},on:{"click":function($event){return _vm.openLink(
                'https://www.cetaphil.com/us/moisturizers/deep-hydration-healthy-glow-daily-cream/302990117011.html'
              )},"touchend":function($event){return _vm.openLink(
                'https://www.cetaphil.com/us/moisturizers/deep-hydration-healthy-glow-daily-cream/302990117011.html'
              )}}},[_c('img',{staticClass:"img-product",staticStyle:{"margin-top":"39px","margin-left":"-15px"},attrs:{"src":require("@/assets/images/jpg-png/dhhgdc-img.png"),"width":"88","alt":""}}),_c('img',{staticClass:"external-icon",staticStyle:{"margin-top":"-5px","margin-left":"12px","cursor":"pointer"},attrs:{"src":require("@/assets/images/jpg-png/ext-link-icon.png"),"alt":""}})])]),_c('div',{staticClass:"container-img-product"},[_c('a',{attrs:{"target":"_system"},on:{"click":function($event){return _vm.openLink(
                'https://www.cetaphil.com/us/eye-creams/deep-hydration-refreshing-eye-serum/302990100624.html'
              )},"touchend":function($event){return _vm.openLink(
                'https://www.cetaphil.com/us/eye-creams/deep-hydration-refreshing-eye-serum/302990100624.html'
              )}}},[_c('img',{staticClass:"img-product",staticStyle:{"margin-top":"-28px","margin-left":"-2px"},attrs:{"src":require("@/assets/images/jpg-png/dhres-img.png"),"width":"36","alt":""}}),_c('img',{staticClass:"external-icon",staticStyle:{"margin-top":"-5px","margin-left":"-1px","cursor":"pointer"},attrs:{"src":require("@/assets/images/jpg-png/ext-link-icon.png"),"alt":""}})])])]),_c('div',{staticClass:"products-card-two-main",staticStyle:{"display":"flex"}},[_c('a',{staticClass:"archor-card-2",attrs:{"href":"#"}},[_vm._v("Dry or Dehydrated Skin")])]),_c('div',{staticClass:"products-card-three-upper",staticStyle:{"display":"flex"}},[_c('div',{staticClass:"container-img-product"},[_c('a',{attrs:{"target":"_system"},on:{"click":function($event){return _vm.openLink(
                'https://www.cetaphil.com/us/products/skin-concern/anti-aging/healthy-renew-face-serum/302990260045.html'
              )},"touchend":function($event){return _vm.openLink(
                'https://www.cetaphil.com/us/products/skin-concern/anti-aging/healthy-renew-face-serum/302990260045.html'
              )}}},[_c('img',{staticClass:"img-product",staticStyle:{"margin-top":"-30px","margin-left":"-20px"},attrs:{"src":require("@/assets/images/jpg-png/face-serum-peptides.png"),"width":"38","alt":""}}),_c('br'),_c('img',{staticClass:"external-icon",staticStyle:{"margin-top":"-6px","margin-left":"-20px","cursor":"pointer"},attrs:{"src":require("@/assets/images/jpg-png/ext-link-icon.png"),"alt":""}})])]),_c('div',{staticClass:"container-img-product"},[_c('a',{attrs:{"target":"_system"},on:{"click":function($event){return _vm.openLink(
                'https://www.cetaphil.com/us/products/skin-concern/anti-aging/healthy-renew-eye-gel-serum/302990260021.html'
              )},"touchend":function($event){return _vm.openLink(
                'https://www.cetaphil.com/us/products/skin-concern/anti-aging/healthy-renew-eye-gel-serum/302990260021.html'
              )}}},[_c('img',{staticClass:"img-product",staticStyle:{"margin-top":"-22px","margin-left":"-25px"},attrs:{"src":require("@/assets/images/jpg-png/eye-gel-serum.png"),"width":"38","alt":""}}),_c('img',{staticClass:"external-icon",staticStyle:{"margin-top":"-6px","margin-left":"-36px","margin-bottom":"-36px","cursor":"pointer"},attrs:{"src":require("@/assets/images/jpg-png/ext-link-icon.png"),"alt":""}})])]),_c('div',{staticClass:"container-img-product"},[_c('a',{attrs:{"target":"_system"},on:{"click":function($event){return _vm.openLink(
                'https://www.cetaphil.com/us/products/skin-concern/anti-aging/healthy-renew-day-cream-spf-30/302994136001.html'
              )},"touchend":function($event){return _vm.openLink(
                'https://www.cetaphil.com/us/products/skin-concern/anti-aging/healthy-renew-day-cream-spf-30/302994136001.html'
              )}}},[_c('img',{staticClass:"img-product",staticStyle:{"margin-top":"-8px","margin-left":"-30px"},attrs:{"src":require("@/assets/images/jpg-png/day-cream-broad.png"),"width":"30","alt":""}}),_c('br'),_c('img',{staticClass:"external-icon",staticStyle:{"margin-top":"-4px","margin-left":"-33px","cursor":"pointer"},attrs:{"src":require("@/assets/images/jpg-png/ext-link-icon.png"),"alt":""}})])]),_c('div',{staticClass:"container-img-product"},[_c('a',{attrs:{"target":"_system"},on:{"click":function($event){return _vm.openLink('https://www.cetaphil.com/us/products/skin-concern/anti-aging/healthy-renew-night-cream/302990260014.html')},"touchend":function($event){return _vm.openLink('https://www.cetaphil.com/us/products/skin-concern/anti-aging/healthy-renew-night-cream/302990260014.html')}}},[_c('img',{staticClass:"img-product",staticStyle:{"margin-top":"35px","margin-left":"-48px"},attrs:{"src":require("@/assets/images/jpg-png/night-cream.png"),"width":"90","alt":""}}),_c('br'),_c('img',{staticClass:"external-icon",staticStyle:{"margin-top":"-4px","margin-left":"-20px","cursor":"pointer"},attrs:{"src":require("@/assets/images/jpg-png/ext-link-icon.png"),"alt":""}})])])]),_c('div',{staticClass:"products-card-three-main",staticStyle:{"display":"flex"}},[_c('a',{staticClass:"archor-card-3",attrs:{"href":"#"}},[_vm._v("Healthy Renew")])]),_c('div',{staticClass:"products-card-four-upper",staticStyle:{"display":"flex"}},[_c('div',{staticClass:"container-img-product"},[_c('a',{attrs:{"target":"_system"},on:{"click":function($event){return _vm.openLink(
                'https://www.cetaphil.com/us/moisturizers/healthy-radiance-antioxidant-c-serum/302990116038.html'
              )},"touchend":function($event){return _vm.openLink(
                'https://www.cetaphil.com/us/moisturizers/healthy-radiance-antioxidant-c-serum/302990116038.html'
              )}}},[_c('img',{staticClass:"img-product",staticStyle:{"margin-top":"-28px","margin-left":"10px"},attrs:{"src":require("@/assets/images/jpg-png/hraoxis-img.png"),"width":"36","height":"133","alt":""}}),_c('img',{staticClass:"external-icon",staticStyle:{"margin-top":"-5px","margin-left":"9px","cursor":"pointer"},attrs:{"src":require("@/assets/images/jpg-png/ext-link-icon.png"),"alt":""}})])]),_c('div',{staticClass:"container-img-product"},[_c('a',{attrs:{"target":"_system"},on:{"click":function($event){return _vm.openLink('https://www.cetaphil.com/us/moisturizers/healthy-radiance-whipped-day-cream-spf-30/302994118007.html')},"touchend":function($event){return _vm.openLink('https://www.cetaphil.com/us/moisturizers/healthy-radiance-whipped-day-cream-spf-30/302994118007.html')}}},[_c('img',{staticClass:"img-product",staticStyle:{"margin-top":"32px","margin-left":"-15px"},attrs:{"src":require("@/assets/images/jpg-png/hrwdc-img.png"),"width":"90","alt":""}}),_c('img',{staticClass:"external-icon",staticStyle:{"margin-top":"-4px","margin-left":"10px","cursor":"pointer"},attrs:{"src":require("@/assets/images/jpg-png/ext-link-icon.png"),"alt":""}})])]),_c('div',{staticClass:"container-img-product"},[_c('a',{attrs:{"target":"_system"},on:{"click":function($event){return _vm.openLink(
                'https://www.cetaphil.com/us/moisturizers/healthy-radiance-renewing-cream/302990116014.html'
              )},"touchend":function($event){return _vm.openLink(
                'https://www.cetaphil.com/us/moisturizers/healthy-radiance-renewing-cream/302990116014.html'
              )}}},[_c('img',{staticClass:"img-product",staticStyle:{"margin-top":"35px","margin-left":"5px"},attrs:{"src":require("@/assets/images/jpg-png/radiance-renewing-cream.png"),"width":"88","alt":""}}),_c('img',{staticClass:"external-icon",staticStyle:{"margin-top":"-6px","margin-left":"32px","cursor":"pointer"},attrs:{"src":require("@/assets/images/jpg-png/ext-link-icon.png"),"alt":""}})])]),_c('div',{staticClass:"container-img-product"},[_c('a',{attrs:{"target":"_system"},on:{"click":function($event){return _vm.openLink(
                'https://www.cetaphil.com/us/cleansers/healthy-radiance-gentle-pha-exfoliating-cleanser/302990116021.html'
              )},"touchend":function($event){return _vm.openLink(
                'https://www.cetaphil.com/us/cleansers/healthy-radiance-gentle-pha-exfoliating-cleanser/302990116021.html'
              )}}},[_c('img',{staticClass:"img-product",staticStyle:{"margin-top":"-8px","margin-left":"-2px"},attrs:{"src":require("@/assets/images/jpg-png/hrgphaec-img.png"),"width":"43","alt":""}}),_c('img',{staticClass:"external-icon",staticStyle:{"margin-top":"-6px","margin-left":"-39px","margin-bottom":"-36px","cursor":"pointer"},attrs:{"src":require("@/assets/images/jpg-png/ext-link-icon.png"),"alt":""}})])])]),_c('div',{staticClass:"products-card-four-main",staticStyle:{"display":"flex"}},[_c('a',{staticClass:"archor-card-4",attrs:{"href":"#"}},[_vm._v("Dull or Uneven Skin")])]),_c('div',{staticClass:"products-card-five-upper",staticStyle:{"display":"flex"}},[_c('div',{staticClass:"container-img-product"},[_c('a',{attrs:{"target":"_system"},on:{"click":function($event){return _vm.openLink(
                'https://www.cetaphil.com/us/baby-skincare/baby-daily-lotion/302993936039.html'
              )},"touchend":function($event){return _vm.openLink(
                'https://www.cetaphil.com/us/baby-skincare/baby-daily-lotion/302993936039.html'
              )}}},[_c('img',{staticClass:"img-product",staticStyle:{"margin-top":"-45px","margin-left":"-25px"},attrs:{"src":require("@/assets/images/jpg-png/products/baby-skincare/Baby Daily Lotion_13-5oz-01@2x.png"),"width":"62","alt":""}}),_c('img',{staticClass:"external-icon",staticStyle:{"margin-top":"-4px","margin-left":"-12px","cursor":"pointer"},attrs:{"src":require("@/assets/images/jpg-png/ext-link-icon.png"),"alt":""}})])]),_c('div',{staticClass:"container-img-product"},[_c('a',{attrs:{"target":"_system"},on:{"click":function($event){return _vm.openLink('https://www.cetaphil.com/us/baby-skincare/baby-eczema-soothing-lotion/302994140053.html')},"touchend":function($event){return _vm.openLink('https://www.cetaphil.com/us/baby-skincare/baby-eczema-soothing-lotion/302994140053.html')}}},[_c('img',{staticClass:"img-product",staticStyle:{"margin-top":"-45px","margin-left":"-14px"},attrs:{"src":require("@/assets/images/jpg-png/products/baby-skincare/Baby Eczema Soothing Lotion_10oz-01@2x.png"),"width":"52.5","alt":""}}),_c('img',{staticClass:"external-icon",staticStyle:{"margin-top":"-4px","margin-left":"-6px","cursor":"pointer"},attrs:{"src":require("@/assets/images/jpg-png/ext-link-icon.png"),"alt":""}})])]),_c('div',{staticClass:"container-img-product"},[_c('a',{attrs:{"target":"_system"},on:{"click":function($event){return _vm.openLink(
                'https://www.cetaphil.com/us/baby-skincare/baby-gentle-wash/302993936091.html'
              )},"touchend":function($event){return _vm.openLink(
                'https://www.cetaphil.com/us/baby-skincare/baby-gentle-wash/302993936091.html'
              )}}},[_c('img',{staticClass:"img-product",staticStyle:{"margin-top":"-50px","margin-left":"-14px"},attrs:{"src":require("@/assets/images/jpg-png/products/baby-skincare/Baby Gentle Wash_7-8oz-01@2x.png"),"width":"65.5","alt":""}}),_c('img',{staticClass:"external-icon",staticStyle:{"margin-top":"-6px","margin-left":"2px","cursor":"pointer"},attrs:{"src":require("@/assets/images/jpg-png/ext-link-icon.png"),"alt":""}})])]),_c('div',{staticClass:"container-img-product"},[_c('a',{attrs:{"target":"_system"},on:{"click":function($event){return _vm.openLink(
                'https://www.cetaphil.com/us/baby-skincare/baby-soothing-wash/302990200058.html'
              )},"touchend":function($event){return _vm.openLink(
                'https://www.cetaphil.com/us/baby-skincare/baby-soothing-wash/302990200058.html'
              )}}},[_c('img',{staticClass:"img-product",staticStyle:{"margin-top":"-45px","margin-left":"-12px"},attrs:{"src":require("@/assets/images/jpg-png/products/baby-skincare/Baby Soothing Wash_10oz-01@2x.png"),"width":"53","alt":""}}),_c('br'),_c('img',{staticClass:"external-icon",staticStyle:{"margin-top":"-6px","margin-left":"-4px","margin-bottom":"-15px","cursor":"pointer"},attrs:{"src":require("@/assets/images/jpg-png/ext-link-icon.png"),"alt":""}})])]),_c('div',{staticClass:"container-img-product"},[_c('a',{attrs:{"target":"_system"},on:{"click":function($event){return _vm.openLink(
                'https://www.cetaphil.com/us/baby-skincare/baby-wash-and-shampoo/302993936077.html'
              )},"touchend":function($event){return _vm.openLink(
                'https://www.cetaphil.com/us/baby-skincare/baby-wash-and-shampoo/302993936077.html'
              )}}},[_c('img',{staticClass:"img-product",staticStyle:{"margin-top":"-43px","margin-left":"-12px"},attrs:{"src":require("@/assets/images/jpg-png/products/baby-skincare/Baby Wash & Shampoo_13-5oz-01@2x.png"),"width":"62","height":"151","alt":""}}),_c('img',{staticClass:"external-icon",staticStyle:{"margin-top":"-6px","margin-left":"4px","margin-bottom":"-15px","cursor":"pointer"},attrs:{"src":require("@/assets/images/jpg-png/ext-link-icon.png"),"alt":""}})])]),_c('div',{staticClass:"container-img-product"},[_c('a',{attrs:{"target":"_system"},on:{"click":function($event){return _vm.openLink(
                'https://www.cetaphil.com/us/baby-skincare/baby-soothe-and-protect-cream/302994103058.html'
              )},"touchend":function($event){return _vm.openLink(
                'https://www.cetaphil.com/us/baby-skincare/baby-soothe-and-protect-cream/302994103058.html'
              )}}},[_c('img',{staticClass:"img-product",staticStyle:{"margin-top":"-2px","margin-left":"-16px"},attrs:{"src":require("@/assets/images/jpg-png/products/baby-skincare/Baby Sooth and Protect Cream_6oz-01@2x.png"),"width":"49.5","height":"109","alt":""}}),_c('img',{staticClass:"external-icon",staticStyle:{"margin-top":"-6px","margin-left":"-9px","margin-bottom":"-15px","cursor":"pointer"},attrs:{"src":require("@/assets/images/jpg-png/ext-link-icon.png"),"alt":""}})])])]),_c('div',{staticClass:"products-card-five-main",staticStyle:{"display":"flex"}},[_c('a',{staticClass:"archor-card-5",attrs:{"href":"#"}},[_vm._v("Baby's Sensitive Skin")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }