<template>
  <page-layout>
    <div class="content slide-swipe">
      <div class="heading">
        <h1 class="main-section-title">MOISTURIZING LOTION</h1>
        <p class="description" v-html="texts_keys[key]"></p>
      </div>
      <div class="back-button">
        <a @click="goLastCategory" @touchend="goLastCategory">
          <img
            src="@/assets/images/jpg-png/back-button.png"
            width="39.5"
            alt=""
          />
        </a>
      </div>
      <div class="content-product-row">
        <div :class="['image-product', { 'extra-top': key == 'features' }]">
          <img
            src="@/assets/images/jpg-png/products/daily-moisturizers/details/Moisturizing Lotion HBL_16oz-01 (1)@2x.png"
            width="309"
            alt=""
          />
        </div>
        <div class="feature-icons" v-show="key === 'benefits'">
          <img src="@/assets/images/jpg-png/97-percent.png" alt="" />
          <img src="@/assets/images/jpg-png/48-hours.png" alt="" />
          <img src="@/assets/images/jpg-png/sensitive-skin-icon.png" alt="" />
        </div>
        <div class="feature-info" v-show="key === 'benefits'">
          <img
            class="face-body-icons"
            src="@/assets/images/jpg-png/body-face-icons.png"
            alt=""
          />
          <div class="feature-info-actions">
            <a
              class="hidden"
              @click="openModal('MoisturizingLotionModalOne')"
              href="#"
            >
              <img src="@/assets/images/jpg-png/see-data-img.png" alt="" />
            </a>
            <a @click="openModal('MoisturizingLotionModalTwo')" href="#">
              <img src="@/assets/images/jpg-png/see-data-img.png" alt="" />
            </a>
            <a @click="openModal('MoisturizingLotionModalThree')" href="#">
              <img src="@/assets/images/jpg-png/see-data-img.png" alt="" />
            </a>
          </div>
          <h3 class="f-bold">
            For dry to normal, sensitive skin
          </h3>
          <div class="feature-info-skin-oil">
            <p class="f-light">
              97% of subjects said Cetaphil Moisturizing Lotion <br />
              Provides
              <span class="f-bold uppercase">immediate relief</span> for their
              sensitive skin
            </p>
          </div>
          <div class="feature-info-hours-barrier">
            <p class="f-light">
              Continuous, <span class="f-bold">48-HOUR HYDRATION</span> after
              a<br />single application
            </p>
          </div>
          <div class="feature-info-sensitive-skin">
            <p class="f-light">
              <span class="f-bold">COMPLETELY RESTORES SKIN BARRIER</span> after
              1<br />week of daily use
            </p>
          </div>
          <div class="hydration-study">
            <img
              src="@/assets/images/svg/hidratation-study-button.svg"
              alt=""
              @click="openModal('MoisturizingLotionModalHydrationStudy')"
              @touchend="openModal('MoisturizingLotionModalHydrationStudy')"
            />
          </div>
        </div>
        <div class="feature-features" v-show="key === 'features'">
          <div class="feature-features-feature">
            <h3 class="f-bold">Key Features</h3>
            <ul class="f-light">
              <li>
                <img
                  src="@/assets/images/jpg-png/polygon-mark.png"
                  width="8"
                  alt=""
                />
                Lightweight formula with Vitamin E
              </li>
              <li>
                <img
                  src="@/assets/images/jpg-png/polygon-mark.png"
                  width="8"
                  alt=""
                />
                Proven safe and well tolerated
              </li>
              <li>
                <img
                  src="@/assets/images/jpg-png/polygon-mark.png"
                  width="8"
                  alt=""
                />
                No fragrances, sulfates, or parabens
              </li>
              <li>
                <img
                  src="@/assets/images/jpg-png/polygon-mark.png"
                  width="8"
                  alt=""
                />
                Non-comedogenic, Hypoallergenic
              </li>
            </ul>
          </div>
          <div class="feature-features-ingredients">
            <h3 class="f-bold">Ingredients</h3>
            <p class="f-light">
              Water, <span class="f-bold">Glycerin</span>, Isopropyl Palmitate,
              Cetearyl Alcohol,<br />
              Ceteareth-20,
              <span class="f-bold">Panthenol, Niacinamide,</span> Tocopheryl
              Acetate, <br />
              Dimethicone, Persea Gratissima (Avocado) Oil, Helianthus <br />
              Annuus (Sunflower) Seed oil, Pantolactone, Glyceryl <br />
              Stearate, Sodium Benzoate, Benzyl Alcohol, Citric Acid
            </p>
          </div>
        </div>
      </div>
      <div class="product-nav">
        <ul>
          <li>
            <a
              @click="toggleKey('benefits')"
              @touchend="toggleKey('benefits')"
              :class="{ active: key == 'benefits' }"
              >Key Benefits</a
            >
          </li>
          <li>
            <a
              @click="toggleKey('features')"
              @touchend="toggleKey('features')"
              :class="{ active: key == 'features' }"
              >Key Features</a
            >
          </li>
        </ul>
      </div>
    </div>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/common/PageLayout.vue';
import { sendTracking } from '@/mixins/tracking.js';

export default {
  components: {
    PageLayout,
  },
  data() {
    return {
      key: 'benefits',
      texts_keys: {
        benefits:
          'Lightweight and fast-absorbing, clinically proven to completely restore the<br>skin barrier in 1 week',
        features: 'Designed for dry to normal, sensitive skin',
      },
    };
  },
  mounted() {
    this.$route.params.onSwipeLeft = this.onSwipeLeft;
    this.$route.params.onSwipeRight = this.onSwipeRight;
  },
  methods: {
    onSwipeLeft() {
      return this.toggleKey('features');
    },
    onSwipeRight() {
      return this.toggleKey('benefits');
    },
    toggleKey(newKey) {
      const OPTIONS_KEY = new Array('benefits', 'features');
      if (OPTIONS_KEY.includes(newKey)) {
        this.key = newKey;
        sendTracking(newKey === 'benefits' ? 840 : 843);
      }
    },
    openModal(modalName) {
      this.toggleModal(modalName);
    },
  },
};
</script>

<style lang="scss">
.daily-moisturizers.lotion-details {
  background: url(~@/assets/images/jpg-png/upper-bg-home.png) no-repeat;
  background-size: 100%;
  .content {
    margin-left: 50.5px;
    margin-top: 38px;
    .back-button {
      position: absolute;
      top: 139px;
    }
    .main-section-title {
      line-height: 35.5px;
      margin-bottom: 17px;
    }
    .description {
      line-height: 16.5px;
      sup {
        font-size: 66%;
        font-family: 'Sofia Pro';
        line-height: 11px;
        vertical-align: top;
        margin-left: -0.5px;
      }
    }
    &-product-row {
      display: flex;
      margin-top: -25px;
      .image-product {
        margin-top: 68px;
        margin-left: 13px;
        &.extra-top {
          margin-top: 84px;
        }
      }
      .feature-icons {
        display: flex;
        flex-direction: column;
        margin-top: 90px;
        margin-left: 34px;
        margin-right: 36.5px;
        img {
          width: 108px;
          margin-bottom: 19px;
          &:nth-child(2) {
            margin-bottom: 10px;
          }
        }
      }
      .hydration-study {
        position: absolute;
        bottom: -65px;
        right: 135px;
        z-index: 9999;
        img {
          width: 220px;
        }
      }
      .feature-info {
        .face-body-icons {
          width: 111px;
          position: absolute;
          right: -80px;
          top: 25px;
        }
        margin-top: 35px;
        color: $main-font-color;
        position: relative;
        h3 {
          margin: 0;
          margin-top: 20px;
          margin-bottom: 50px;
          letter-spacing: 0.1px;
          sup {
            font-size: 66%;
            font-family: 'Sofia Pro';
            line-height: 25px;
            vertical-align: top;
            margin-left: -0.5px;
          }
        }
        &-tags {
          position: absolute;
          right: -45px;
          top: 25px;
          display: inline-flex;
          .tag {
            display: flex;
            font-family: 'Sofia Pro Bold';
            border: 1px solid #c8ddea;
            border-radius: 9px;
            font-size: 7.5px;
            text-transform: uppercase;
            width: 39px;
            height: 15.5px;
            margin-right: 3px;
            align-items: center;
            justify-content: center;
          }
        }
        &-actions {
          position: absolute;
          top: 74px;
          left: 339px;
          img {
            width: 70px;
            margin-bottom: 49px;
          }
        }
        &-skin-oil {
          margin-bottom: 44px;
          padding-bottom: 41px;
          border-bottom: 1px solid rgb(200, 221, 234);
          letter-spacing: -0.5px;
          line-height: 13px;
          sup {
            font-size: 60%;
            font-family: 'Sofia Pro';
            line-height: 12px;
            vertical-align: top;
            margin-left: 0.5px;
          }
        }
        &-hours-barrier {
          border-bottom: 1px solid rgb(200, 221, 234);
          padding-bottom: 31px;
          margin-bottom: 35px;
          letter-spacing: -0.5px;
          line-height: 16px;
          sup {
            font-size: 60%;
            font-family: 'Sofia Pro';
            line-height: 12px;
            vertical-align: top;
            margin-left: 0.5px;
          }
        }
        &-sensitive-skin {
          letter-spacing: -0.56px;
          line-height: 16px;
          sup {
            font-size: 60%;
            font-family: 'Sofia Pro';
            line-height: 12px;
            vertical-align: top;
            margin-left: 0.5px;
          }
        }
      }
    }
    .feature-features {
      color: $main-font-color;
      margin: 78px 0px 0px 109px;
      &-feature {
        border-bottom: 1px solid rgb(200, 221, 234);
        padding-bottom: 31px;
        margin-bottom: 29px;
        margin-right: 10px;
        h3 {
          font-family: 'Sofia Pro Bold';
          font-size: 20px;
          margin: 0px;
          letter-spacing: -0.38px;
          margin-bottom: 15px;
        }
        ul {
          margin: 0;
          padding: 0;
          li {
            font-size: 15px;
            font-family: 'Sofia Pro Light';
            list-style: none;
            margin-bottom: 0px;
            line-height: 17px;
            img {
              display: inline-block;
              margin-right: 5px;
              margin-left: 2px;
            }
            &:first-child {
              text-indent: -18px;
              margin-left: 19px;
            }
          }
        }
      }
      &-ingredients {
        h3 {
          font-family: 'Sofia Pro Bold';
          font-size: 20px;
          margin: 0px;
          letter-spacing: -0.38px;
          margin-bottom: 7px;
        }
        p {
          margin: 0px;
          letter-spacing: -0.5px;
          line-height: 1;
          .f-bold {
            letter-spacing: -0.5px;
          }
        }
      }
    }
    .product-nav {
      position: absolute;
      z-index: 9999;
      bottom: 127px;
      left: 121px;
      border: 1px solid $light-blue;
      box-shadow: 0px 9px 14px rgba($color: $gray-backdrop, $alpha: 0.06);
      border-radius: 8px;
      overflow: hidden;
      ul {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
        li {
          list-style: none;
          > a {
            font-size: 12.5px;
            color: $main-font-color;
            padding: 10px 12.5px;
            line-height: 1;
            display: inline-block;
            font-family: 'Sofia Pro';
            &.active {
              background: $light-blue-2;
              font-family: 'Sofia Pro Bold';
              color: $dark-blue;
            }
          }
          &:first-child {
            border-right: 1px solid $light-blue;
          }
        }
      }
    }
  }
}
</style>
