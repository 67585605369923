<template>
  <div>
    <div id="app">
      <v-touch
        v-on:swipeleft="onSwipeLeft"
        v-on:swiperight="onSwipeRight"
        ref="tapper"
      >
        <router-view />
      <div class="footer">
        <img
          v-if="isHomePage"
          class="nav-cover"
          src="@/assets/images/jpg-png/cover-nav-bg-blue.png"
          alt=""
        />
        <img
          v-else
          class="nav-cover"
          src="@/assets/images/jpg-png/cover-nav-bg.png"
          alt=""
        />
        <main-navigation></main-navigation>
      </div>
      </v-touch>
    </div>
    <div id="modals">
      <modal-layout :show="modal.isVisible" :on-close="toggleModal">
        <component :is="modal.component"></component>
      </modal-layout>
    </div>
  </div>
</template>
<script>
import MainNavigation from '@/components/common/MainNav';
import ModalLayout from '@/components/common/ModalLayout.vue';

export default {
  components: {
    ModalLayout,
    MainNavigation,
  },
  methods: {
    onSwipeLeft(event) {
      var path = event.srcEvent.composedPath();
      var slideSwipe = this.blockSwipe;
      if (slideSwipe) {
        this.$route.params.onSwipeLeft();
      }else{
        if (this.lastPage === this.currentPage) {
          this.next = 0;
        } else {
          this.next = this.currentPage + 1;
        }
        
        if (this.currentPage == 0){
          return this.$router.push({ name: 'homepage:pioneering-extensive-research' });
        }else{
          this.$router.push(this.routes[this.next]);
        }
      }
    },
    onSwipeRight(event) {
      var path = event.srcEvent.composedPath();
      var slideSwipe = this.blockSwipe;
      if (slideSwipe) {
        this.$route.params.onSwipeRight();
      }else{
        if (this.currentPage === 0) {
          this.prev = this.lastPage;
        } else {
          this.prev = this.currentPage - 1;
        }
        if (this.currentPage == 1){
          return this.$router.push({ name: 'homepage:exceptional-hydratation' });
        }else{
          this.$router.push(this.routes[this.prev]);
        }
      }
    },
  },
  computed: {
    currentPage() {
      this.routes.forEach((route, i) => {
        if (route.children) {
          if (route.children[0].name === this.$route.name) {
            // eslint-disable-next-line
            this.page = i;
          }
        }else{
          if (route.name === this.$route.name) {
            // eslint-disable-next-line
            this.page = i;
          }
        }
      });
      return this.page;
    },
    mainNav() {
      return this.$store.state.mainNav;
    },
    modal() {
      return this.$store.state.modal;
    },
    blockSwipe(){
    return this.$route.name.includes(":");
    },
    isHomePage() {
      return this.$route.path === '/';
    },
  },
  beforeMount() {
    this.routes = [];
    this.$router.options.routes.forEach((route) => {
      this.routes.push(route);
    });
    this.lastPage = this.routes.length - 1;
    const previousRoute = localStorage.getItem('cetaphilV1.actualUrl');
    if (previousRoute) {
      const routeExists = this.$router.resolve(previousRoute);
      if (routeExists.resolved.matched.length > 0) {
        this.$router.push(previousRoute);
      }
    }
  },
  watch: {
    $route: function(route) {
      localStorage.setItem('cetaphilV1.actualUrl', route.fullPath);
    },
  },
};
</script>
<style lang="scss">
#app {
  width: 1024px;
  height: 768px;
  overflow: hidden;
  .footer {
    background: url(~@/assets/images/jpg-png/nav-bg.png) no-repeat;
    background-size: contain;
    background-position: 0 31px;
    display: flex;
    flex-direction: column;
    justify-self: end;
    font-family: 'Sofia Pro SemiBold';
    font-weight: 600;
    font-style: normal;
    position: absolute;
    width: 100%;
    height: 162px;
    bottom: 44px;
    z-index: 999;
    .nav-cover {
      position: absolute;
      width: 100%;
      top: 18px;
      z-index: 3;
    }
  }
}

body {
  // Font "Smooth" on Photoshop
  -webkit-font-smoothing: antialiased;
}
</style>
