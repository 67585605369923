<template>
  <div class="content">
    <h1 class="f-bold">
      Significantly restores skin barrier at Day 3 and completely restores by
      day 7 of use
    </h1>
    <h2>TEWL variation from baseline normalized by stripping at T0</h2>
    <img src="@/assets/images/svg/ml-modal-three-graph.svg" alt="" />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.moisturizinglotionmodalthree {
  .modal-layout__content {
    padding: 25px 38px;
    h1 {
      font-size: 26px;
      margin: 0;
      letter-spacing: 0.2px;
      text-transform: uppercase;
      line-height: 30px;
      margin-top: 4px;
    }
    h2 {
      text-align: center;
      font-size: 18px;
      letter-spacing: -0.95px;
      margin: 56px 0 27px -18px;
      color: $gray-second-text;
    }
    img {
      width: 691px;
      display: block;
      margin: 0 auto;
    }
  }
}
</style>
