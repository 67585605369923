<template>
    <page-layout>
        <div class="content">
            <div class="heading">
                <h1 class="main-title">Proven Efficacy</h1>
                <p class="description">
                    Our science backed skincare formulations actively improve sensitive skin’s<br>
                    defense and resilience for a hydrated and fortified skin barrier.<sup>5-6-7</sup>
                </p>
            </div>

            <div class="container">
                <div class="column frst-col">
                  <div class="row">
                    <img 
                        src="@/assets/images/svg/resilience-skin.svg" 
                        alt="resilience skin"
                    >
                    <p>Improves the resilience of <br>sensitive skin<sup>5</sup></p>
                  </div>
                  <div class="row">
                    <img 
                        src="@/assets/images/svg/five-skin-sensitive-icon.svg" 
                        alt="five skin sensitive icon"
                    >
                    <p>
                        Defend against 5 signs of skin<br>
                        sensitivity: Dryness, irritation,<br>
                        roughness, tightness and<br>
                        a weakened skin barrier.<sup>13-16</sup><br>
                    </p>
                  </div>
                </div>
                
                <div class="column scnd-col">
                    <img 
                        src="@/assets/images/jpg-png/composition-1.png"
                        alt="composition"
                        width="202px"
                    >
                </div>
            
                <div class="column three-col">
                  <div class="row">
                    <img 
                        src="@/assets/images/jpg-png/formulation-2.png"
                        alt="formulation 2"
                    >
                    <div>
                        <h3>+GLYCERIN</h3>
                        <p class="subtitle">to help prevent dryness</p>
                        <p class="desc">Powerful humectant that pulls<br>
                        in moisture to hydrate the skin<sup>8-12</sup></p>
                        <hr>
                    </div>
                  </div>
                  <div class="row">
                    <img 
                        src="@/assets/images/jpg-png/formulation-3.png"
                        alt="formulation 3"
                    >
                    <div>
                        <h3>+NIACINAMIDE</h3>
                        <p class="subtitle">to strengthen skin barrier</p>
                        <p class="desc">Widely known for its ability to<br>
                        strengthen skin barrier integritiy<sup>8-12</sup></p>
                        <hr>
                    </div>
                  </div>
                  <div class="row">
                    <img 
                        src="@/assets/images/jpg-png/formulation-4.png"
                        alt="formulation 4"
                    >
                    <div>
                        <h3>+PANTHENOL</h3>
                        <p class="subtitle">to soothe and restore dry skin</p>
                        <p class="desc">Hydrating ingredient that soothes<br>
                            and restores dry skin<sup>8-12</sup></p>
                    </div>
                    
                  </div>
                </div>
            </div>

            <a @click="goPage('homepage:pioneering-extensive-research')" class="link prev-link">   
                <img
                    src="@/assets/images/svg/prev-icon.svg"
                    alt="next icon"
                />
                <p class="f-bold">Pioneering Extensive Research</p>
            </a>
            <a @click="goPage('homepage:exceptional-hydratation')" class="link next-link">
                <p class="f-bold">Exceptional Hydration</p>
                <img
                    src="@/assets/images/svg/next-icon.svg"
                    alt="next icon"
                />
            </a>
        </div>
    </page-layout>
</template>

<script>
    import PageLayout from '@/components/common/PageLayout.vue';

    export default {
        components: {
            PageLayout,
        },
        mounted() {
            this.$route.params.onSwipeLeft = this.onSwipeLeft;
            this.$route.params.onSwipeRight = this.onSwipeRight;
        },
        methods: {
            goPage(name) {
                return this.$router.push({ name });
            },
            onSwipeLeft() {
                return this.$router.push({ name: 'homepage:exceptional-hydratation' });
            },
            onSwipeRight() {
                return this.$router.push({ name: 'homepage:pioneering-extensive-research' });
            },
        }
    };
</script>

<style lang="scss">
    .proven-efficacy {
        background: url(~@/assets/images/jpg-png/upper-bg-home.png) no-repeat;
        background-size: 100%;
        .content {
            margin-left: 50.5px;
            margin-top: 60px;
            .heading {
                .main-title {
                    font-family: 'Sofia Pro Bold';
                    font-size: 27.5px;
                    text-transform: uppercase;
                    color: $main-font-color;
                    letter-spacing: -0.94px;
                    line-height: 1.1;
                    margin-top: 40px;
                }
                .description {
                    font-size: 18px;
                    sup {
                        font-size: small;
                    }
                }
                
            }
            .container {
                display: flex;
                justify-content: space-between;
                margin-left: -80px;
                padding-top: 30px;
                .column {
                    flex: 1;
                    padding: 10px;
                    margin: 0 10px;
                }
                .row {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-bottom: 10px;
                }
                p {
                    text-align: center;
                    font-family: 'Sofia Pro Light';
                    color: $main-font-color;
                    font-size: 16px;
                    line-height: 20px;
                }
                .frst-col {
                    padding-left: 80px;
                    p {
                        margin-top: 5px;
                        sup {
                            font-size: x-small;
                        }
                    }
                }
                .scnd-col {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-left: -170px;
                    margin-top: -90px;
                }
                .three-col {
                    flex-direction: row;
                    justify-content: center;
                    margin-left: -60px;
                    .row {
                        display: -webkit-box;
                        margin-bottom: 30px;
                        margin-left: -25px;
                    }
                    h3 {
                        font-family: 'SpaceGrotesk Bold';
                        color: $main-font-color;
                        font-size: 22px;
                        margin-top: -20px;
                        margin-bottom: -8px;
                    }
                    .subtitle {
                        text-align: start;
                        margin-top: 10px;
                        margin-bottom: -8px;
                    }
                    .desc {
                        text-align: start;
                        color: #2F2F2F;
                        margin-bottom: 4;
                        sup {
                            font-size: x-small;
                        }
                    }
                    hr {
                        border: 1px solid #AAD3F1;
                        width: 280px;
                        margin-bottom: 2px;
                        border-top-width: 1px;
                        border-bottom-width: 0;
                    }
                    img {
                        width: 65.942px;
                        height: 69.584px;
                        transform: rotate(-0.248deg);
                        flex-shrink: 0;
                        margin-right: 10px;
                    }
                }
            }
            .link {
                position: absolute;
                z-index: 10000;
                p {
                    text-decoration: underline;
                    color: #63AF45;
                    font-size: 14px;
                }
                img {
                    padding-left: 10px;
                    padding-right: 10px;
                    padding-top: 5px;
                }
            }
            .prev-link {
                display: flex;
                justify-content: flex-start;
                margin-left: -8px;
                margin-top: -57px;
            }
            .next-link {
                display: flex;
                justify-content: flex-end;
                margin-right: 0px;
                right: 45px;
                margin-top: -57px;
            }
        }
    }
</style>