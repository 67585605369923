<template>
  <div class="content">
    <h1 class="f-bold uppercase">In a patient satisfaction survey:</h1>
    <div class="content-chart" style="display:flex; justify-content:center;">
        <img src="@/assets/images/jpg-png/gsc-modal-1-chart.png" width="520" style="margin-top:70px" alt=""/>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.gscmodal1 > .modal-layout__content {
    background: url(~@/assets/images/jpg-png/upper-bg-home.png) no-repeat;
    border: 1px solid #fff;
    border-radius: 8px;
    .content {
        margin: 20px;
        h1 {
            font-size: 25px;
        }
    }
}
.gsc-modal1 {
  .modal-layout__close {
    top: 18px;
  }
  .modal-layout__content {
    width: 931px;
    height: 642px;
    top: 17px;
    left: 2px;
  }
  font-family: 'Sofia Pro';
  color: $main-font-color;
}
</style>