<template>
  <page-layout>
    <div class="content slide-swipe">
      <div class="heading">
        <h1 class="main-section-title">Gentle Skin Cleansing Cloths</h1>
        <p class="description">
          Quick &amp; easy, these pre-moistened cleansing cloths are ultra-soft
          and gentle <br />
          for even the most sensitive skin
        </p>
      </div>
      <div class="back-button">
        <img
          src="@/assets/images/jpg-png/back-button.png"
          width="39.5"
          alt=""
          @click="goLastCategory"
          @touchend="goLastCategory"
        />
      </div>
      <div class="content-product-row">
        <div class="image-product">
          <img src="@/assets/images/jpg-png/gsc-cloths.png" alt="" />
        </div>
        <div class="feature-icons">
          <img src="@/assets/images/jpg-png/sensitive-skin-icon.png" alt="" />
          <img src="@/assets/images/jpg-png/skin-oil-icon.png" alt="" />
          <img src="@/assets/images/jpg-png/drop-only.png" alt="" />
        </div>
        <div class="feature-info">
          <h3 class="f-bold">For sensitive skin</h3>
          <div class="feature-info-skin-oil">
            <p class="f-light">
              Mild, soap free formulation won't strip skin of its<br />
              natural protective oils and emollients
            </p>
          </div>
          <div class="feature-info-hours-barrier">
            <ul class="f-light">
              <li>
                <img
                  src="@/assets/images/jpg-png/polygon-mark.png"
                  width="8"
                  alt=""
                />
                Free of harsh surfactants that might dry or <br />
                irritate the skin
              </li>
              <li>
                <img
                  src="@/assets/images/jpg-png/polygon-mark.png"
                  width="8"
                  alt=""
                />Moisturizes as it cleanses to help maintain <br />
                the skin's natural moisture barrier
              </li>
            </ul>
          </div>
          <div class="feature-info-sensitive-skin">
            <p class="f-light">Cloth material is gentle on sensitive skin</p>
          </div>
        </div>
      </div>
      <div class="product-nav">
        <ul>
          <li>
            <router-link :to="{ name: 'daily-cleansers:gsc-cloths-benefits' }"
              >Key Benefits</router-link
            >
          </li>
          <li>
            <router-link :to="{ name: 'daily-cleansers:gsc-cloths-features' }"
              >Key Features</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/common/PageLayout.vue';

export default {
  components: {
    PageLayout,
  },
  mounted() {
    this.$route.params.onSwipeLeft = this.onSwipeLeft;
    this.$route.params.onSwipeRight = this.onSwipeRight;
  },
  methods: {
    onSwipeLeft() {
      return this.$router.push({ name: 'daily-cleansers:gsc-cloths-features' });
    },
    onSwipeRight() {
      return this.$router.push({ name: 'daily-cleansers:gsc-cloths-benefits' });
    },
  },
};
</script>

<style lang="scss">
.gsc-cloths-benefits {
  background: url(~@/assets/images/jpg-png/upper-bg-home.png) no-repeat;
  background-size: 100%;
  .content {
    margin-left: 50.5px;
    margin-top: 38px;
    .back-button {
      position: absolute;
      top: 139px;
    }
    &-product-row {
      display: flex;
      align-items: center;
      .image-product {
        margin-top: 28px;
        margin-left: 13px;
        img {
          width: 309px;
        }
      }
      .feature-icons {
        display: flex;
        flex-direction: column;
        margin-top: 99px;
        margin-left: 38px;
        margin-right: 47px;
        img {
          width: 100px;
          margin-bottom: 23px;
        }
      }
      .feature-info {
        position: relative;
        width: 326px;
        height: 360px;
        color: $main-font-color;
        h3 {
          position: absolute;
          width: 100%;
          top: -8px;
          left: -7px;
        }
        &-skin-oil {
          position: absolute;
          border-bottom: 1px solid rgb(200, 221, 234);
          letter-spacing: -0.5px;
          width: 100%;
          top: 60px;
          left: -6px;
          padding-bottom: 8px;
        }
        &-hours-barrier {
          position: absolute;
          border-bottom: 1px solid rgb(200, 221, 234);
          width: 100%;
          bottom: 70px;
          left: -7px;
          letter-spacing: -0.4px;
          padding-bottom: 10px;
          p {
            margin-bottom: 20px;
          }
          ul {
            margin: 0;
            padding: 0;
            li {
              font-family: 'Sofia Pro Light';
              list-style: none;
              margin-bottom: 5px;
              text-indent: -12px;
              margin-left: 13px;
              margin-bottom: 20px;
              img {
                display: inline-block;
                margin-right: 3px;
              }
            }
          }
        }
        &-sensitive-skin {
          position: absolute;
          width: 100%;
          bottom: -2px;
          left: -7px;
          letter-spacing: -0.4px;
        }
      }
    }
    .product-nav {
      position: absolute;
      z-index: 9999;
      bottom: 127px;
      left: 119px;
      border: 1px solid $light-blue;
      box-shadow: 0px 9px 14px rgba($color: $gray-backdrop, $alpha: 0.06);
      border-radius: 8px;
      overflow: hidden;
      ul {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
        li {
          list-style: none;
          > a {
            font-size: 12.5px;
            color: $main-font-color;
            padding: 10px 12.5px;
            line-height: 1;
            display: inline-block;
            font-family: 'Sofia Pro Bold';
          }
          > a.router-link-exact-active {
            background: $light-blue-2;
          }
          &:first-child {
            border-right: 1px solid $light-blue;
          }
        }
      }
    }
  }
}
</style>
