<template>
  <page-layout>
    <div class="content slide-swipe">
      <div class="heading">
        <h1 class="main-section-title">
          Gentle clear complexion <br />
          clearing acne cleanser <br />
          with 2.6% benzoyl peroxide <br />
          acne treatment
        </h1>
        <p class="description">
          Designed for acne-prone, sensitive skin
        </p>
      </div>
      <div class="back-button">
        <img
          src="@/assets/images/jpg-png/back-button.png"
          width="39.5"
          alt=""
          @click="goLastCategory"
          @touchend="goLastCategory"
        />
      </div>
      <div class="content-product-row">
        <div class="image-product">
          <img
            src="@/assets/images/jpg-png/gentle-clear-complexion-detail-image.png"
            alt=""
          />
        </div>
        <div class="feature-info">
          <h3 class="f-bold">Key Features</h3>
          <div class="key-features-info">
            <ul>
              <li>
                Dermatologist tested
              </li>
              <li>
                Targets acne causing bacteria with 2.6% Micronized Benzoyl
                Peroxide
              </li>
              <li>Oil-free, paraben free, fragrance free</li>
              <li>Non-comedogenic, hypoallergenic</li>
            </ul>
          </div>
          <div class="ingredients-info">
            <h3 class="f-bold">Ingredients</h3>
            <p>
              <span class="f-bold">Active ingredients:</span> Benzoyl Peroxide
              2.6%
              <br />
              <span class="f-bold">Inactive ingredients:</span> Water, magnesium
              aluminum silicate, glycerin, sodium c14-16 olefin sulfonate,
              PEG-75, PEG/PPG-120/10 trimethylolpropane trioleate, laureth-2,
              phenoxyethanol, xanthan gum, dipotassium glycyrrhizate, poloxamer
              124, zinc gluconate, disodium EDTA, citric acid .
            </p>
          </div>
        </div>
      </div>
      <div class="product-nav">
        <ul>
          <li>
            <router-link
              :to="{
                name: 'skin-concerns:gentle-clear-complexion-acne-benefits',
              }"
              >Key Benefits</router-link
            >
          </li>
          <li>
            <router-link
              :to="{
                name: 'skin-concerns:gentle-clear-complexion-clearing-features',
              }"
              >Key Features</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/common/PageLayout.vue';

export default {
  components: {
    PageLayout,
  },
  mounted() {
    this.$route.params.onSwipeLeft = this.onSwipeLeft;
    this.$route.params.onSwipeRight = this.onSwipeRight;
  },
  methods: {
    onSwipeLeft() {
      return this.$router.push({
        name: 'skin-concerns:gentle-clear-complexion-clearing-features',
      });
    },
    onSwipeRight() {
      return this.$router.push({
        name: 'skin-concerns:gentle-clear-complexion-acne-benefits',
      });
    },
  },
};
</script>

<style lang="scss">
.gentle-clear-complexion-clearing-features {
  background: url(~@/assets/images/jpg-png/upper-bg-home.png) no-repeat;
  background-size: 100%;
  .content {
    margin-left: 50.5px;
    margin-top: 38px;
    .heading {
      .main-section-title {
        margin-bottom: 25px;
      }
      .description {
        sup {
          font-size: 55%;
        }
      }
    }
    .back-button {
      position: absolute;
      top: 240px;
    }
    &-product-row {
      display: flex;
      align-items: center;
      .image-product {
        margin-top: 52px;
        margin-left: 30px;
        img {
          width: 250px;
        }
      }
      .feature-info {
        position: relative;
        margin-top: 10px;
        margin-left: 150px;
        color: $main-font-color;
        width: 450px;
        height: 310px;
        h3 {
          position: absolute;
          width: 100%;
          top: -31px;
        }
        .key-features-info {
          border-bottom: 1px solid rgb(200, 221, 234);
          padding-bottom: 35px;
          letter-spacing: -0.5px;
          position: absolute;
          width: 92%;
          top: 36px;
          line-height: 1.1;
          ul {
            margin: 0;
            padding: 0;
            margin-top: -6px;
            li {
              font-family: 'Sofia Pro Light';
              list-style: none;
              background: url(~@/assets/images/jpg-png/standard-arrow.png)
                no-repeat;
              background-size: 10px;
              padding-left: 15px;
              sup {
                font-size: 40%;
              }
              img {
                display: inline-block;
                margin-right: 10px;
              }
            }
          }
        }
        .ingredients-info {
          padding-top: 0;
          position: absolute;
          width: 100%;
          top: 170px;
          h3 sup {
            font-size: 60%;
          }
          p {
            font-family: 'Sofia Pro Light';
            line-height: 16.9px;
            margin-top: 25px;
            letter-spacing: -0.5px;
          }
        }
      }
    }
    .product-nav {
      position: absolute;
      z-index: 9999;
      bottom: 120px;
      left: 100px;
      border: 1px solid $light-blue;
      box-shadow: 0px 9px 14px rgba($color: $gray-backdrop, $alpha: 0.06);
      border-radius: 8px;
      overflow: hidden;
      ul {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
        li {
          list-style: none;
          > a {
            font-size: 12.5px;
            color: $main-font-color;
            padding: 10px 12.5px;
            line-height: 1;
            display: inline-block;
            font-family: 'Sofia Pro Bold';
          }
          > a.router-link-exact-active {
            background: $light-blue-2;
          }
          &:first-child {
            border-right: 1px solid $light-blue;
          }
        }
      }
    }
  }
}
</style>
