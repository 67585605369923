<template>
  <div class="content">
    <h1 class="f-bold">
      ITCH RELIEF THAT LASTS UP TO 12 HOURS
    </h1>
    <h2>Significantly greater itch reduction at 8 hours<br>vs CeraVe Itch Relief Moisturizing Cream</h2>
    <img src="@/assets/images/svg/itch-relief-gel-see-more-data-modal.svg" alt="" />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.itchreliefgelseemoredatamodal {
  .modal-layout__content {
    padding: 25px 38px;
    h1 {
      font-size: 26px;
      margin: 0;
      letter-spacing: 0.2px;
      text-transform: uppercase;
      line-height: 30px;
      margin-top: 4px;
    }
    h2 {
      text-align: center;
      font-size: 18px;
      letter-spacing: -0.95px;
      color: $gray-second-text;
    }
    img {
      width: 550px;
      display: block;
      margin: 0 auto;
      margin-top: 40px;
      margin-bottom: 0;
    }
  }
}
</style>
