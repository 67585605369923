var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-layout',[_c('div',{staticClass:"content"},[_c('div',{staticClass:"main-heading"},[_c('h1',[_vm._v("Daily cleansers")])]),_c('div',{staticClass:"secondary-heading"},[_c('h4',{staticClass:"text-short-description"},[_vm._v(" Cetaphil daily cleansers are designed to effectively cleanse the face"),_c('br'),_vm._v(" and/or body without irritating sensitive skin. ")])]),_c('div',{staticClass:"content-products",staticStyle:{"margin-top":"50px"}},[_c('div',{staticClass:"products-card-one-upper",staticStyle:{"display":"flex"}},[_c('div',{staticClass:"container-img-product"},[_c('a',{on:{"click":function($event){return _vm.openDetail('daily-cleansers:gsc-benefits')}}},[_c('img',{staticClass:"img-product",staticStyle:{"margin-top":"-33px"},attrs:{"src":require("@/assets/images/jpg-png/gsc-img.png"),"width":"70","alt":""}}),_c('img',{staticClass:"internal-icon",staticStyle:{"margin-left":"26px","cursor":"pointer"},attrs:{"src":require("@/assets/images/jpg-png/int-link-icon.png"),"alt":""}})])]),_c('div',{staticClass:"container-img-product"},[_c('a',{on:{"click":function($event){return _vm.openDetail('daily-cleansers:hfc-cleanser-benefits')}}},[_c('img',{staticClass:"img-product",staticStyle:{"margin-top":"-33px","width":"75px"},attrs:{"src":require("@/assets/images/jpg-png/hydating-foaming-cream-cleanser.png"),"width":"70","alt":""}}),_c('img',{staticClass:"internal-icon",staticStyle:{"margin-left":"26px","margin-top":"-1px","cursor":"pointer"},attrs:{"src":require("@/assets/images/jpg-png/int-link-icon.png"),"alt":""}})])]),_c('div',{staticClass:"container-img-product"},[_c('a',{attrs:{"target":"_system"},on:{"click":function($event){return _vm.openLink(
                'https://www.cetaphil.com/us/cleansers/gentle-cleansing-bar/302993923046.html'
              )},"touchend":function($event){return _vm.openLink(
                'https://www.cetaphil.com/us/cleansers/gentle-cleansing-bar/302993923046.html'
              )}}},[_c('img',{staticClass:"img-product",staticStyle:{"margin-top":"-9px","margin-left":"13px"},attrs:{"src":require("@/assets/images/jpg-png/gcb-img.png"),"width":"68","alt":""}}),_c('img',{staticClass:"external-icon",staticStyle:{"margin-top":"-5px","margin-left":"26px","cursor":"pointer"},attrs:{"src":require("@/assets/images/jpg-png/ext-link-icon.png"),"alt":""}})])]),_c('div',{staticClass:"container-img-product"},[_c('a',{attrs:{"target":"_system"},on:{"click":function($event){return _vm.openLink(
                'https://www.cetaphil.com/us/cleansers/gentle-foaming-cleanser/302993889175.html'
              )},"touchend":function($event){return _vm.openLink(
                'https://www.cetaphil.com/us/cleansers/gentle-foaming-cleanser/302993889175.html'
              )}}},[_c('img',{staticStyle:{"margin-top":"-46px","margin-left":"20px"},attrs:{"src":require("@/assets/images/jpg-png/gfc-img.png"),"width":"54","alt":""}}),_c('img',{staticClass:"external-icon",staticStyle:{"margin-top":"-6px","margin-left":"26px","cursor":"pointer"},attrs:{"src":require("@/assets/images/jpg-png/ext-link-icon.png"),"alt":""}})])]),_c('div',{staticClass:"container-img-product"},[_c('a',{attrs:{"target":"_system"},on:{"click":function($event){return _vm.openLink(
                'https://www.cetaphil.com/us/cleansers/ultra-gentle-body-wash-fragrance-free/302993938163.html'
              )},"touchend":function($event){return _vm.openLink(
                'https://www.cetaphil.com/us/cleansers/ultra-gentle-body-wash-fragrance-free/302993938163.html'
              )}}},[_c('img',{staticStyle:{"margin-top":"-48px","margin-left":"18px"},attrs:{"src":require("@/assets/images/jpg-png/ugbw-img.png"),"width":"57","alt":""}}),_c('img',{staticClass:"external-icon",staticStyle:{"margin-top":"-7px","margin-left":"26px","cursor":"pointer"},attrs:{"src":require("@/assets/images/jpg-png/ext-link-icon.png"),"alt":""}})])]),_c('div',{staticClass:"container-img-product"},[_c('a',{attrs:{"target":"_system"},on:{"click":function($event){return _vm.openLink(
                'https://www.cetaphil.com/us/cleansers/ultra-gentle-body-wash-refreshing-scent/302993938064.html'
              )},"touchend":function($event){return _vm.openLink(
                'https://www.cetaphil.com/us/cleansers/ultra-gentle-body-wash-refreshing-scent/302993938064.html'
              )}}},[_c('img',{staticStyle:{"margin-top":"-48px","margin-left":"16px"},attrs:{"src":require("@/assets/images/jpg-png/ugrbw-img.png"),"width":"57","alt":""}}),_c('img',{staticClass:"external-icon",staticStyle:{"margin-top":"-6px","margin-left":"26px","cursor":"pointer"},attrs:{"src":require("@/assets/images/jpg-png/ext-link-icon.png"),"alt":""}})])]),_c('div',{staticClass:"container-img-product"},[_c('a',{attrs:{"target":"_system"},on:{"click":function($event){return _vm.openLink(
                'https://www.cetaphil.com/us/cleansers/ultra-gentle-body-wash-skin-soothing/302993938262.html'
              )},"touchend":function($event){return _vm.openLink(
                'https://www.cetaphil.com/us/cleansers/ultra-gentle-body-wash-skin-soothing/302993938262.html'
              )}}},[_c('img',{staticStyle:{"margin-top":"-48px","margin-left":"14px"},attrs:{"src":require("@/assets/images/jpg-png/ugsbw-img.png"),"width":"57","alt":""}}),_c('img',{staticClass:"external-icon",staticStyle:{"margin-top":"-8px","margin-left":"24px","cursor":"pointer"},attrs:{"src":require("@/assets/images/jpg-png/ext-link-icon.png"),"alt":""}})])]),_c('div',{staticClass:"container-img-product"},[_c('a',{attrs:{"target":"_system"},on:{"click":function($event){return _vm.openLink(
                'https://www.cetaphil.com/us/cleansers/deep-cleansing-bar/302993923572.html'
              )},"touchend":function($event){return _vm.openLink(
                'https://www.cetaphil.com/us/cleansers/deep-cleansing-bar/302993923572.html'
              )}}},[_c('img',{staticStyle:{"margin-top":"-9px","margin-left":"13px"},attrs:{"src":require("@/assets/images/jpg-png/dcb-img.png"),"width":"68","alt":""}}),_c('img',{staticClass:"external-icon",staticStyle:{"margin-top":"-5px","margin-left":"26px","cursor":"pointer"},attrs:{"src":require("@/assets/images/jpg-png/ext-link-icon.png"),"alt":""}})])])]),_c('div',{staticClass:"products-card-one-main",staticStyle:{"display":"flex"}},[_c('a',{staticClass:"archor-card-1",attrs:{"href":"#"}},[_vm._v("Dry to Normal Skin")])]),_c('div',{staticClass:"products-card-two-upper",staticStyle:{"display":"flex"}},[_c('div',{staticClass:"container-img-product"},[_c('a',{on:{"click":function($event){return _vm.openDetail('daily-cleansers:gsc-cloths-benefits')}}},[_c('img',{staticClass:"img-product",staticStyle:{"margin-top":"-40px","margin-left":"5px"},attrs:{"src":require("@/assets/images/jpg-png/gsc-cloth-v2.png"),"width":"72","alt":""}}),_c('img',{staticClass:"internal-icon",staticStyle:{"margin-left":"33px","cursor":"pointer"},attrs:{"src":require("@/assets/images/jpg-png/int-link-icon.png"),"alt":""}})])]),_c('div',{staticClass:"container-img-product"},[_c('a',{attrs:{"target":"_system"},on:{"click":function($event){return _vm.openLink(
                'https://www.cetaphil.com/us/cleansers/gentle-makeup-removing-wipes/302993889250.html'
              )},"touchend":function($event){return _vm.openLink(
                'https://www.cetaphil.com/us/cleansers/gentle-makeup-removing-wipes/302993889250.html'
              )}}},[_c('img',{staticClass:"img-product",staticStyle:{"margin-top":"8px","margin-left":"-10px"},attrs:{"src":require("@/assets/images/jpg-png/gmrw-img.png"),"width":"137","alt":""}}),_c('img',{staticClass:"external-icon",staticStyle:{"margin-top":"-8px","margin-left":"41px","cursor":"pointer"},attrs:{"src":require("@/assets/images/jpg-png/ext-link-icon.png"),"alt":""}})])]),_c('div',{staticClass:"container-img-product"},[_c('a',{attrs:{"target":"_system"},on:{"click":function($event){return _vm.openLink(
                'https://www.cetaphil.com/us/cleansers/gentle-oil-free-makeup-remover/302993889069.html'
              )},"touchend":function($event){return _vm.openLink(
                'https://www.cetaphil.com/us/cleansers/gentle-oil-free-makeup-remover/302993889069.html'
              )}}},[_c('img',{staticStyle:{"margin-top":"-34px"},attrs:{"src":require("@/assets/images/jpg-png/gofmr-img.png"),"width":"53","alt":""}}),_c('img',{staticClass:"external-icon",staticStyle:{"margin-top":"-8px","margin-left":"7px","cursor":"pointer"},attrs:{"src":require("@/assets/images/jpg-png/ext-link-icon.png"),"alt":""}})])])]),_c('div',{staticClass:"products-card-two-main",staticStyle:{"display":"flex"}},[_c('a',{staticClass:"archor-card-2",attrs:{"href":"#"}},[_vm._v("All Skin Types")])]),_c('div',{staticClass:"products-card-three-upper",staticStyle:{"display":"flex"}},[_c('div',{staticClass:"container-img-product"},[_c('a',{on:{"click":function($event){return _vm.openDetail('daily-cleansers:dfc-ff-benefits')}}},[_c('img',{staticClass:"img-product",staticStyle:{"margin-top":"-33px"},attrs:{"src":require("@/assets/images/jpg-png/daily-facial-cleanser-fragance-free.png"),"width":"71","alt":""}}),_c('img',{staticClass:"internal-icon",staticStyle:{"margin-left":"26px","cursor":"pointer"},attrs:{"src":require("@/assets/images/jpg-png/int-link-icon.png"),"alt":""}})])]),_c('div',{staticClass:"container-img-product"},[_c('a',{attrs:{"target":"_system"},on:{"click":function($event){return _vm.openLink(
                'https://www.cetaphil.com/us/cleansers/daily-facial-cleanser/302993927341.html'
              )},"touchend":function($event){return _vm.openLink(
                'https://www.cetaphil.com/us/cleansers/daily-facial-cleanser/302993927341.html'
              )}}},[_c('img',{staticClass:"img-product",staticStyle:{"margin-top":"-33px","margin-left":"9px"},attrs:{"src":require("@/assets/images/jpg-png/daily-facial-cleanser-new.png"),"width":"72","alt":""}}),_c('img',{staticClass:"external-icon",staticStyle:{"margin-top":"-7px","margin-left":"24px","cursor":"pointer"},attrs:{"src":require("@/assets/images/jpg-png/ext-link-icon.png"),"alt":""}})])]),_c('div',{staticClass:"container-img-product"},[_c('a',{attrs:{"target":"_system"},on:{"click":function($event){return _vm.openLink(
                'https://www.cetaphil.com/us/cleansers/extra-gentle-daily-scrub/302993889182.html'
              )},"touchend":function($event){return _vm.openLink(
                'https://www.cetaphil.com/us/cleansers/extra-gentle-daily-scrub/302993889182.html'
              )}}},[_c('img',{staticStyle:{"margin-top":"-28px","margin-left":"11px"},attrs:{"src":require("@/assets/images/jpg-png/egds-img.png"),"width":"58","alt":""}}),_c('img',{staticClass:"external-icon",staticStyle:{"margin-top":"-6px","margin-left":"22px","cursor":"pointer"},attrs:{"src":require("@/assets/images/jpg-png/ext-link-icon.png"),"alt":""}})])])]),_c('div',{staticClass:"products-card-three-main",staticStyle:{"display":"flex"}},[_c('a',{staticClass:"archor-card-3",attrs:{"href":"#"}},[_vm._v("Combination to Oily Skin")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }