<template>
  <page-layout>
    <div class="content">
      <div class="main-heading">
        <h1 class="title">DERMACONTROL & RESTORADERM</h1>
        <p class="title-subheading">
          The efficacy of the Cetaphil<sup>®</sup> DERMACONTROL & Cetaphil<sup
            >®</sup
          >
          RESTORADERM product<br />
          range has been assessed and shown across three evidence levels that
          combine clinical<br />
          and instrumental measures with patient-reported outcomes.
        </p>
      </div>
      <div class="main-content">
        <!--Card #1-->
        <div class="card-product oily-skin">
          <div class="card-product__head">
            <div class="card-product__head-image">
              <a>
                <img
                  src="@/assets/images/jpg-png/dorfw-img.png"
                  width="50.5"
                  height="150.5"
                  style="margin-left:-9px; cursor:pointer;"
                  alt=""
                  @click="openDetail('derma-control:dco-rfw-benefits')"
                />
              </a>
            </div>
            <div class="card-product__head-image">
              <a>
                <img
                  src="@/assets/images/jpg-png/doam-img.png"
                  width="52"
                  height="142"
                  style="margin-left:2px; cursor:pointer;"
                  alt=""
                  @click="openDetail('derma-control:dco-amspf30-benefits')"
                />
              </a>
            </div>
            <div class="card-product__head-image">
              <a
                @click="
                  openLink(
                    'https://www.cetaphil.com/us/cleansers/dermacontrol-purifying-clay-mask/302993931195.html'
                  )
                "
                @touchend="
                  openLink(
                    'https://www.cetaphil.com/us/cleansers/dermacontrol-purifying-clay-mask/302993931195.html'
                  )
                "
                target="_system"
              >
                <img
                  src="@/assets/images/jpg-png/dcpcm-img.png"
                  width="122.5"
                  height="132"
                  style="margin-left:-5px; cursor:pointer;"
                  alt=""
                />
              </a>
            </div>
          </div>
          <div class="card-product__icons-links">
            <div class="container-icon-1" style="height:5px;">
              <a @click="openDetail('derma-control:dco-rfw-benefits')">
                <img
                  class="internal-icon"
                  src="@/assets/images/jpg-png/int-link-icon.png"
                  style="margin-left:58px; cursor:pointer;"
                  alt=""
                />
              </a>
            </div>
            <div class="container-icon-2" style="height:5px;">
              <a @click="openDetail('derma-control:dco-amspf30-benefits')">
                <img
                  class="internal-icon"
                  src="@/assets/images/jpg-png/int-link-icon.png"
                  style="margin-left:78px; cursor:pointer;"
                  alt=""
                />
              </a>
            </div>
            <div class="container-icon-3" style="height:5px;">
              <a
                @click="
                  openLink(
                    'https://www.cetaphil.com/us/cleansers/dermacontrol-purifying-clay-mask/302993931195.html'
                  )
                "
                @touchend="
                  openLink(
                    'https://www.cetaphil.com/us/cleansers/dermacontrol-purifying-clay-mask/302993931195.html'
                  )
                "
                target="_system"
              >
                <img
                  class="external-icon"
                  src="@/assets/images/jpg-png/ext-link-icon.png"
                  style="margin-left:80px; cursor:pointer;"
                  alt=""
                />
              </a>
            </div>
          </div>
          <div class="card-product__details">
            <span>
              <router-link :to="{ name: 'derma-control' }">
                Oily Skin
              </router-link>
            </span>
          </div>
        </div>

        <!--Card #2-->
        <div class="card-product eczema-dry-skin">
          <div class="card-product__head">
            <div class="card-product__head-image">
              <a>
                <img
                  src="@/assets/images/jpg-png/rswsshbl-img.png"
                  width="50.5"
                  height="150.5"
                  alt=""
                  @click="openDetail('derma-control:soothing-wash-benefits')"
                />
              </a>
            </div>
            <div class="card-product__head-image">
              <a>
                <img
                  src="@/assets/images/jpg-png/restoraderm-itch-relief-gel.png"
                  width="67"
                  height="130"
                  alt=""
                  @click="
                    openDetail('derma-control:itch-relief-gel-benefits')
                  "
                />
              </a>
            </div>
            <div class="card-product__head-image">
              <a>
                <img
                  src="@/assets/images/jpg-png/restoraderm-flare-up-relief-cream.png"
                  width="72"
                  height="130"
                  alt=""
                  @click="
                    openDetail('derma-control:restoraderm-flareup-benefits')
                  "
                />
              </a>
            </div>
            <div class="card-product__head-image">
              <a>
                <img
                  src="@/assets/images/jpg-png/restoraderm-soothing-moisturizing-lotion.png"
                  width="86"
                  height="155"
                  alt=""
                  @click="
                    openDetail('derma-control:restoraderm-eczema-benefits')
                  "
                />
              </a>
            </div>
          </div>
          <div class="card-product__icons-links">
            <a>
              <div class="container-icon-1">
                <img
                  class="internal-icon"
                  src="@/assets/images/jpg-png/int-link-icon.png"
                  style="margin-left:55px; cursor:pointer;"
                  alt=""
                  @click="openDetail('derma-control:soothing-wash-benefits')"
                />
              </div>
            </a>
            <div class="container-icon-2">
              <a>
                <img
                  class="internal-icon"
                  src="@/assets/images/jpg-png/int-link-icon.png"
                  style="margin-left:72px; cursor:pointer;"
                  alt=""
                  @click="
                    openDetail('derma-control:itch-relief-gel-benefits')
                  "
                />
              </a>
            </div>
            <div class="container-icon-2">
              <a>
                <img
                  class="internal-icon"
                  src="@/assets/images/jpg-png/int-link-icon.png"
                  style="margin-left:72px; cursor:pointer;"
                  alt=""
                  @click="
                    openDetail('derma-control:restoraderm-flareup-benefits')
                  "
                />
              </a>
            </div>
            <div class="container-icon-2">
              <a>
                <img
                  class="internal-icon"
                  src="@/assets/images/jpg-png/int-link-icon.png"
                  style="margin-left:83px; cursor:pointer;"
                  alt=""
                  @click="
                    openDetail('derma-control:restoraderm-eczema-benefits')
                  "
                />
              </a>
            </div>
          </div>
          <div class="card-product__details">
            <span>
              <router-link :to="{ name: 'derma-control' }">
                Dry Skin
              </router-link>
            </span>
          </div>
        </div>
      </div>
    </div>
  </page-layout>
</template>

<script>
import PageLayout from "@/components/common/PageLayout.vue";

export default {
  components: {
    PageLayout,
  },
  methods: {
    openDetail(name) {
      return this.$router.push({ name });
    },
  },
  created() {
    this.setLastCategory("derma-control");
  },
};
</script>

<style lang="scss" scoped>
.derma-control {
  background: url(~@/assets/images/jpg-png/upper-bg-home.png) no-repeat;
  background-size: 100%;
  .content {
    padding-left: 50.5px;
  }
  .main-heading {
    padding-top: 22.5px;
    margin-bottom: 58.5px;
    .title {
      margin-bottom: 0px;
      font-family: "Sofia Pro Bold";
      color: $dark-blue;
      font-size: 27.5px;
      line-height: 29.5px;
      letter-spacing: -0.82px;
    }
    .title-subheading {
      margin-top: 19.5px;
      margin-bottom: 0px;
      font-family: "Sofia Pro Light";
      color: $dark-blue;
      font-size: 12.5px;
      line-height: 16px;
      letter-spacing: -0.24px;
    }
  }
  .main-content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    .card-product {
      border-radius: 6.5px;
      box-shadow: 0px 4.5px 7px $box-shadow;
      border: 1px solid $light-blue;
      background-color: $white;
      display: flex;
      flex-direction: column;
      margin-right: 40px;
      &.gap {
        margin-top: 47.5px;
      }
      &__head {
        margin: -1px;
        border-radius: inherit;
        height: 101.5px;
        background-color: $lighter-blue;
        border: 1px solid $lighter-blue;
        position: relative;
        padding: 0px 57px;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        &-image {
          margin-bottom: -12.5px;
          margin-left: -5px;
          margin-right: 43px;
          &:last-child {
            margin-right: 0px;
            margin-left: -17px;
          }
        }
      }
      &__details {
        position: relative;
        display: flex;
        justify-content: center;
        align-content: center;
        text-align: center;
        padding: 2px 0px 14px;
        span {
          a {
            font-family: "Sofia Pro";
            font-size: 12.5px;
            color: $dark-blue;
            letter-spacing: -0.3px;
          }
        }
      }
      &__icons-links {
        display: flex;
        height: 36px;
        .container-icon-1 {
          .internal-icon {
            margin-top: 14px;
            width: 17px;
          }
        }
        .container-icon-2 {
          .internal-icon {
            margin-top: 14px;
            width: 17px;
          }
        }
        .external-icon {
          width: 42px;
          margin-top: 7px;
        }
      }
      &.oily-skin {
        .card-product__head {
          padding: 0px 18px 0px 54px;
        }
      }
      &.eczema-dry-skin {
        .card-product__head {
          padding: 0px 28px;
          &-image {
            margin-bottom: -13.5px;
            margin-right: 10px;
            margin-left: 10px;
            &:first-child{
              margin-right: 20px;
            }
            &:last-child {
              margin-right: 0px;
              margin-left: 10px;
            }
          }
        }
        .card-product__details {
          padding-left: 6px;
        }
      }
    }
  }
}
</style>
