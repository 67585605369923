<template>
  <div class="content">
    <h1 class="f-bold">IN A PATIENT SATISFACTION SURVEY:</h1>
    <img src="@/assets/images/svg/ml-modal-one-graph.svg" alt="" />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.moisturizinglotionmodalone {
  .modal-layout__content {
    padding: 25px 38px;
    h1 {
      font-size: 26px;
      margin: 0;
      letter-spacing: 0.14px;
    }
    img {
      width: 590px;
      display: block;
      margin: 119px auto 0;
    }
  }
}
</style>
