<template>
  <page-layout>
    <div class="container slide-swipe">
      <div class="heading">
        <h1 class="main-section-title">restoraderm <br />soothing moisturizing lotion</h1>
        <p class="description">
          For eczema-prone, sensitive skin
        </p>
      </div>
      <div class="back-button">
        <img
          src="@/assets/images/jpg-png/back-button.png"
          width="39.5"
          alt=""
          @click="goLastCategory" @touchend="goLastCategory"
        />
      </div>
      <div class="container-product-row">
        <div class="image-product">
         <img src="@/assets/images/jpg-png/restoraderm-soothing-moisturizer-tag.png" alt="" />
        </div>
        <div class="feature-info">
          <h3 class="f-bold">Key Features</h3>
          <div class="key-features-info">
            <ul>
              <li class="doble-line">
                <img
                  src="@/assets/images/jpg-png/polygon-mark.png"
                  width="8"
                  alt=""
                /><span>Patented, Ad-Resyl<sup>&reg;</sup> is designed to help restore a<br>damaged skin barrier</span>
              </li>
              <li class="doble-line">
                <img
                  src="@/assets/images/jpg-png/polygon-mark.png"
                  width="8"
                  alt=""
                /><span>Proprietary Filaggrin Technology helps strengthen<br>skin’s barrier</span>
              </li>
              <li class="doble-line">
                <img
                  src="@/assets/images/jpg-png/polygon-mark.png"
                  width="8"
                  alt=""
                /><span>Skin protectant, Colloidal Oatmeal soothes<br>dry, itchy, irritated skin</span>
              </li>
              <li class="doble-line">
                <img
                  src="@/assets/images/jpg-png/polygon-mark.png"
                  width="8"
                  alt=""
                /><span>Fragrance free, paraben free, steroid free,<br>phthalate free, BHA free, BHT free</span>
              </li>
              <li>
                <img
                  src="@/assets/images/jpg-png/polygon-mark.png"
                  width="8"
                  alt=""
                />Hypoallergenic, non-comedogenic
              </li>
              <li>
                <img
                  src="@/assets/images/jpg-png/polygon-mark.png"
                  width="8"
                  alt=""
                />Dermatologist developed and tested
              </li>
            </ul>
          </div>
          <div class="ingredients-info">
            <h3 class="f-bold" style="margin-top:20px">Ingredients</h3>
            <p style="font-size: 14px">
              <span class="f-bold">Active Ingredient:</span> Collodial Oatmeal 1.0% <br />
              <span class="f-bold">Inactive Ingredients:</span> Water, Glycerin, Caprylic/Capric Triglyceride,
              Helianthus Annuus (Sunflower) Seed Oil, Pentylene Glycol,
              Butyrospermum Parkii (Shea) Butter, Cyclopentasiloxane,
              Cetearyl Alcohol, Sorbitol, Behenyl Alcohol, Glyceryl Stearate,
              Cetyl Alcohol, Tocopheryl Acetate, Arginine, Niacinamide,
              Glyceryl Stearate Citrate, Sodium Polyacrylate, Disodium Ethylene Dicocamide PEG-15 Disulfate,
              Caprylyl Glycol, Ceteareth-20, Sodium PCA, Panthenol, Citric Acid, Allantoin, Dimethiconol,
              Ophiopogon Japonicus Root Extract, Disodium EDTA, Maltodextrin, Sodium Hyaluronate, Ceramide NP, Pantolactone
            </p>
          </div>
        </div>  
      </div>
      <div class="product-nav">
        <ul>
          <li>
            <router-link :to="{ name: 'derma-control:restoraderm-eczema-benefits' }"
              >Key Benefits</router-link
            >
          </li>
          <li>
            <router-link :to="{ name: 'derma-control:restoraderm-eczema-features' }"
              >Key Features</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/common/PageLayout.vue'
export default {
  components: {
    PageLayout
  },
  mounted(){
    this.$route.params.onSwipeLeft = this.onSwipeLeft;
    this.$route.params.onSwipeRight = this.onSwipeRight;
  },
  methods: {
    onSwipeLeft() {
      return this.$router.push({name: 'derma-control:restoraderm-eczema-features'});
    },
    onSwipeRight() {
      return this.$router.push({name: 'derma-control:restoraderm-eczema-benefits'});
    }
  }
}
</script>

<style lang="scss">
.restoraderm-eczema-features {
  background: url(~@/assets/images/jpg-png/upper-bg-home.png) no-repeat;
  background-size: 100%;
  .container {
    margin-left: 50.5px;
    margin-top: 38px;
    .back-button {
      position: absolute;
      top: 180px;
    }
    &-product-row {
      display: flex;
      align-items: center;
      .image-product {
        margin-top: 62.5px;
        margin-right: 109px;
        margin-left: 11px;
        img {
          width: 308px;
        }
      }
      .feature-info {
        position: relative;
        margin-top: 90px;
        color: $main-font-color;
        width: 450px;
        height: 310px;
        h3 {
          position: absolute;
          width: 100%;
          top: -85px;
        }
        .key-features-info {
          border-bottom: 1px solid rgb(200, 221, 234);
          padding-bottom: 15px;
          letter-spacing: -0.5px;
          position: absolute;
          width: 92%;
          top: -18px;
          left: 5px;
          ul {
            margin: 0;
            padding: 0;
            margin-top: -6px;
            li {
              font-family: 'Sofia Pro Light';
              list-style: none;
              img {
                display: inline-block;
                margin-right: 10px;
              }
            }
            li.doble-line{
              text-indent: -18px;
              margin-left: 18px;
              span{
                text-indent: -18px;
              }
            }
          }
        }
        .ingredients-info {
          padding-top: 15px;
          position: absolute;
          width: 100%;
          top: 224px;
          p {
            font-family: 'Sofia Pro Light';
            line-height: 1.1;
            margin-top: -42px;
            letter-spacing: -0.5px;
          }
        }
      }
    }
    .product-nav {
      position: absolute;
      z-index: 9999;
      bottom: 127px;
      left: 119px;
      border: 1px solid $light-blue;
      box-shadow: 0px 9px 14px rgba($color: $gray-backdrop, $alpha: 0.06);
      border-radius: 8px;
      overflow: hidden;
      ul {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
        li {
          list-style: none;
          > a {
            font-size: 12.5px;
            color: $main-font-color;
            padding: 10px 12.5px;
            line-height: 1;
            display: inline-block;
            font-family: 'Sofia Pro Bold';
          }
          > a.router-link-exact-active {
            background: $light-blue-2;
          }
          &:first-child {
            border-right: 1px solid $light-blue;
          }
        }
      }
    }
  }
}
</style>
