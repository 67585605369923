<template>
    <page-layout>
      <div class="content slide-swipe">
        <div class="heading">
            <h1 class="main-section-title">
                Smarter science
            </h1>
            <h1 class="secondary-section-title">for sensitive skin</h1>
        </div>
        <div class="container">
            <div class="bullets-container">
                <div class="bullets">
                    <a @click="goPage('homepage:pioneering-extensive-research')">
                        <img
                            src="@/assets/images/svg/custom-bulletpoint.svg"
                            alt="PER custom bulletpoint"
                        />
                        <h2 class="f-bold">Pioneering extensive research</h2>
                    </a>
                </div>

                <div class="bullets">
                    <a @click="goPage('homepage:proven-efficacy')">
                        <img
                            src="@/assets/images/svg/custom-bulletpoint.svg"
                            alt="PE custom bulletpoint"
                        />
                        <h2 class="f-bold">Proven efficacy</h2>
                    </a>
                </div>
                
                <div class="bullets">
                    <router-link 
                        :to="{ name: 'ctmp' }"
                        >
                        <img
                            src="@/assets/images/svg/custom-bulletpoint.svg"
                            alt="PSR custom bulletpoint"
                        />
                        <h2 class="f-bold">Personalized skincare routines</h2></router-link
                    >
                </div>
            </div>
            <div class="product-images">
                <img
                    class="first-product"
                    src="@/assets/images/jpg-png/mc-product.png"
                    alt="custom bulletpoint"
                />
                <img
                    class="scnd-product"
                    src="@/assets/images/jpg-png/gsc-product.png"
                    alt="custom bulletpoint"
                />
            </div>
        </div>
      </div>
    </page-layout>
  </template>

<script>
import PageLayout from '@/components/common/PageLayout.vue';

export default {
  components: {
    PageLayout,
  },
  methods: {
    goPage(name) {
      return this.$router.push({ name });
    },
  },
  created() {
    this.setLastCategory('homepage');
  },
};
</script>

<style lang="scss" scoped>
.homepage {
    background: url(~@/assets/images/jpg-png/bg-homepage-v3.png);
    background-repeat: no-repeat;
    background-size: 100% 97%;
    .heading {
        padding-top: 105px;
        margin-left: 65px;
        .main-section-title {
            font-size: 42px;
            color: white;
        }
        .secondary-section-title {
            margin-top: -20px;
            font-size: 42px;
            color: white;
            margin-left: 35px;
        }
    }
    .container {
        display: flex;
        justify-content: space-between;
    }
    .bullets-container {
        margin-top: 120px;
        a {
            display: contents;
        }
        .bullets {
            display: flex;
            text-align: center;
            margin-left: 65px;
            margin-bottom: -20px;
            img {
                padding-top: 10px;
                margin-bottom: 10px;
            }
            h2 {
                font-size: 22px;
                padding-left: 10px;
                color: white;
            }
        }
    }
    .product-images {
        display: flex;
        align-items: center;
        margin-right: 150px;
        .first-product {
            width: 175px;
            margin-top: 135px;
            margin-right: -62px;
            z-index: 3;
        }
        .scnd-product {
            width: 240px;
            margin-top: -70px;
        }
    }
}
</style>