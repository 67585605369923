import Vue from 'vue';
import Router from 'vue-router';
import BlankPage from '@/components/common/BlankPage.vue';

// Import views
// menu components
import HomePage from '@/views/HomePage.vue';
import DailyCleansers from '@/views/DailyCleansers.vue';
import DailyMoisturizers from '@/views/DailyMoisturizers.vue';
import DermaControl from '@/views/DermaControl.vue';
import SpecialBenefits from '@/views/SpecialBenefits.vue';
import SkinConcerns from '@/views/SkinConcerns.vue';
// import BabySkincare from '@/views/BabySkincare.vue';
import PersonalizedSkincareRoutines from '@/views/patient-routine/PersonalizedSkincareRoutines.vue';

// homepage flow
import PioneeringExtensiveResearch from '@/views/homepage-flow/PioneeringExtensiveResearch';
import ProvenEfficacy from '@/views/homepage-flow/ProvenEfficacy';
import ExceptionalHydratation from '@/views/homepage-flow/ExceptionalHydratation';
import MoisturizingCream from '@/views/homepage-flow/MoisturizingCream';
import MoisturizingLotion from '@/views/homepage-flow/MoisturizingLotion';

// daily cleansers products
import GscBenefits from '@/views/daily-cleansers/GscBenefits';
import GscFeatures from '@/views/daily-cleansers/GscFeatures';
import GscClothsBenefits from '@/views/daily-cleansers/GscClothsBenefits.vue';
import GscClothsFeatures from '@/views/daily-cleansers/GscClothsFeatures.vue';
import HydratingFoamingCleanserBenefits from '@/views/daily-cleansers/HydratingFoamingCleanserBenefits.vue';
import HydratingFoamingCleanserFeatures from '@/views/daily-cleansers/HydratingFoamingCleanserFeatures.vue';
import DfcFfBenefits from '@/views/daily-cleansers/DfcFfBenefits.vue';
import DfcFfFeatures from '@/views/daily-cleansers/DfcFfFeatures.vue';

// daily moisturizers
import DoFFacialM35Benefits from '@/views/daily-moisturizers/DoFFacialM35Benefits';
import DoFFacialM35Features from '@/views/daily-moisturizers/DoFFacialM35Features';
import MoisturizingBenefits from '@/views/daily-moisturizers/MoisturizingCreamBenefits.vue';
import MoisturizingCreamFeatures from '@/views/daily-moisturizers/MoisturizingCreamFeatures.vue';
import DailyMoisturizersLotionDetails from '@/views/daily-moisturizers/DailyMoisturizersLotionDetails.vue';
import SoothingGelBenefits from '@/views/daily-moisturizers/SoothingGelBenefits.vue';
import SoothingGelFeatures from '@/views/daily-moisturizers/SoothingGelFeatures.vue';

// dermacontrol & restoraderm
import DcOilRFoamWBenefits from '@/views/dermacontrol-restoraderm/DcOilRFoamWBenefits.vue';
import DcOilRFoamWFeatures from '@/views/dermacontrol-restoraderm/DcOilRFoamWFeatures.vue';
import DcOilAMspf30Benefits from '@/views/dermacontrol-restoraderm/DcOilAMspf30Benefits.vue';
import DcOilAMspf30Features from '@/views/dermacontrol-restoraderm/DcOilAMspf30Features.vue';
import SoothingWashBenefits from '@/views/dermacontrol-restoraderm/SoothingWashBenefits.vue';
import SoothingWashFeatures from '@/views/dermacontrol-restoraderm/SoothingWashFeatures.vue';
import RestoradermEczemaBenefits from '@/views/dermacontrol-restoraderm/RestoradermEczemaBenefits.vue';
import RestoradermEczemaFeatures from '@/views/dermacontrol-restoraderm/RestoradermEczemaFeatures.vue';
import GentleClearComplexionClearingFeatures from '@/views/skin-concerns/GentleClearComplexionClearingFeatures.vue';
import ItchReliefGelBenefits from '@/views/dermacontrol-restoraderm/ItchReliefGelBenefits.vue';
import ItchReliefGelFeatures from '@/views/dermacontrol-restoraderm/ItchReliefGelFeatures.vue';

// sun care and special skin benefits
import SheerMineralStick from '@/views/special-benefits/SheerMineralStick.vue';
import SheerMineralStickFeatures from '@/views/special-benefits/SheerMineralStickFeatures.vue';
import SheerMineralSunScreenFace from '@/views/special-benefits/SheerMineralSunScreenFace.vue';
import SheerMineralSunScreenFaceFeatures from '@/views/special-benefits/SheerMineralSunScreenFaceFeatures.vue';

// therapeutic and restorative skin concerns
import RoughBumpyBenefits from '@/views/skin-concerns/RoughBumpyBenefits.vue';
import RoughBumpyFeatures from '@/views/skin-concerns/RoughBumpyFeatures.vue';
import HealingOintmentBenefits from '@/views/skin-concerns/HealingOintmentBenefits.vue';
import HealingOintmentFeatures from '@/views/skin-concerns/HealingOintmentFeatures.vue';
import GentleClearComplexionAcneBenefits from '@/views/skin-concerns/GentleClearComplexionAcneBenefits.vue';

// Eczema
import RestoradermFlareupBenefits from '@/views/dermacontrol-restoraderm/RestoradermFlareupBenefits.vue'
import RestoradermFlareupFeatures from '@/views/dermacontrol-restoraderm/RestoradermFlareupFeatures.vue'

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/',
      name: 'homepage',
      component: BlankPage,
      children: [
        {
          path: '',
          name: 'homepage',
          component: HomePage,
          meta: {
            datapointId: 1110,
          },
        },
        {
          path: 'pioneering-extensive-research',
          name: 'homepage:pioneering-extensive-research',
          component: PioneeringExtensiveResearch,
          meta: {
            datapointId: 1111,
          },
        },
        {
          path: 'proven-efficacy',
          name: 'homepage:proven-efficacy',
          component: ProvenEfficacy,
          meta: {
            datapointId: 1112,
          },
        },
        {
          path: 'exceptional-hydratation',
          name: 'homepage:exceptional-hydratation',
          component: ExceptionalHydratation,
          meta: {
            datapointId: 1113,
          },
        },
        {
          path: 'moisturizing-cream',
          name: 'homepage:moisturizing-cream',
          component: MoisturizingCream,
          meta: {
            datapointId: 1114,
          },
        },
        {
          path: 'moisturizing-lotion',
          name: 'homepage:moisturizing-lotion',
          component: MoisturizingLotion,
          meta: {
            datapointId: 1115,
          },
        }
      ],
    },
    {
      path: '/daily-cleansers',
      component: BlankPage,
      children: [
        {
          path: '',
          name: 'daily-cleansers',
          component: DailyCleansers,
          meta: {
            datapointId: 769,
          },
        },
        {
          path: 'gsc-benefits',
          name: 'daily-cleansers:gsc-benefits',
          component: GscBenefits,
          meta: {
            datapointId: 770,
          },
        },
        {
          path: 'gsc-features',
          name: 'daily-cleansers:gsc-features',
          component: GscFeatures,
          meta: {
            datapointId: 774,
          },
        },
        {
          path: 'gsc-cloths-benefits',
          name: 'daily-cleansers:gsc-cloths-benefits',
          component: GscClothsBenefits,
          meta: {
            datapointId: 775,
          },
        },
        {
          path: 'gsc-cloths-features',
          name: 'daily-cleansers:gsc-cloths-features',
          component: GscClothsFeatures,
          meta: {
            datapointId: 776,
          },
        },
        {
          path: 'hfc-cleanser-benefits',
          name: 'daily-cleansers:hfc-cleanser-benefits',
          component: HydratingFoamingCleanserBenefits,
          meta: {
            datapointId: 1018,
          },
        },
        {
          path: 'hfc-cleanser-features',
          name: 'daily-cleansers:hfc-cleanser-features',
          component: HydratingFoamingCleanserFeatures,
          meta: {
            datapointId: 1019,
          },
        },
        {
          path: 'dfc-ff-benefits',
          name: 'daily-cleansers:dfc-ff-benefits',
          component: DfcFfBenefits,
          meta: {
            datapointId: 1021,
          },
        },
        {
          path: 'dfc-ff-features',
          name: 'daily-cleansers:dfc-ff-features',
          component: DfcFfFeatures,
          meta: {
            datapointId: 1022,
          },
        },
      ],
    },
    {
      path: '/daily-moisturizers',
      component: BlankPage,
      children: [
        {
          path: '',
          name: 'daily-moisturizers',
          component: DailyMoisturizers,
          meta: {
            datapointId: 835,
          },
        },
        {
          path: 'dof-facial-moisturizer-benefits',
          name: 'daily-moisturizers:dof-facial-moisturizer-benefits',
          component: DoFFacialM35Benefits,
          meta: {
            datapointId: 848,
          },
        },
        {
          path: 'dof-facial-moisturizer-features',
          name: 'daily-moisturizers:dof-facial-moisturizer-features',
          component: DoFFacialM35Features,
          meta: {
            datapointId: 849,
          },
        },
        {
          path: 'moisturizing-cream-benefits',
          name: 'daily-moisturizers:moisturizing-cream-benefits',
          component: MoisturizingBenefits,
          meta: {
            datapointId: 836,
          },
        },
        {
          path: 'moisturizing-cream-features',
          name: 'daily-moisturizers:moisturizing-cream-features',
          component: MoisturizingCreamFeatures,
          meta: {
            datapointId: 839,
          },
        },
        {
          path: 'lotion-details',
          name: 'daily-moisturizers:lotion-details',
          component: DailyMoisturizersLotionDetails,
          meta: {
            datapointId: 840,
          },
        },
        {
          path: 'soothing-gel-benefits',
          name: 'daily-moisturizers:soothing-gel-benefits',
          component: SoothingGelBenefits,
          meta: {
            datapointId: 856,
          },
        },
        {
          path: 'soothing-gel-features',
          name: 'daily-moisturizers:soothing-gel-features',
          component: SoothingGelFeatures,
          meta: {
            datapointId: 857,
          },
        },
      ],
    },
    {
      path: '/special-benefits',
      component: BlankPage,
      children: [
        {
          path: '',
          name: 'special-benefits',
          component: SpecialBenefits,
          meta: {
            datapointId: 1120,
          },
        },
        {
          path: 'sheer-mineral-sun-screen-face',
          name: 'special-benefits:sheer-mineral-sun-screen-face',
          component: SheerMineralSunScreenFace,
          meta: {
            datapointId: 860,
          },
        },
        {
          path: 'sheer-mineral-sun-screen-face-features',
          name: 'special-benefits:sheer-mineral-sun-screen-face-features',
          component: SheerMineralSunScreenFaceFeatures,
          meta: {
            datapointId: 861,
          },
        },
        {
          path: 'sheer-mineral-stick',
          name: 'special-benefits:sheer-mineral-stick',
          component: SheerMineralStick,
          meta: {
            datapointId: 862,
          },
        },
        {
          path: 'sheer-mineral-stick-features',
          name: 'special-benefits:sheer-mineral-stick-features',
          component: SheerMineralStickFeatures,
          meta: {
            datapointId: 863,
          },
        },
      ],
    },
    {
      path: '/derma-control',
      component: BlankPage,
      children: [
        {
          path: '',
          name: 'derma-control',
          component: DermaControl,
          meta: {
            datapointId: 875,
          },
        },
        {
          path: 'dco-rfw-benefits',
          name: 'derma-control:dco-rfw-benefits',
          component: DcOilRFoamWBenefits,
          meta: {
            datapointId: 876,
          },
        },
        {
          path: 'dco-rfw-features',
          name: 'derma-control:dco-rfw-features',
          component: DcOilRFoamWFeatures,
          meta: {
            datapointId: 877,
          },
        },
        {
          path: 'dco-amspf30-benefits',
          name: 'derma-control:dco-amspf30-benefits',
          component: DcOilAMspf30Benefits,
          meta: {
            datapointId: 878,
          },
        },
        {
          path: 'dco-amspf30-features',
          name: 'derma-control:dco-amspf30-features',
          component: DcOilAMspf30Features,
          meta: {
            datapointId: 879,
          },
        },
        {
          path: 'soothing-wash-benefits',
          name: 'derma-control:soothing-wash-benefits',
          component: SoothingWashBenefits,
          meta: {
            datapointId: 881,
          },
        },
        {
          path: 'soothing-wash-features',
          name: 'derma-control:soothing-wash-features',
          component: SoothingWashFeatures,
          meta: {
            datapointId: 882,
          },
        },
        {
          path: 'restoraderm-eczema-benefits',
          name: 'derma-control:restoraderm-eczema-benefits',
          component: RestoradermEczemaBenefits,
          meta: {
            datapointId: 883,
          },
        },
        {
          path: 'restoraderm-eczema-features',
          name: 'derma-control:restoraderm-eczema-features',
          component: RestoradermEczemaFeatures,
          meta: {
            datapointId: 884,
          },
        },
        {
          path: 'restoraderm-flareup-benefits',
          name: 'derma-control:restoraderm-flareup-benefits',
          component: RestoradermFlareupBenefits,
          meta: {
            datapointId: 1037,
          },
        },
        {
          path: 'restoraderm-flareup-features',
          name: 'derma-control:restoraderm-flareup-features',
          component: RestoradermFlareupFeatures,
          meta: {
            datapointId: 1040,
          },
        },
        {
          path: 'itch-relief-gel-benefits',
          name: 'derma-control:itch-relief-gel-benefits',
          component: ItchReliefGelBenefits,
          meta: {
            datapointId: 1033,
          },
        },
        {
          path: 'itch-relief-gel-features',
          name: 'derma-control:itch-relief-gel-features',
          component: ItchReliefGelFeatures,
          meta: {
            datapointId: 1036,
          },
        }
      ],
    },
    {
      path: '/skin-concerns',
      component: BlankPage,
      children: [
        {
          path: '',
          name: 'skin-concerns',
          component: SkinConcerns,
          meta: {
            datapointId: 885,
          },
        },
        {
          path: 'rough-bumpy-benefits',
          name: 'skin-concerns:rough-bumpy-benefits',
          component: RoughBumpyBenefits,
          meta: {
            datapointId: 891,
          },
        },
        {
          path: 'rough-bumpy-features',
          name: 'skin-concerns:rough-bumpy-features',
          component: RoughBumpyFeatures,
          meta: {
            datapointId: 893,
          },
        },
        {
          path: 'healing-ointment-benefits',
          name: 'skin-concerns:healing-ointment-benefits',
          component: HealingOintmentBenefits,
          meta: {
            datapointId: 888,
          },
        },
        {
          path: 'healing-ointment-features',
          name: 'skin-concerns:healing-ointment-features',
          component: HealingOintmentFeatures,
          meta: {
            datapointId: 890,
          },
        },
        {
          path: 'gentle-clear-complexion-acne-benefits',
          name: 'skin-concerns:gentle-clear-complexion-acne-benefits',
          component: GentleClearComplexionAcneBenefits,
          meta: {
            datapointId: 1025,
          },
        },
        {
          path: 'gentle-clear-complexion-clearing-features',
          name: 'skin-concerns:gentle-clear-complexion-clearing-features',
          component: GentleClearComplexionClearingFeatures,
          meta: {
            datapointId: 1026,
          },
        },
      ],
    },
    {
      path: '/ctmp',
      name: 'ctmp',
      component: PersonalizedSkincareRoutines,
      meta: {
        datapointId: 1103,
      },
    },
  ],
});

// Based on https://router.vuejs.org/guide/advanced/navigation-failures.html
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
