<template>
  <page-layout>
    <div class="content slide-swipe">
      <div class="heading">
        <h1 class="main-section-title">
          Dermacontrol Oil Absorbing<br />Moisturizer spf 30
        </h1>
        <p class="description">
          Designed for oily, sensitive skin
        </p>
      </div>
      <div class="back-button">
        <img
          src="@/assets/images/jpg-png/back-button.png"
          width="39.5"
          alt=""
          @click="goLastCategory"
          @touchend="goLastCategory"
        />
      </div>
      <div class="content-product-row">
        <div class="image-product">
          <img src="@/assets/images/jpg-png/dermacoam-img-product.png" alt="" />
          <div class="product-nav">
            <ul>
              <li>
                <router-link
                  :to="{ name: 'derma-control:dco-amspf30-benefits' }"
                  >Key Benefits</router-link
                >
              </li>
              <li>
                <router-link
                  :to="{ name: 'derma-control:dco-amspf30-features' }"
                  >Key Features</router-link
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="feature-info">
          <h3 class="f-bold">Key Features</h3>
          <div class="key-features-info">
            <ul>
              <li>
                Paraben free, fragance free, oil free
              </li>
              <li>
                Oxybenzone &amp; octinoxate free
              </li>
              <li>
                Non-comedogenic, hypoallergenic
              </li>
              <li>
                Contains Zinc Gluconate - helps soothe acne-prone skin
              </li>
              <li>
                Contains Oleosome Technology - allows for broad<br />
                spectrum protection with a minimal quantity of UV filters
              </li>
              <li>
                MicroPearl mattifying technology - absorbs surface oils
              </li>
            </ul>
          </div>
          <div class="ingredients-info">
            <h3 class="f-bold">Ingredients</h3>
            <p>
              <span class="f-bold">Active Ingredients:</span> Avobenzone 3%,
              Octisalate 5%, Octocrylene 7% <br />
              <span class="f-bold">Inactive Ingredients:</span> Allantoin,
              Aluminum Starch Octenylsuccinate, Caprylyl Glycol, Carbomer,
              Diisopropyl Sebacate, Dimethicone, Dimethiconol, Disodium EDT A,
              Glycerin, Glycyrrhetinic Acid, Hydroxypalmitoyl Sphinganine
              (Ceramide), lsopropyl Lauroyl Sarcosinate, Panthenol, Pentylene
              Glycol, Phenoxyethanol, Polymethyl Methacrylate, Polysorbate 61,
              Potassium Sorbate, Silica, Sodium Stearoyl Glutamate, Sucrose
              Tristearate, Tocopheryl Acetate, Triethanolamine, Water, Xanthan
              Gum, Zinc Gluconate
            </p>
          </div>
        </div>
      </div>
    </div>
  </page-layout>
</template>

<script>
import PageLayout from "@/components/common/PageLayout.vue";

export default {
  components: {
    PageLayout,
  },
  mounted() {
    this.$route.params.onSwipeLeft = this.onSwipeLeft;
    this.$route.params.onSwipeRight = this.onSwipeRight;
  },
  methods: {
    onSwipeLeft() {
      return this.$router.push({ name: "derma-control:dco-amspf30-features" });
    },
    onSwipeRight() {
      return this.$router.push({ name: "derma-control:dco-amspf30-benefits" });
    },
  },
};
</script>

<style lang="scss">
.dco-amspf30-features {
  background: url(~@/assets/images/jpg-png/upper-bg-home.png) no-repeat;
  background-size: 100%;
  .content {
    .heading {
      position: absolute;
      top: 41px;
      left: 50px;
      h1 {
        margin-top: 0;
        margin-bottom: 22px;
        letter-spacing: -0.8px;
      }
    }
    .back-button {
      position: absolute;
      top: 151px;
      left: 50px;
    }
    &-product-row {
      display: flex;
      align-items: flex-start;
      position: relative;
      top: 176px;
      margin-left: 63px;
      .image-product {
        margin-top: 15px;
        width: 309px;
        margin-right: 80px;
        img {
          width: 100%;
        }
      }
      .feature-info {
        color: $main-font-color;
        width: 484px;
        z-index: 9999;
        h3 {
          margin: 0;
          margin-bottom: 10px;
        }
        .key-features-info {
          border-bottom: 1px solid rgb(200, 221, 234);
          padding-bottom: 23px;
          padding-top: 8px;
          letter-spacing: -0.5px;
          ul {
            margin: 0;
            padding: 0;
            li {
              font-family: "Sofia Pro Light";
              list-style: none;
              background: url(~@/assets/images/jpg-png/standard-arrow.png) 0 0
                no-repeat;
              background-size: 10px;
              padding-left: 15px;
              img {
                display: inline-block;
                margin-right: 10px;
              }
            }
          }
        }
        .ingredients-info {
          padding-top: 22px;
          p {
            font-family: "Sofia Pro Light";
            margin-top: -3px;
            margin-left: -2px;
            letter-spacing: -0.7px;
            line-height: 17px;
          }
        }
      }
    }
    .product-nav {
      position: relative;
      z-index: 9999;
      border: 1px solid $light-blue;
      box-shadow: 0px 9px 14px rgba($color: $gray-backdrop, $alpha: 0.06);
      border-radius: 8px;
      overflow: hidden;
      width: 194px;
      margin: 20px auto;
      ul {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
        li {
          list-style: none;
          > a {
            font-size: 12px;
            color: $main-font-color;
            padding: 10px 13px;
            line-height: 1;
            display: inline-block;
            font-family: "Sofia Pro Bold";
          }
          > a.router-link-exact-active {
            background: $light-blue-2;
            font-family: "Sofia Pro Bold";
          }
          &:first-child {
            border-right: 1px solid $light-blue;
          }
          &:last-child a {
            padding-right: 15px;
          }
        }
      }
    }
  }
}
</style>
