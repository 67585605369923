const LINKS_MAPPING = {
	'https://www.cetaphil.com/us/cleansers/gentle-cleansing-bar/302993923046.html':777,
	'https://www.cetaphil.com/us/cleansers/gentle-foaming-cleanser/302993889175.html':778,
	'https://www.cetaphil.com/us/cleansers/ultra-gentle-body-wash-fragrance-free/302993938163.html':779,
	'https://www.cetaphil.com/us/cleansers/ultra-gentle-body-wash-refreshing-scent/302993938064.html':780,
	'https://www.cetaphil.com/us/cleansers/ultra-gentle-body-wash-skin-soothing/302993938262.html':781,
	'https://www.cetaphil.com/us/cleansers/deep-cleansing-bar/302993923572.html':782,
	'https://www.cetaphil.com/us/cleansers/gentle-makeup-removing-wipes/302993889250.html':783,
	'https://www.cetaphil.com/us/cleansers/gentle-oil-free-makeup-remover/302993889069.html':784,
	'https://www.cetaphil.com/us/cleansers/daily-facial-cleanser/302993927341.html':785,
	'https://www.cetaphil.com/us/cleansers/extra-gentle-daily-scrub/302993889182.html':786,
	'https://www.cetaphil.com/us/moisturizers/daily-facial-moisturizer-spf-15/302993928041.html':844,
	'https://www.cetaphil.com/us/moisturizers/daily-facial-moisturizer-spf-50/302993930020.html': 845,
	'https://www.cetaphil.com/us/moisturizers/rich-hydrating-cream/302993889021.html': 846,
	'https://www.cetaphil.com/us/eye-creams/hydrating-eye-gel-cream/302993889168.html': 847,
	'https://www.cetaphil.com/us/moisturizers/daily-oil-free-hydrating-lotion/302993889038.html': 850,
	'https://www.cetaphil.com/us/moisturizers/advanced-relief-cream/302990116045.html': 851,
	'https://www.cetaphil.com/us/moisturizers/advanced-relief-lotion-with-shea-butter/302993914280.html': 852,
	'https://www.cetaphil.com/us/moisturizers/intensive-healing-lotion-with-ceramides/302993914938.html': 853,
	'https://www.cetaphil.com/us/moisturizers/cracked-skin-repair-balm/302994115006.html': 854,
	'https://www.cetaphil.com/us/moisturizers/cracked-skin-repair-lotion/302994114009.html': 855,
	'https://www.cetaphil.com/us/moisturizers/restoring-lotion/302993914945.html': 858,
	'https://www.cetaphil.com/us/sunscreens/sheer-mineral-sunscreen-broad-spectrum-spf-30/302994111008.html': 864,
	'https://www.cetaphil.com/us/sunscreens/sheer-mineral-sunscreen-broad-spectrum-spf-50/302994112005.html': 865,
	'https://www.cetaphil.com/us/moisturizers/deep-hydration-48-hour-activation-serum/302990117004.html': 866,
	'https://www.cetaphil.com/us/moisturizers/deep-hydration-healthy-glow-daily-cream/302990117011.html': 867,
	'https://www.cetaphil.com/us/eye-creams/deep-hydration-refreshing-eye-serum/302990100624.html': 868,
	'https://www.cetaphil.com/us/moisturizers/sheer-hydration-replenishing-body-lotion/302990117042.html': 869,
	'https://www.cetaphil.com/us/moisturizers/sheer-hydration-body-spray-moisturizer/302990117066.html': 870,
	'https://www.cetaphil.com/us/moisturizers/healthy-radiance-antioxidant-c-serum/302990116038.html': 871,
	'https://www.cetaphil.com/us/moisturizers/healthy-radiance-whipped-day-cream-spf-30/302994118007.html': 872,
	'https://www.cetaphil.com/us/moisturizers/healthy-radiance-renewing-cream/302990116014.html': 873,
	'https://www.cetaphil.com/us/cleansers/healthy-radiance-gentle-pha-exfoliating-cleanser/302990116021.html': 874,
	'https://www.cetaphil.com/us/cleansers/dermacontrol-purifying-clay-mask/302993931195.html': 880,
	'https://www.cetaphil.com/us/cleansers/gentle-clear-clarifying-acne-cream-cleanser/302994119004.html': 886,
	'https://www.cetaphil.com/us/moisturizers/gentle-clear-mattifying-acne-moisturizer/302994117000.html': 887,
	'https://www.cetaphil.com/us/cleansers/foaming-face-wash-for-redness-prone-skin/302993889083.html': 894,
	'https://www.cetaphil.com/us/moisturizers/redness-relieving-daily-facial-moisturizer-spf-20/302995889005.html': 895,
	'https://www.cetaphil.com/us/moisturizers/redness-relieving-night-moisturizer/302993889045.html': 896,
	'https://www.cetaphil.com/us/cleansers/acne-relief-body-wash/302994121007.html':897,
	'https://www.cetaphil.com/us/cleansers/flare-up-relief-body-wash/302990118032.html':898,
	'https://www.cetaphil.com/us/cleansers/moisturizing-relief-body-wash/302990118001.html':899,
	'https://www.cetaphil.com/us/cleansers/body-cleansers/smoothing-relief-body-wash/302990118049.html':900,
	'https://www.cetaphil.com/us/baby-skincare/baby-daily-lotion/302993936039.html':902,
	'https://www.cetaphil.com/us/baby-skincare/baby-eczema-soothing-lotion/302994140053.html':903,
	'https://www.cetaphil.com/us/baby-skincare/baby-ultra-soothing-lotion/302993936183.html':904,
	'https://www.cetaphil.com/us/baby-skincare/baby-soothe-and-protect-cream/302994103058.html':905,
	'https://www.cetaphil.com/us/baby-skincare/baby-gentle-wash/302993936091.html':906,
	'https://www.cetaphil.com/us/baby-skincare/baby-soothing-wash/302990200058.html':907,
	'https://www.cetaphil.com/us/baby-skincare/baby-wash-and-shampoo/302993936077.html':908,
	'https://www.cetaphil.com/us/moisturizers/facial-moisturizers-serums/deep-hydration-skin-restoring-water-gel/302990117073.html':1121,
	'https://www.cetaphil.com/us/products/skin-concern/anti-aging/healthy-renew-face-serum/302990260045.html':1122,
	'https://www.cetaphil.com/us/products/skin-concern/anti-aging/healthy-renew-eye-gel-serum/302990260021.html':1123,
	'https://www.cetaphil.com/us/products/skin-concern/anti-aging/healthy-renew-day-cream-spf-30/302994136001.html':1124,
	'https://www.cetaphil.com/us/products/skin-concern/anti-aging/healthy-renew-night-cream/302990260014.html':1125

}

export default LINKS_MAPPING;
