<template>
  <div class="content">
    <div class="content-left">
      <img src="@/assets/images/jpg-png/campwonder-banner.png" width="415.5" height="601" alt="">
    </div>
    <div class="content-right">
      <div class="content-right__head">
        <h3>RAISING AWARENESS ON SEVERE<br>OR RARE SKIN DISEASES</h3>
      </div>
      <div class="content-right__body">
        <div class="content-right__body-head">
          <div class="div">
            <p class="content-right__body-head-subheading">Partnership with</p>
            <h2 class="content-right__body-head-heading">Camp Wonder</h2>
            <p class="content-right__body-head-extrainfo">Through its brand Cetaphil<sup>®</sup>, Galderma<br>supports the <span class="f-bold">Children's Skin Disease</span><br>Foundation including Camp Wonder, a<br>summer camp for children with severe<br>skin deseases.</p>
          </div>
          <img src="@/assets/images/jpg-png/campwonder-log.png" width="100" height="164" alt="">
        </div>
        <div class="content-right__body-info">
          <div class="content-right__body-quote">
            <img class="left" src="@/assets/images/svg/campwonder-quote-left.svg" width="30" alt="">
            <img class="right" src="@/assets/images/svg/campwonder-quote-right.svg" width="30" alt="">
            <p> A place to experience the childhood joys of<br>summer camp, a place for kids to be kids,<br><span class="f-bold">Francesca Tenconi, CEO and Founder of<br>Camp Wonder</span></p>
          </div>
          <img @click="openModal('VideoCampWonderModal')" @touchend="openModal('VideoCampWonderModal')" src="@/assets/images/jpg-png/capwonder-play.png" alt="" class="content-right__body-play" width="242">
          <p class="content-right__body-prefooter">The Camp Wonder 2012 Special Edition of<br>the Cetaphil Moisturizing cream</p>
          <p class="content-right__body-footer">csdf.org/campwoder</p>
        </div>
      </div>
    </div>
    <img src="@/assets/images/jpg-png/campeworder-product.png" width="130" alt="" class="separator">
  </div>
</template>

<script>
export default {
  methods: {
    openModal(modalName) {
      this.toggleModal(modalName);
    }
  }
}
</script>

<style lang="scss">
.campwonder {
  .modal-layout {
    &__backdrop {
      background-color: #00325760;
      opacity: 1;
    }
    &__content {
      border-radius: 6.5px;
      border: 0px;
      width: 855.5px;
      height: 601px;
      margin-top: 12px;
      .content {
        display: flex;
        position: relative;
        .separator {
          position: absolute;
          left: 351px;
          bottom: 28px;
        }
        &-right {
          width: 100%;
          border-radius: 0px 6.5px 0px 0px;
          &__head {
            background: $campwonder-blue;
            color: $white;
            padding: 29.5px 10px 25.5px 35px;
            border-radius: inherit;
            h3 {
              font-size: 17px;
              line-height: 17px;
              text-transform: uppercase;
              margin: 0px;
              letter-spacing: -0.9px;
            }
          }
          &__body {
            &-head {
              display: flex;
              text-align: right;
              color: $campwonder-blue-title;
              padding-left: 35px;
              padding-top: 23px;
              padding-bottom: 26px;
              &-subheading {
                font-size: 16.5px;
                margin: 0px;
                margin-right: 38px;
                letter-spacing: -0.3px;
              }
              &-heading {
                font-size: 36px;
                margin: 0px;
                margin-right: 37px;
                letter-spacing: -1px;
              }
              &-extrainfo{
                margin: 0px;
                font-size: 15px;
                line-height: 15px;
                text-align: left;
                color: $campwonder-gray;
                margin-left: 4px;
                span {
                  line-height: 0px;
                }
              }
              img {
                margin-top: -58px;
                margin-left: 12px;
              }
            }
            &-info {
              padding-left: 77px;
            }
            &-quote {
              font-size: 15px;
              color: $campwonder-gray;
              line-height: 17.5px;
              font-style: italic;
              position: relative;
              span {
                line-height: 0px;
              }
              img{
                position: absolute;
                &.left {
                  top: -6px;
                  left: -38px;
                }
                &.right {
                  bottom: -8px;
                  right: 37px;
                }
              }
              p {
                margin: 0px;
              }
            }
            &-play {
              padding-top: 20px;
              padding-left: 12px;
            }
            &-prefooter {
              font-size: 15px;
              line-height: 15px;
              color: $dark-blue;
              margin: 15px 0px 0px 0px;
            }
            &-footer {
              font-size: 12.5px;
              text-align: right;
              color: $campwonder-gray;
              padding-right: 39.5px;
              letter-spacing: -0.5px;
            }
          }
        }
      }
    }
  }
}
</style>
