<template>
  <page-layout>
    <div class="content">
      <div class="main-heading">
        <h1>Daily cleansers</h1>
      </div>
      <div class="secondary-heading">
        <h4 class="text-short-description">
          Cetaphil daily cleansers are designed to effectively cleanse the
          face<br />
          and/or body without irritating sensitive skin.
        </h4>
      </div>
      <div class="content-products" style="margin-top:50px">
        <!--Card 1-->
        <div class="products-card-one-upper" style="display:flex">
          <!--Product-1-->
          <div class="container-img-product">
            <a @click="openDetail('daily-cleansers:gsc-benefits')">
              <img
                class="img-product"
                src="@/assets/images/jpg-png/gsc-img.png"
                width="70"
                style="margin-top:-33px"
                alt=""
              />
              <img
                class="internal-icon"
                src="@/assets/images/jpg-png/int-link-icon.png"
                style="margin-left:26px; cursor:pointer;"
                alt=""
              />
            </a>
          </div>
          <!--Product-3-->
          <div class="container-img-product">
            <a @click="openDetail('daily-cleansers:hfc-cleanser-benefits')">
              <img
                class="img-product"
                src="@/assets/images/jpg-png/hydating-foaming-cream-cleanser.png"
                width="70"
                style="margin-top:-33px; width:75px;"
                alt=""
              />
              <img
                class="internal-icon"
                src="@/assets/images/jpg-png/int-link-icon.png"
                style="margin-left:26px; margin-top: -1px; cursor:pointer;"
                alt=""
              />
            </a>
          </div>
          <!--Product-4-->
          <div class="container-img-product">
            <a
              @click="
                openLink(
                  'https://www.cetaphil.com/us/cleansers/gentle-cleansing-bar/302993923046.html'
                )
              "
              @touchend="
                openLink(
                  'https://www.cetaphil.com/us/cleansers/gentle-cleansing-bar/302993923046.html'
                )
              "
              target="_system"
            >
              <img
                class="img-product"
                src="@/assets/images/jpg-png/gcb-img.png"
                width="68"
                style="margin-top:-9px; margin-left:13px"
                alt=""
              />
              <img
                class="external-icon"
                src="@/assets/images/jpg-png/ext-link-icon.png"
                style="margin-top:-5px; margin-left:26px; cursor:pointer;"
                alt=""
              />
            </a>
          </div>
          <!--Product-5-->
          <div class="container-img-product">
            <a
              @click="
                openLink(
                  'https://www.cetaphil.com/us/cleansers/gentle-foaming-cleanser/302993889175.html'
                )
              "
              @touchend="
                openLink(
                  'https://www.cetaphil.com/us/cleansers/gentle-foaming-cleanser/302993889175.html'
                )
              "
              target="_system"
            >
              <img
                src="@/assets/images/jpg-png/gfc-img.png"
                width="54"
                style="margin-top:-46px; margin-left:20px;"
                alt=""
              />
              <img
                class="external-icon"
                src="@/assets/images/jpg-png/ext-link-icon.png"
                style="margin-top:-6px; margin-left:26px; cursor:pointer;"
                alt=""
              />
            </a>
          </div>
          <!--Product-6-->
          <div class="container-img-product">
            <a
              @click="
                openLink(
                  'https://www.cetaphil.com/us/cleansers/ultra-gentle-body-wash-fragrance-free/302993938163.html'
                )
              "
              @touchend="
                openLink(
                  'https://www.cetaphil.com/us/cleansers/ultra-gentle-body-wash-fragrance-free/302993938163.html'
                )
              "
              target="_system"
            >
              <img
                src="@/assets/images/jpg-png/ugbw-img.png"
                width="57"
                style="margin-top:-48px; margin-left:18px"
                alt=""
              />
              <img
                class="external-icon"
                src="@/assets/images/jpg-png/ext-link-icon.png"
                style="margin-top:-7px; margin-left:26px; cursor:pointer;"
                alt=""
              />
            </a>
          </div>
          <!--Product-7-->
          <div class="container-img-product">
            <a
              @click="
                openLink(
                  'https://www.cetaphil.com/us/cleansers/ultra-gentle-body-wash-refreshing-scent/302993938064.html'
                )
              "
              @touchend="
                openLink(
                  'https://www.cetaphil.com/us/cleansers/ultra-gentle-body-wash-refreshing-scent/302993938064.html'
                )
              "
              target="_system"
            >
              <img
                src="@/assets/images/jpg-png/ugrbw-img.png"
                width="57"
                style="margin-top:-48px; margin-left:16px"
                alt=""
              />
              <img
                class="external-icon"
                src="@/assets/images/jpg-png/ext-link-icon.png"
                style="margin-top:-6px; margin-left:26px; cursor:pointer;"
                alt=""
              />
            </a>
          </div>
          <!--Product-8-->
          <div class="container-img-product">
            <a
              @click="
                openLink(
                  'https://www.cetaphil.com/us/cleansers/ultra-gentle-body-wash-skin-soothing/302993938262.html'
                )
              "
              @touchend="
                openLink(
                  'https://www.cetaphil.com/us/cleansers/ultra-gentle-body-wash-skin-soothing/302993938262.html'
                )
              "
              target="_system"
            >
              <img
                src="@/assets/images/jpg-png/ugsbw-img.png"
                width="57"
                style="margin-top:-48px; margin-left:14px"
                alt=""
              />
              <img
                class="external-icon"
                src="@/assets/images/jpg-png/ext-link-icon.png"
                style="margin-top:-8px; margin-left:24px; cursor:pointer;"
                alt=""
              />
            </a>
          </div>
          <!--Product-9-->
          <div class="container-img-product">
            <a
              @click="
                openLink(
                  'https://www.cetaphil.com/us/cleansers/deep-cleansing-bar/302993923572.html'
                )
              "
              @touchend="
                openLink(
                  'https://www.cetaphil.com/us/cleansers/deep-cleansing-bar/302993923572.html'
                )
              "
              target="_system"
            >
              <img
                src="@/assets/images/jpg-png/dcb-img.png"
                width="68"
                style="margin-top:-9px; margin-left:13px"
                alt=""
              />
              <img
                class="external-icon"
                src="@/assets/images/jpg-png/ext-link-icon.png"
                style="margin-top:-5px; margin-left:26px; cursor:pointer;"
                alt=""
              />
            </a>
          </div>
        </div>
        <div class="products-card-one-main" style="display:flex">
          <a href="#" class="archor-card-1">Dry to Normal Skin</a>
        </div>

        <!--Card 2-->
        <div class="products-card-two-upper" style="display:flex">
          <!--Product-1-->
          <div class="container-img-product">
            <a @click="openDetail('daily-cleansers:gsc-cloths-benefits')">
              <img
                class="img-product"
                src="@/assets/images/jpg-png/gsc-cloth-v2.png"
                width="72"
                style="margin-top:-40px; margin-left:5px"
                alt=""
              />
              <img
                class="internal-icon"
                src="@/assets/images/jpg-png/int-link-icon.png"
                style="margin-left:33px; cursor:pointer;"
                alt=""
              />
            </a>
          </div>
          <!--Product-2-->
          <div class="container-img-product">
            <a
              @click="
                openLink(
                  'https://www.cetaphil.com/us/cleansers/gentle-makeup-removing-wipes/302993889250.html'
                )
              "
              @touchend="
                openLink(
                  'https://www.cetaphil.com/us/cleansers/gentle-makeup-removing-wipes/302993889250.html'
                )
              "
              target="_system"
            >
              <img
                class="img-product"
                src="@/assets/images/jpg-png/gmrw-img.png"
                width="137"
                style="margin-top:8px; margin-left:-10px"
                alt=""
              />
              <img
                class="external-icon"
                src="@/assets/images/jpg-png/ext-link-icon.png"
                style="margin-top:-8px; margin-left:41px; cursor:pointer;"
                alt=""
              />
            </a>
          </div>
          <!--Product-3-->
          <div class="container-img-product">
            <a
              @click="
                openLink(
                  'https://www.cetaphil.com/us/cleansers/gentle-oil-free-makeup-remover/302993889069.html'
                )
              "
              @touchend="
                openLink(
                  'https://www.cetaphil.com/us/cleansers/gentle-oil-free-makeup-remover/302993889069.html'
                )
              "
              target="_system"
            >
              <img
                src="@/assets/images/jpg-png/gofmr-img.png"
                width="53"
                style="margin-top:-34px"
                alt=""
              />
              <img
                class="external-icon"
                src="@/assets/images/jpg-png/ext-link-icon.png"
                style="margin-top:-8px; margin-left:7px; cursor:pointer;"
                alt=""
              />
            </a>
          </div>
        </div>

        <div class="products-card-two-main" style="display:flex">
          <a href="#" class="archor-card-2">All Skin Types</a>
        </div>

        <!--Card 3-->
        <div class="products-card-three-upper" style="display:flex">
        <!-- Product 1 -->
          <div class="container-img-product">
            <a @click="openDetail('daily-cleansers:dfc-ff-benefits')">
              <img
                class="img-product"
                src="@/assets/images/jpg-png/daily-facial-cleanser-fragance-free.png"
                width="71"
                style="margin-top:-33px"
                alt=""
              />
              <img
                class="internal-icon"
                src="@/assets/images/jpg-png/int-link-icon.png"
                style="margin-left:26px; cursor:pointer;"
                alt=""
              />
            </a>
          </div>
          <!--Product-2-->
          <div class="container-img-product">
            <a
              @click="
                openLink(
                  'https://www.cetaphil.com/us/cleansers/daily-facial-cleanser/302993927341.html'
                )
              "
              @touchend="
                openLink(
                  'https://www.cetaphil.com/us/cleansers/daily-facial-cleanser/302993927341.html'
                )
              "
              target="_system"
            >
              <img
                class="img-product"
                src="@/assets/images/jpg-png/daily-facial-cleanser-new.png"
                width="72"
                style="margin-top:-33px;  margin-left:9px;"
                alt=""
              />
              <img
                class="external-icon"
                src="@/assets/images/jpg-png/ext-link-icon.png"
                style="margin-top:-7px; margin-left:24px; cursor:pointer;"
                alt=""
              />
            </a>
          </div>
          <!--Product-3-->
          <div class="container-img-product">
            <a
              @click="
                openLink(
                  'https://www.cetaphil.com/us/cleansers/extra-gentle-daily-scrub/302993889182.html'
                )
              "
              @touchend="
                openLink(
                  'https://www.cetaphil.com/us/cleansers/extra-gentle-daily-scrub/302993889182.html'
                )
              "
              target="_system"
            >
              <img
                src="@/assets/images/jpg-png/egds-img.png"
                width="58"
                style="margin-top:-28px; margin-left:11px;"
                alt=""
              />
              <img
                class="external-icon"
                src="@/assets/images/jpg-png/ext-link-icon.png"
                style="margin-top:-6px; margin-left:22px; cursor:pointer;"
                alt=""
              />
            </a>
          </div>
        </div>
        <div class="products-card-three-main" style="display:flex">
          <a href="#" class="archor-card-3">Combination to Oily Skin</a>
        </div>
      </div>
    </div>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/common/PageLayout.vue';
export default {
  components: {
    PageLayout,
  },
  mounted() {
      this.$route.params.onSwipeRight = this.onSwipeRight;
  },
  methods: {
    openDetail(name) {
      return this.$router.push({ name });
    },
    onSwipeRight() {
        console.log('homepage:proven-efficacy')
        return this.$router.push({ name: 'homepage:proven-efficacy' });
    },
  },
  created() {
    this.setLastCategory('daily-cleansers');
  },
};
</script>

<style lang="scss" scoped>
.daily-cleansers {
  background: url(~@/assets/images/jpg-png/upper-bg-home.png) no-repeat;
  background-size: 100%;
  .content {
    .main-heading {
      margin-left: 50px;
      margin-top: 40px;
      position: relative;
      h1 {
        font-size: 28px;
        font-family: 'Sofia Pro bold';
        text-transform: uppercase;
        color: #004987;
        line-height: 0.95;
        letter-spacing: -0.6px;
        sup {
          font-size: 12px;
          vertical-align: top;
          margin-top: 5px;
          margin-left: 2px;
          display: inline-block;
        }
      }
      h2 {
        font-size: 23px;
        color: $white;
        text-transform: uppercase;
        line-height: 0.95;
        width: 200px;
        position: absolute;
        right: 89px;
        top: 43px;
        span {
          display: inline-block;
          margin-left: 28px;
          line-height: 1.1;
          letter-spacing: 1.5px;
        }
      }
    }
    .secondary-heading {
      .text-short-description {
        font-family: 'Sofia Pro Light';
        font-weight: 300;
        font-size: 14px;
        letter-spacing: -0.68px;
        margin-left: 50px;
        color: #004987;
        line-height: 1.5;
      }
    }
    .content-products {
      width: 900px;
      margin-top: 40px;
      .products-card-one-upper,
      .products-card-two-upper,
      .products-card-three-upper {
        background: #e6f5fb;
        border: 1px solid #e6f5fb;
        border-radius: 8px;
        opacity: 1;
        position: absolute;
        left: 54px;
        height: 96px;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
        margin-top: 17px;
        width: 891px;
        z-index: 1;
        .container-img-product {
          width: 124px;
          margin-left: 25px;
          img.img-product {
            margin-top: -30px;
          }
          .external-icon {
            width: 38px;
          }
          .internal-icon {
            width: 18px;
          }
        }
      }
      .products-card-three-upper {
        .container-img-product {
          margin-left: 8px;
        }
      }
      .products-card-one-upper {
        .container-img-product {
          width: 82px;
        }
      }
      .products-card-two-upper {
        margin-top: 226px;
        width: 362px;
        position: absolute;
        z-index: 10001;
      }
      .products-card-three-upper {
        margin-top: 226px;
        margin-left: 420px;
        width: 250px;
        position: absolute;
        z-index: 10001;
      }
      .products-card-one-main,
      .products-card-two-main,
      .products-card-three-main {
        display: flex;
        background: #fff;
        border: 1px solid #c8ddea;
        border-radius: 8px;
        opacity: 1;
        position: absolute;
        left: 54px;
        height: 117px;
        padding: 20px 10px 20px 10px;
        width: 892px;
        margin-top: 24px;
        box-shadow: 0px 9px 14px #0324720d;
        .archor-card-1,
        .archor-card-2,
        .archor-card-3 {
          font-size: 13px;
          font-weight: 400;
          color: #004987;
        }
      }
      .products-card-one-main {
        .archor-card-1 {
          margin-top: 115px;
          margin-left: 388px;
        }
      }
      .products-card-two-main {
        margin-top: 237px;
        width: 360px;
        position: absolute;
        z-index: 10000;
        .archor-card-2 {
          margin-top: 111px;
          margin-left: 150px;
        }
      }
      .products-card-three-main {
        margin-top: 237px;
        margin-left: 420px;
        width: 250px;
        position: absolute;
        z-index: 10000;
        .archor-card-3 {
          margin-top: 113px;
          margin-left: 52px;
        }
      }
    }
  }
}
</style>
