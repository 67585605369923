<template>
  <div class="content">
    <h1 class="f-bold uppercase">
      Maintains skin barrier even after repeated washes <br />
      vs baseline and control
    </h1>
    <p
      style="color:#63656A; font-weight:600;text-align:center; letter-spaciong:1px;"
    >
      TEWL measurements over time (16 washes in 4 days)
    </p>
    <div class="content-chart" style="display:flex; justify-content:center;">
      <img
        src="@/assets/images/jpg-png/gsc-modal-3-chart.png"
        width="520"
        alt=""
      />
    </div>
    <div class="footnote">
      <p class="f-regular">TEWL=Transepidermal Water Loss</p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.gscmodal3 > .modal-layout__content {
  background: url(~@/assets/images/jpg-png/upper-bg-home.png) no-repeat;
  border: 1px solid #fff;
  border-radius: 8px;
  .content {
    margin: 20px;
    h1 {
      font-size: 25px;
    }
    .footnote {
      position: absolute;
      left: 182px;
      color: #64676a;
      font-size: 12px;
    }
  }
}
.gsc-modal3 {
  .modal-layout__close {
    top: 18px;
  }
  .modal-layout__content {
    width: 931px;
    height: 642px;
    top: 17px;
    left: 2px;
  }
  font-family: 'Sofia Pro';
  color: $main-font-color;
}
</style>
