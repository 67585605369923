<template>
  <div class="content">
    <h1 class="f-bold">Significantly Restores Skin Barrier At Day 3,<br /> with complete recovery by day 7</h1>
    <img src="@/assets/images/jpg-png/moisturizing-modal-4-chart.png" alt="" />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.moisturizingcreammodalone > .modal-layout__content {
    background: url(~@/assets/images/jpg-png/upper-bg-home.png) no-repeat;
    border: 1px solid #fff;
    border-radius: 8px;
    display: flex;
    .content {
        margin: 20px;
        h1 {
            font-size: 30px;
            text-transform: uppercase;
        }
        img {
            width: 550px;
            margin-left: 120px;
        }
    }
}

</style>