<template>
  <page-layout>
    <div class="content slide-swipe">
      <div class="heading">
        <h1 class="main-section-title">ROUGH &amp; BUMPY</h1>
        <p class="description">
          Designed for rough and bumpy, sensitive skin
        </p>
      </div>
      <div class="back-button">
        <img
          src="@/assets/images/jpg-png/back-button.png"
          width="39.5"
          alt=""
          @click="goLastCategory"
          @touchend="goLastCategory"
        />
      </div>
      <div class="content-product-row">
        <div class="image-product">
          <img
            src="@/assets/images/jpg-png/products/skin-concern/details/rough-bumpy-detail.png"
            alt=""
          />
          <div class="product-nav">
            <ul>
              <li>
                <router-link
                  :to="{ name: 'skin-concerns:rough-bumpy-benefits' }"
                  >Key Benefits</router-link
                >
              </li>
              <li>
                <router-link
                  :to="{ name: 'skin-concerns:rough-bumpy-features' }"
                  >Key Features</router-link
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="feature-content">
          <h3 class="f-bold">Key Features</h3>
          <ul class="f-light bullets">
            <li>Fragrance free, mineral oil free</li>
            <li>Non-comedogenic, hypoallergenic</li>
            <li>Formulated with 20% Urea</li>
          </ul>
          <h3 class="f-bold">Ingredients</h3>
          <p class="f-light">
            Water, Urea, Cetyl Lactate, Myristyl Lactate, Glycerin, Propylene
            Glycol, Prunus Amygdalus Dulcis (Sweet Almond) Oil, Glyceryl
            Stearate, Cetearyl Alcohol, Cetyl Alcohol, Ceteareth-20,
            Dimethicone, Carbomer, Methylparaben, Propylparaben, Sodium
            Hydroxide.
          </p>
        </div>
      </div>
    </div>
  </page-layout>
</template>

<script>
import PageLayout from "@/components/common/PageLayout.vue";

export default {
  components: {
    PageLayout,
  },
  mounted() {
    this.$route.params.onSwipeLeft = this.onSwipeLeft;
    this.$route.params.onSwipeRight = this.onSwipeRight;
  },
  methods: {
    onSwipeLeft() {
      return this.$router.push({ name: "skin-concerns:rough-bumpy-features" });
    },
    onSwipeRight() {
      return this.$router.push({ name: "skin-concerns:rough-bumpy-benefits" });
    },
  },
};
</script>

<style lang="scss">
.rough-bumpy-features {
  background: url(~@/assets/images/jpg-png/upper-bg-home.png) no-repeat;
  background-size: 100%;
  .content {
    .heading {
      position: absolute;
      top: 41px;
      left: 50px;
      h1 {
        margin-top: 0;
        margin-bottom: 22px;
        letter-spacing: -0.8px;
      }
    }
    .back-button {
      position: absolute;
      top: 151px;
      left: 50px;
    }
    .description {
      max-width: 450px;
    }
    &-product-row {
      display: flex;
      align-items: flex-start;
      position: relative;
      top: 176px;
      margin-left: 63px;
      h3 {
        margin: 0;
        color: #004987;
        letter-spacing: 0.15px;
      }
      .image-product {
        margin-top: 15px;
        width: 309px;
        margin-right: 110px;
        img {
          width: 100%;
        }
      }
      .feature-content {
        width: 418px;
        margin-top: 40px;
        color: $dark-blue;
        letter-spacing: -0.52px;

        p {
          margin-top: 13px;
          line-height: 17px;
        }

        ul {
          border-bottom: 1px solid $light-blue-2;
          padding-bottom: 27px;
          margin-bottom: 26px;
          margin-top: 15px;
        }

        &-inner {
          height: 365px;
          display: flex;
          flex-flow: column;
          justify-content: space-between;
        }
      }
    }
    .product-nav {
      position: relative;
      z-index: 9999;
      border: 1px solid $light-blue;
      box-shadow: 0px 9px 14px rgba($color: $gray-backdrop, $alpha: 0.06);
      border-radius: 8px;
      overflow: hidden;
      width: 194px;
      margin: 20px auto;
      ul {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
        li {
          list-style: none;
          > a {
            font-size: 12px;
            color: $main-font-color;
            padding: 10px 13px;
            line-height: 1;
            display: inline-block;
            font-family: "Sofia Pro Bold";
          }
          > a.router-link-exact-active {
            background: $light-blue-2;
            font-family: "Sofia Pro Bold";
          }
          &:first-child {
            border-right: 1px solid $light-blue;
          }
          &:last-child a {
            padding-right: 15px;
          }
        }
      }
    }
  }
}
</style>
