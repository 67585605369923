<template>
  <page-layout>
    <div class="content slide-swipe">
      <div class="heading">
        <h1 class="main-section-title">
          Sheer mineral sunscreen stick <br />
          broad spectrum spf 50
        </h1>
        <p class="description">
          Ultra-lightweight, 100% mineral active sunscreen that reflects both
          UVA and <br />
          UVB rays to help prevent sunburn.
        </p>
      </div>
      <div class="back-button">
        <img
          src="@/assets/images/jpg-png/back-button.png"
          width="39.5"
          alt=""
          @click="goLastCategory"
          @touchend="goLastCategory"
        />
      </div>
      <div class="content-product-row">
        <div class="image-product">
          <img
            src="@/assets/images/jpg-png/sheer-mineral-stick-detail.png"
            alt=""
          />
        </div>
        <div class="feature-icons">
          <img src="@/assets/images/jpg-png/sensitive-skin-icon.png" alt="" />
          <img src="@/assets/images/jpg-png/skin-oil-icon.png" alt="" />
          <img src="@/assets/images/jpg-png/ingredients-icon.png" alt="" />
        </div>
        <div class="feature-info">
          <h3 class="f-bold">For sensitive skin</h3>
          <div class="feature-info-skin-oil">
            <p class="f-light">
              100% mineral active sunscreen that reflects UVA/<br />
              UVB rays to protect skin and prevent sunburn
            </p>
          </div>
          <div class="feature-info-hours-barrier">
            <p class="f-light">
              Formulated with a combination of sheer zinc <br />
              oxide and titanium dioxide with prebiotics and <br />
              soothing vitamin E to help protect skin from surface free radicals
            </p>
          </div>
          <div class="feature-info-sensitive-skin">
            <p class="f-light">
              Avobenzone, Oxybenzone, <br />
              Octinoxate & PABA free
            </p>
          </div>
        </div>
      </div>
      <div class="product-nav">
        <ul>
          <li>
            <router-link :to="{ name: 'special-benefits:sheer-mineral-stick' }"
              >Key Benefits</router-link
            >
          </li>
          <li>
            <router-link :to="{ name: 'special-benefits:sheer-mineral-stick-features' }"
              >Key Features</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/common/PageLayout.vue';

export default {
  components: {
    PageLayout,
  },
  // to organize when features screen is created
  mounted(){
    this.$route.params.onSwipeLeft = this.onSwipeLeft;
    this.$route.params.onSwipeRight = this.onSwipeRight;
  },
  methods: {
    onSwipeLeft() {
      return this.$router.push({name: 'special-benefits:sheer-mineral-stick-features'});
    },
    onSwipeRight() {
      return this.$router.push({name: 'special-benefits:sheer-mineral-stick'});
    }
  }
};
</script>

<style lang="scss">
.sheer-mineral-stick {
  background: url(~@/assets/images/jpg-png/upper-bg-home.png) no-repeat;
  background-size: 100%;
  .content {
    margin-left: 50.5px;
    margin-top: 38px;
    .heading {
      .main-section-title {
        margin-bottom: 10px;
        letter-spacing: -0.84px;
      }
    }
    .back-button {
      position: absolute;
      top: 170px;
    }
    &-product-row {
      display: flex;
      align-items: center;
      .image-product {
        margin-top: -11px;
        margin-left: 11px;
        img {
          width: 309px;
        }
      }
      .feature-icons {
        display: flex;
        flex-direction: column;
        margin-top: 77px;
        margin-left: 38px;
        margin-right: 47px;
        img {
          width: 100px;
          margin-bottom: 30px;
        }
      }
      .feature-info {
        position: relative;
        width: 326px;
        height: 360px;
        color: $main-font-color;
        h3 {
          position: absolute;
          width: 100%;
          top: -36px;
          left: -4px;
        }
        &-skin-oil {
          position: absolute;
          border-bottom: 1px solid rgb(200, 221, 234);
          letter-spacing: -0.5px;
          width: 100%;
          top: 30px;
          left: -6px;
          padding-bottom: 34px;
        }
        &-hours-barrier {
          position: absolute;
          border-bottom: 1px solid rgb(200, 221, 234);
          width: 100%;
          bottom: 94px;
          left: -6px;
          letter-spacing: -0.4px;
          padding-bottom: 10px;
          line-height: 1.1;
          p {
            margin-bottom: 20px;
          }
          ul {
            margin: 0;
            padding: 0;
            li {
              font-family: 'Sofia Pro Light';
              list-style: none;
              margin-bottom: 5px;
              &:first-child {
                text-indent: -15px;
                margin-left: 13px;
                margin-bottom: 20px;
              }
              img {
                display: inline-block;
                margin-right: 3px;
              }
            }
          }
        }
        &-sensitive-skin {
          position: absolute;
          width: 100%;
          bottom: -2px;
          left: -7px;
          letter-spacing: -0.4px;
        }
      }
    }
    .product-nav {
      position: absolute;
      z-index: 9999;
      bottom: 127px;
      left: 119px;
      border: 1px solid $light-blue;
      box-shadow: 0px 9px 14px rgba($color: $gray-backdrop, $alpha: 0.06);
      border-radius: 8px;
      overflow: hidden;
      ul {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
        li {
          list-style: none;
          > a {
            font-size: 12.5px;
            color: $main-font-color;
            padding: 10px 12.5px;
            line-height: 1;
            display: inline-block;
            font-family: 'Sofia Pro Bold';
          }
          > a.router-link-exact-active {
            background: $light-blue-2;
          }
          &:first-child {
            border-right: 1px solid $light-blue;
          }
        }
      }
    }
  }
}
</style>
