<template>
    <page-layout>
      <div class="content">
        <div class="heading">
            <h1 class="main-title">Pioneering Extensive Research</h1>
            <p class="description">
                Sensitive skin is prevalent worldwide. The overall <br>
                average number of individuals in the general <br>
                population who claimed some degree of sensitivity <br>
                (e.g., "very," "moderately," or "slightly" sensitive) was <br>
                about 70%.<sup>1-2</sup>
            </p>
        </div>
        <div class="heading-img">
            <img
                class="people-img"
                src="@/assets/images/svg/selected-people.svg"
                alt="selected people"
            />
            <img
                class="map-img"
                src="@/assets/images/svg/results-map.svg"
                alt="results map"
            />
        </div>
        <div class="main-info">
            <p>
                For <span class="bolder">75 years</span> Cetaphil has been committed to knowing<br>
                sensitive skin deeper than anyone else.<sup>3</sup>
            </p>
        </div>
        <div class="results-container">
            <div class="result">
                <div class="img-container">
                    <img
                        src="@/assets/images/svg/clinical-studies-info.svg"
                        alt="clinical studies info"
                    />
                </div>
                <p>
                    Included in over 850+<br>
                    Galderma clinical studies.<sup>4</sup>
                </p>
            </div>
            <div class="result">
                <div class="img-container">
                    <img
                        src="@/assets/images/svg/subjects-info.svg"
                        alt="subjects info"
                    />
                </div>
                <p>
                    Clinically tested on more than<br>
                    37,000+ subjects.<sup>4</sup>
                </p>
            </div>
            <div class="result">
                <div class="img-container">
                    <img
                        src="@/assets/images/svg/phase-iv-info.svg"
                        alt="phase iv info"
                    />
                </div>
                <p>
                    Proven compatibility with<br>
                    prescription treatments.
                </p>
            </div>
        </div>
        <a @click="goPage('homepage:proven-efficacy')" class="next-link">
            <p class="f-bold">Proven Efficacy</p>
            <img
                src="@/assets/images/svg/next-icon.svg"
                alt="next icon"
            />
        </a>
      </div>
    </page-layout>
</template>
  
<script>
    import PageLayout from '@/components/common/PageLayout.vue';

    export default {
        components: {
            PageLayout,
        },
        mounted() {
            this.$route.params.onSwipeLeft = this.onSwipeLeft;
            this.$route.params.onSwipeRight = this.onSwipeRight;
        },
        methods: {
            goPage(name) {
                return this.$router.push({ name });
            },
            onSwipeLeft() {
                return this.$router.push({ name: 'homepage:proven-efficacy' });
            },
            onSwipeRight() {
                return this.$router.push({ name: 'homepage' });
            },
        }
    };
</script>
  
<style lang="scss">
.pioneering-extensive-research {
    background: url(~@/assets/images/jpg-png/upper-bg-home.png) no-repeat;
    background-size: 100%;
    .content {
        margin-left: 50.5px;
        margin-top: 60px;
        .heading {
            margin-top: 52px;
            .main-title {
                font-family: 'Sofia Pro Bold';
                font-size: 27.5px;
                text-transform: uppercase;
                color: $main-font-color;
                letter-spacing: -0.94px;
                line-height: 1.1;
            }
            .description {
                font-size: 18px;
                sup {
                    font-family: "Sofia Pro Semibold";
                    font-size: 10px;
                }
            }
            
        }
        .heading-img {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            margin-bottom:  -90px;
            .people-img {
                position: relative;
                margin-right: -205px;
                top: -90px;
                z-index: 3;
            }
            .map-img {
                position: relative;
                top: -90px;
                margin-right: 230px;
                z-index: 2;
                stroke-width: 0.37px;
                stroke: #AAD2F0;
                opacity: 70%;
            }
        }
        .main-info {
            margin-left: -68px;
            p { 
                color: $main-font-color;
                text-align: center;
                font-family: 'Sofia Pro Light';
                font-size: 22px;
                font-weight: 400;
                line-height: 1.2;
                .bolder {
                    font-family: "Sofia Pro Bold";
                }
                sup {
                    font-family: "Sofia Pro Semibold";
                    font-size: 12px;
                }
            }
        }
        .results-container {
            width: 64%;
            display: flex;
            justify-content: space-between;
            margin-left: 125px;
            margin-top: 25px;
            .img-container {
                text-align: center;
            }
            p {
                font-family: 'Sofia Pro Light';
                font-size: 14px;
                color: $main-font-color;
                text-align: center;
                sup {
                    font-size: 60%;
                }
            }
        }
        .next-link {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-top: 4px;
            right: 55px;
            position: absolute;
            z-index: 10000;
            font-size: 14px;
            p {
                text-decoration: underline;
                color: #63AF45;
            }
            img {
                padding-left: 10px;
                padding-top: 5px;
            }
        }
    }
}
</style>
  