<template>
  <page-layout>
    <div class="content slide-swipe">
      <div class="heading">
        <h1 class="main-section-title">
          Gentle clear complexion <br />
          clearing acne cleanser <br />
          with 2.6% benzoyl peroxide <br />
          acne treatment
        </h1>
        <p class="description">
          Formulated with micronized Benzoyl Peroxide to immediately target
          <br />
          acne-causing bacteria, without further irritating or stripping the
          skin's <br />
          natural moisture barrier.
        </p>
      </div>
      <div class="back-button">
        <img
          src="@/assets/images/jpg-png/back-button.png"
          width="39.5"
          alt=""
          @click="goLastCategory"
          @touchend="goLastCategory"
        />
      </div>
      <div class="content-product-row">
        <div class="image-product">
          <img
            src="@/assets/images/jpg-png/gentle-clear-complexion-detail-image.png"
            alt=""
          />
        </div>
        <div class="feature-icons">
          <img src="@/assets/images/jpg-png/93-percent-icon.png" alt="" />
          <img src="@/assets/images/jpg-png/healthy-looking-logo.png" alt="" />
          <img
            src="@/assets/images/jpg-png/hidratation-double-drop.png"
            alt=""
          />
        </div>
        <div class="feature-info">
          <h3 class="f-bold">
            For acne-prone, sensitive skin
            <img
              class="face-body-icons"
              src="@/assets/images/jpg-png/face-icon.png"
              alt=""
            />
            <div class="face-overlay"></div>
          </h3>
          <div class="feature-info-skin-oil">
            <p class="f-light text-info">
              93% of subjects said this cleanser left their skin feeling <br />
              <span class="f-bold">PURIFIED WITHOUT STRIPPING THEIR SKIN</span>
              after just 1 day
            </p>
          </div>
          <div class="feature-info-hours-barrier">
            <p class="f-light text-info">
              <span class="f-bold">REDUCES SURFACE OIL LEVEL BY 79%</span>
              after just 1 use <br />
              while <span class="f-bold">LICORICE ROOT SOOTHES</span> the skin
            </p>
          </div>
          <div class="feature-info-sensitive-skin">
            <p class="f-light text-info">
              Clinically proven to
              <span class="f-bold">REDUCE BLEMISHES BY 25%</span> <br />
              after just 1 week
            </p>
          </div>
        </div>
        <div class="feature-btn">
          <img
            @click="openModal('GentleClearKeyBenefitsFace')"
            @touchend="openModal('GentleClearKeyBenefitsFace')"
            class="see-data-2"
            src="@/assets/images/jpg-png/see-data-img.png"
            width="70"
            alt=""
          />
        </div>
      </div>
      <div class="product-nav">
        <ul>
          <li>
            <router-link
              :to="{
                name: 'skin-concerns:gentle-clear-complexion-acne-benefits',
              }"
              >Key Benefits</router-link
            >
          </li>
          <li>
            <router-link
              :to="{
                name: 'skin-concerns:gentle-clear-complexion-clearing-features',
              }"
              >Key Features</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/common/PageLayout.vue';

export default {
  components: {
    PageLayout,
  },
  mounted() {
    this.$route.params.onSwipeLeft = this.onSwipeLeft;
    this.$route.params.onSwipeRight = this.onSwipeRight;
  },
  methods: {
    onSwipeLeft() {
      return this.$router.push({
        name: 'skin-concerns:gentle-clear-complexion-clearing-features',
      });
    },
    onSwipeRight() {
      return this.$router.push({
        name: 'skin-concerns:gentle-clear-complexion-acne-benefits',
      });
    },
    openModal(modalName) {
      this.toggleModal(modalName);
    },
  },
};
</script>

<style lang="scss">
.gentle-clear-complexion-acne-benefits {
  background: url(~@/assets/images/jpg-png/upper-bg-home.png) no-repeat;
  background-size: 100%;
  .content {
    margin-left: 50.5px;
    margin-top: 38px;
    .back-button {
      position: absolute;
      top: 240px;
    }
    &-product-row {
      display: flex;
      align-items: center;
      margin-top: 50px;
      .image-product {
        margin-top: -80px;
        margin-left: 30px;
        img {
          width: 250px;
        }
      }
      .feature-icons {
        display: flex;
        flex-direction: column;
        margin-top: -20px;
        margin-left: 53px;
        margin-right: 47px;
        z-index: 9999;
        img {
          width: 100px;
          margin-bottom: 20px;
        }
      }
      .feature-info {
        color: $main-font-color;
        margin-top: -110px;
        position: relative;
        z-index: 9999;
        .face-body-icons {
          width: 54px;
          position: absolute;
          right: 45px;
          top: 8px;
        }
        .face-overlay {
          position: absolute;
          right: -70px;
          top: 8px;
          width: 52px;
          height: 17px;
        }
        h3 {
          margin: 0;
          margin-top: -1px;
          margin-left: -8px;
          margin-bottom: 35px;
          span {
            font-size: 10px;
            .sup-btn-description {
              margin-left: 19px;
              border: 1px solid rgb(200, 221, 234);
              border-radius: 30px;
              padding: 2px 8px 3px 8px;
            }
          }
        }
        &-skin-oil {
          margin-bottom: 35px;
          padding-bottom: 23px;
          border-bottom: 1px solid rgb(200, 221, 234);
        }
        &-hours-barrier {
          border-bottom: 1px solid rgb(200, 221, 234);
          padding-bottom: 24px;
          ul {
            margin: 0;
            padding: 0;
            li {
              font-family: 'Sofia Pro Light';
              list-style: none;
              margin-bottom: 15px;
              &:first-child {
                text-indent: -23px;
                margin-left: 22px;
              }
              img {
                display: inline-block;
                margin-right: 10px;
              }
            }
          }
        }
        &-sensitive-skin {
          margin-top: 40px;
        }
        .text-info {
          font-size: 14px;
          margin-top: 36px;
          margin-left: -8px;
        }
      }
      .feature-btn {
        display: flex;
        flex-direction: column;
        margin-top: -115px;
        margin-left: 13px;
        .see-data-2 {
          margin-top: 324px;
          margin-bottom: 42px;
          margin-left: -40px;
        }
      }
    }
    .product-nav {
      position: absolute;
      z-index: 9999;
      bottom: 120px;
      left: 100px;
      border: 1px solid $light-blue;
      box-shadow: 0px 9px 14px rgba($color: $gray-backdrop, $alpha: 0.06);
      border-radius: 8px;
      overflow: hidden;
      ul {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
        li {
          list-style: none;
          > a {
            font-size: 12.5px;
            color: $main-font-color;
            padding: 10px 12.5px;
            line-height: 1;
            display: inline-block;
            font-family: 'Sofia Pro Bold';
          }
          > a.router-link-exact-active {
            background: $light-blue-2;
          }
          &:first-child {
            border-right: 1px solid $light-blue;
          }
        }
      }
    }
  }
}
</style>
