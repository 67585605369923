<template>
  <page-layout>
    <div class="content">
      <div class="main-heading">
        <h1 class="title">DAILY MOISTURIZERS</h1>
        <p class="title-subheading">
          Cetaphil's nourishing moisturizers provide long-lasting hydration for sensitive skin.<br/><br>
        </p>
      </div>
      <div class="main-content">
        <div class="card-product">
          <div class="card-product__head">
            <div class="card-product__head-image">
              <a @click="openDetail('daily-moisturizers:moisturizing-cream-benefits')">
                <img src="@/assets/images/jpg-png/moisturizing-cream.png" width="61" height="62" alt="">
              </a>
            </div>
          </div>
          <div class="card-product__icons-links">
            <div class="container-icon-1" style="height:5px;">
              <a @click="openDetail('daily-moisturizers:moisturizing-cream-benefits')">
                  <img class="internal-icon" src="@/assets/images/jpg-png/int-link-icon.png" style="margin-left:80px; cursor:pointer;" alt="">
              </a>
            </div>
          </div>
          <div class="card-product__details">
            <span>
              <router-link :to="{ name: 'daily-moisturizers' }">
                Very Dry to Dry Skin
              </router-link>
            </span>
          </div>
        </div>
        <div class="card-product dry-normal">
          <div class="card-product__head">
            <div class="card-product__head-image">
              <router-link :to="{ 'name': 'daily-moisturizers:lotion-details' }">
                <img src="@/assets/images/jpg-png/products/daily-moisturizers/Moisturizing Lotion HBL_16oz-01 (1)@2x.png" width="66" height="127.5" alt="">
              </router-link>
            </div>
            <div class="card-product__head-image">
              <a @click="openLink('https://www.cetaphil.com/us/moisturizers/daily-facial-moisturizer-spf-15/302993928041.html')" @touchend="openLink('https://www.cetaphil.com/us/moisturizers/daily-facial-moisturizer-spf-15/302993928041.html')" target="_system">
                <img src="@/assets/images/jpg-png/products/daily-moisturizers/Daily Facial Moisturizer SPF15_4oz-01@2x.png" width="44.5" height="137.5" alt="">
              </a>
            </div>
            <div class="card-product__head-image">
              <a @click="openLink('https://www.cetaphil.com/us/moisturizers/daily-facial-moisturizer-spf-50/302993930020.html')" @touchend="openLink('https://www.cetaphil.com/us/moisturizers/daily-facial-moisturizer-spf-50/302993930020.html')" target="_system">
                <img src="@/assets/images/jpg-png/products/daily-moisturizers/Daily Facial Moisturizer SPF50_1-7oz-01@2x.png" width="44" height="132" alt="">
              </a>
            </div>
            <div class="card-product__head-image">
              <a @click="openLink('https://www.cetaphil.com/us/moisturizers/rich-hydrating-cream/302993889021.html')" @touchend="openLink('https://www.cetaphil.com/us/moisturizers/rich-hydrating-cream/302993889021.html')" target="_system">
                <img src="@/assets/images/jpg-png/products/daily-moisturizers/Rich Hydrating Cream_1-7oz-01@2x.png" width="75.5" height="39" alt="">
              </a>
            </div>
            <div class="card-product__head-image">
              <a @click="openLink('https://www.cetaphil.com/us/eye-creams/hydrating-eye-gel-cream/302993889168.html')" @touchend="openLink('https://www.cetaphil.com/us/eye-creams/hydrating-eye-gel-cream/302993889168.html')" target="_system">
                <img src="@/assets/images/jpg-png/products/daily-moisturizers/Hydrating Eye Gel Cream 0.5oz 14ml-01@2x.png" width="34.5" height="119.5" alt="">
              </a>
            </div>
          </div>
          <div class="card-product__icons-links">
            <div class="container-icon-1" style="height:5px;">
              <router-link :to="{ 'name': 'daily-moisturizers:lotion-details' }">
                <img class="internal-icon" src="@/assets/images/jpg-png/int-link-icon.png" style="margin-left:65px; cursor:pointer;" alt="">
              </router-link>
            </div>
            <div class="container-icon-1" style="height:5px;">
              <a @click="openLink('https://www.cetaphil.com/us/moisturizers/daily-facial-moisturizer-spf-15/302993928041.html')" @touchend="openLink('https://www.cetaphil.com/us/moisturizers/daily-facial-moisturizer-spf-15/302993928041.html')" target="_system">
                <img class="external-icon" src="@/assets/images/jpg-png/ext-link-icon.png" style="margin-left:42px; cursor:pointer;" alt="">
              </a>
            </div>
            <div class="container-icon-1" style="height:5px;">
              <a @click="openLink('https://www.cetaphil.com/us/moisturizers/daily-facial-moisturizer-spf-50/302993930020.html')" @touchend="openLink('https://www.cetaphil.com/us/moisturizers/daily-facial-moisturizer-spf-50/302993930020.html')" target="_system">
                <img class="external-icon" src="@/assets/images/jpg-png/ext-link-icon.png" style="margin-left:25px; cursor:pointer;" alt="">
              </a>
            </div>
            <div class="container-icon-1" style="height:5px;">
              <a @click="openLink('https://www.cetaphil.com/us/moisturizers/rich-hydrating-cream/302993889021.html')" @touchend="openLink('https://www.cetaphil.com/us/moisturizers/rich-hydrating-cream/302993889021.html')" target="_system">
                  <img class="external-icon" src="@/assets/images/jpg-png/ext-link-icon.png" style="margin-left:40px; cursor:pointer;" alt="">
              </a>
            </div>
            <div class="container-icon-1" style="height:5px;">
              <a @click="openLink('https://www.cetaphil.com/us/eye-creams/hydrating-eye-gel-cream/302993889168.html')" @touchend="openLink('https://www.cetaphil.com/us/eye-creams/hydrating-eye-gel-cream/302993889168.html')" target="_system">
                <img class="external-icon" src="@/assets/images/jpg-png/ext-link-icon.png" style="margin-left:40px; cursor:pointer;" alt="">
              </a>
            </div>
          </div>
          <div class="card-product__details">
            <span>
              <router-link :to="{ name: 'daily-moisturizers' }">
                Dry to Normal Skin
              </router-link>
            </span>
          </div>
        </div>
        <div class="card-product combination">
          <div class="card-product__head">
            <div class="card-product__head-image">
              <a @click="openDetail('daily-moisturizers:dof-facial-moisturizer-benefits')">
                <img src="@/assets/images/jpg-png/products/daily-moisturizers/Daily Facial Moisturizer SPF35_3oz-01 (1)@2x.png" width="49" height="138.5" alt="">
              </a>
            </div>
            <div class="card-product__head-image">
              <a @click="openLink('https://www.cetaphil.com/us/moisturizers/daily-oil-free-hydrating-lotion/302993889038.html')" @touchend="openLink('https://www.cetaphil.com/us/moisturizers/daily-oil-free-hydrating-lotion/302993889038.html')" target="_system">
                <img src="@/assets/images/jpg-png/products/daily-moisturizers/Daily Oil Free Hydrating Lotion_3oz-01@2x.png" width="49" height="138.5" alt="">
              </a>
            </div>
          </div>
          <div class="card-product__icons-links">
            <div class="container-icon-1" style="height:5px;">
              <a @click="openDetail('daily-moisturizers:dof-facial-moisturizer-benefits')">
                  <img class="internal-icon" src="@/assets/images/jpg-png/int-link-icon.png" style="margin-left:78px; cursor:pointer;" alt="">
              </a>
            </div>
            <div class="container-icon-1" style="height:5px;">
              <a @click="openLink('https://www.cetaphil.com/us/moisturizers/daily-oil-free-hydrating-lotion/302993889038.html')" @touchend="openLink('https://www.cetaphil.com/us/moisturizers/daily-oil-free-hydrating-lotion/302993889038.html')" target="_system">
                <img class="external-icon" src="@/assets/images/jpg-png/ext-link-icon.png" style="margin-left:55px; cursor:pointer;" alt="">
              </a>
            </div>
          </div>
          <div class="card-product__details">
            <span>
              <router-link :to="{ name: 'daily-moisturizers' }">
                Combination Skin
              </router-link>
            </span>
          </div>
        </div>
        <div class="card-product continually-dry gap">
          <div class="card-product__head">
            <div class="card-product__head-image">
              <a @click="openLink('https://www.cetaphil.com/us/moisturizers/advanced-relief-cream/302990116045.html')" @touchend="openLink('https://www.cetaphil.com/us/moisturizers/advanced-relief-cream/302990116045.html')" target="_system">
                <img src="@/assets/images/jpg-png/products/daily-moisturizers/Advanced Relief Cream_6oz-01@2x.png" width="50" height="109.5" alt="">
              </a>
            </div>
            <div class="card-product__head-image">
              <a @click="openLink('https://www.cetaphil.com/us/moisturizers/advanced-relief-lotion-with-shea-butter/302993914280.html')" @touchend="openLink('https://www.cetaphil.com/us/moisturizers/advanced-relief-lotion-with-shea-butter/302993914280.html')" target="_system">
                <img src="@/assets/images/jpg-png/products/daily-moisturizers/Advanced Relief Lotion_16oz-01@2x.png" width="66" height="127.5" alt="">
              </a>
            </div>
          </div>
          <div class="card-product__icons-links">
            <div class="container-icon-1" style="height:5px;">
              <a @click="openLink('https://www.cetaphil.com/us/moisturizers/advanced-relief-cream/302990116045.html')" @touchend="openLink('https://www.cetaphil.com/us/moisturizers/advanced-relief-cream/302990116045.html')" target="_system">
                  <img class="external-icon" src="@/assets/images/jpg-png/ext-link-icon.png" style="margin-left:40px; cursor:pointer;" alt="">
              </a>
            </div>
            <div class="container-icon-1" style="height:5px;">
              <a @click="openLink('https://www.cetaphil.com/us/moisturizers/advanced-relief-lotion-with-shea-butter/302993914280.html')" @touchend="openLink('https://www.cetaphil.com/us/moisturizers/advanced-relief-lotion-with-shea-butter/302993914280.html')" target="_system">
                  <img class="external-icon" src="@/assets/images/jpg-png/ext-link-icon.png" style="margin-left:35px; cursor:pointer;" alt="">
              </a>
            </div>
          </div>
          <div class="card-product__details">
            <span>
              <router-link :to="{ name: 'daily-moisturizers' }">
                Continually Dry
              </router-link>
            </span>
          </div>
        </div>
        <div class="card-product rough gap">
          <div class="card-product__head">
            <div class="card-product__head-image">
              <a @click="openLink('https://www.cetaphil.com/us/moisturizers/intensive-healing-lotion-with-ceramides/302993914938.html')" @touchend="openLink('https://www.cetaphil.com/us/moisturizers/intensive-healing-lotion-with-ceramides/302993914938.html')" target="_system">
                <img src="@/assets/images/jpg-png/products/daily-moisturizers/Intensive Healing Lotion_16oz-01@2x.png" width="66" height="128" alt="">
              </a>
            </div>
          </div>
          <div class="card-product__icons-links">
            <div class="container-icon-1" style="height:5px;">
              <a @click="openLink('https://www.cetaphil.com/us/moisturizers/intensive-healing-lotion-with-ceramides/302993914938.html')" @touchend="openLink('https://www.cetaphil.com/us/moisturizers/intensive-healing-lotion-with-ceramides/302993914938.html')" target="_system">
                <img class="external-icon" src="@/assets/images/jpg-png/ext-link-icon.png" style="margin-left:40px; cursor:pointer;" alt="">
              </a>
            </div>
          </div>
          <div class="card-product__details">
            <span>
              <router-link :to="{ name: 'daily-moisturizers' }">
                Rough, Flaky
              </router-link>
            </span>
          </div>
        </div>
        <div class="card-product very-rough gap">
          <div class="card-product__head">
            <div class="card-product__head-image">
              <a @click="openLink('https://www.cetaphil.com/us/moisturizers/cracked-skin-repair-balm/302994115006.html')" @touchend="openLink('https://www.cetaphil.com/us/moisturizers/cracked-skin-repair-balm/302994115006.html')" target="_system">
                <img src="@/assets/images/jpg-png/products/daily-moisturizers/Cracked Skin Repair Balm_3oz-01@2x.png" width="52" height="109" alt="">
              </a>
            </div>
            <div class="card-product__head-image">
              <a @click="openLink('https://www.cetaphil.com/us/moisturizers/cracked-skin-repair-lotion/302994114009.html')" @touchend="openLink('https://www.cetaphil.com/us/moisturizers/cracked-skin-repair-lotion/302994114009.html')" target="_system">
                <img src="@/assets/images/jpg-png/products/daily-moisturizers/Cracked Skin Repair Lotion_16oz-01@2x.png" width="66" height="127.5" alt="">
              </a>
            </div>
          </div>
          <div class="card-product__icons-links">
            <div class="container-icon-1" style="height:5px;">
              <a @click="openLink('https://www.cetaphil.com/us/moisturizers/cracked-skin-repair-balm/302994115006.html')" @touchend="openLink('https://www.cetaphil.com/us/moisturizers/cracked-skin-repair-balm/302994115006.html')" target="_system">
                  <img class="external-icon" src="@/assets/images/jpg-png/ext-link-icon.png" style="margin-left:40px; cursor:pointer;" alt="">
              </a>
            </div>
            <div class="container-icon-1" style="height:5px;">
              <a @click="openLink('https://www.cetaphil.com/us/moisturizers/cracked-skin-repair-lotion/302994114009.html')" @touchend="openLink('https://www.cetaphil.com/us/moisturizers/cracked-skin-repair-lotion/302994114009.html')" target="_system">
                  <img class="external-icon" src="@/assets/images/jpg-png/ext-link-icon.png" style="margin-left:40px; cursor:pointer;" alt="">
              </a>
            </div>
          </div>
          <div class="card-product__details">
            <span>
              <router-link :to="{ name: 'daily-moisturizers' }">
                Very Rough, Cracked
              </router-link>
            </span>
          </div>
        </div>
        <div class="card-product irritated gap">
          <div class="card-product__head">
            <div class="card-product__head-image">
              <a @click="openDetail('daily-moisturizers:soothing-gel-benefits')">
                <img src="@/assets/images/jpg-png/products/daily-moisturizers/Soothing Gel Cream 16oz Jar - FRONT@2x.png" width="59.5" height="61.5" alt="">
              </a>
            </div>
          </div>
          <div class="card-product__icons-links">
            <div class="container-icon-1" style="height:5px;">
              <a @click="openDetail('daily-moisturizers:soothing-gel-benefits')">
                  <img class="internal-icon" src="@/assets/images/jpg-png/int-link-icon.png" style="margin-left:53px; cursor:pointer;" alt="">
              </a>
            </div>
          </div>
          <div class="card-product__details">
            <span>
              <router-link :to="{ name: 'daily-moisturizers' }">
                Irritated
              </router-link>
            </span>
          </div>
        </div>
        <div class="card-product aging gap">
          <div class="card-product__head">
            <div class="card-product__head-image">
              <a @click="openLink('https://www.cetaphil.com/us/moisturizers/restoring-lotion/302993914945.html')" @touchend="openLink('https://www.cetaphil.com/us/moisturizers/restoring-lotion/302993914945.html')" target="_system">
                <img src="@/assets/images/jpg-png/products/daily-moisturizers/Restoring Lotion_16oz-01 (1)@2x.png" width="65.5" height="127" alt="">
              </a>
            </div>
          </div>
          <div class="card-product__icons-links">
            <div class="container-icon-1" style="height:5px;">
              <a a @click="openLink('https://www.cetaphil.com/us/moisturizers/restoring-lotion/302993914945.html')" @touchend="openLink('https://www.cetaphil.com/us/moisturizers/restoring-lotion/302993914945.html')" target="_system">
                  <img class="external-icon" src="@/assets/images/jpg-png/ext-link-icon.png" style="margin-left:53px; cursor:pointer;" alt="">
              </a>
            </div>
          </div>
          <div class="card-product__details">
            <span>
              <router-link :to="{ name: 'daily-moisturizers' }">
                Aging
              </router-link>
            </span>
          </div>
        </div>
      </div>
    </div>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/common/PageLayout.vue';

export default {
  components: {
    PageLayout,
  },
  methods: {
    openDetail(name) {
      return this.$router.push({name});
    },
  },
  created() {
    this.setLastCategory("daily-moisturizers")
  }
};
</script>

<style lang="scss" scoped>
.daily-moisturizers {
  background: url(~@/assets/images/jpg-png/upper-bg-home.png) no-repeat;
  background-size: 100%;
  .content {
    padding-left: 50.5px;
  }
  .main-heading {
    padding-top: 22.5px;
    margin-bottom: 54px;
    .title {
      margin-bottom: 0px;
      font-family: "Sofia Pro Bold";
      color: $dark-blue;
      font-size: 27.5px;
      line-height: 29.5px;
      letter-spacing: -0.82px;
    }
    .title-subheading {
      margin-top: 19.5px;
      margin-bottom: 0px;
      font-family: "Sofia Pro Light";
      color: $dark-blue;
      font-size: 12.5px;
      line-height: 16px;
      letter-spacing: -0.24px;
    }
  }
  .main-content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    .card-product {
      border-radius: 6.5px;
      box-shadow: 0px 4.5px 7px $box-shadow;
      border: 1px solid $light-blue;
      background-color: $white;
      display: flex;
      flex-direction: column;
      margin-right: 26px;
      &.gap {
        margin-top: 47.5px;
      }
      &__head {
        margin: -1px;
        border-radius: inherit;
        height: 101.5px;
        background-color: $lighter-blue;
        border: 1px solid $lighter-blue;
        position: relative;
        padding: 0px 57px;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        &-image {
          margin-bottom: -13.5px;
          margin-right: 22px;
          &:last-child {
            margin-right: 0px;
          }
        }
      }
      &__details {
        position: relative;
        display: flex;
        justify-content: center;
        align-content: center;
        text-align: center;
        padding: 7px 0px 14px;
        span {
          a {
            font-family: "Sofia Pro";
            font-size: 12.5px;
            color: $dark-blue;
            letter-spacing: -0.3px;
          }
        }
      }
      &__icons-links {
        display: flex;
        height: 30px;
        .container-icon-1{
          .internal-icon {
            margin-top: 14px;
            width: 17px;
          }
        }
        .container-icon-2{
          .internal-icon {
            margin-top: 14px;
            width: 17px;
          }
        }
        .external-icon {
          width: 42px;
          margin-top: 7px;
        }
      }
      &.dry-normal {
        .card-product__head {
          padding: 0px 40px;
        }
      }
      &.combination {
        .card-product__head {
          padding: 0px 21px 0px 59px;
          &-image {
            margin-bottom: -15.5px;
            margin-right: 37.5px;
          }
        }
      }
      &.continually-dry {
        z-index: 10001;
        .card-product__head {
          padding: 0px 14px 0px 34px;
          &-image {
            margin-bottom: -16.5px;
            margin-right: 18.5px;
          }
        }
        .card-product__details {
          padding-left: 7px;
        }
      }
      &.rough {
        z-index: 10001;
        .card-product__head {
          padding: 0px 7px 0px 26px;
          &-image {
            margin-bottom: -17.5px;
            margin-right: 18.5px;
          }
        }
      }
      &.very-rough {
        z-index: 10001;
        .card-product__head {
          padding: 0px 5px 0px 35px;
          &-image {
            margin-bottom: -17px;
            margin-right: 30.5px;
          }
        }
      }
      &.irritated {
        z-index: 10001;
        .card-product__head {
          padding: 0px 0px 0px 30px;
          &-image {
            margin-bottom: -17px;
            margin-right: 29.5px;
          }
        }
      }
      &.aging {
        z-index: 10001;
        .card-product__head {
          padding: 0px 0px 0px 41px;
          &-image {
            margin-bottom: -17px;
            margin-right: 41.5px;
          }
        }
      }
      
    }
  }
}
</style>
