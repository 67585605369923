<template>
  <div class="content">
    <h1 class="main-section-title">
      Clinically proven to reduce blemishes by
      <br />
      25% after just 1 week
    </h1>
    <div class="content-chart">
      <img
        src="@/assets/images/jpg-png/blemishes-reduction-comparisson.png"
        alt=""
      />
      <div class="captions">
        <span class="f-bold uppercase">Baseline</span>
        <span class="f-bold uppercase">1 Week</span>
        <span class="f-bold uppercase">4 Weeks</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss">
.gentleclearkeybenefitsface {
  .modal-layout__content {
    height: 510px;
  }
  .main-section-title {
    margin-top: 20px;
    text-align: center;
  }
  .content-chart {
    display: flex;
    align-items: center;
    flex-direction: column;
    img {
      width: 790px;
      margin-top: 25px;
    }
    .captions {
      display: flex;
      width: 659px;
      justify-content: space-around;
      padding-top: 10px;
      padding-left: 60px;
      padding-right: 60px;
      span {
        color: $gray-second-text;
      }
    }
  }
}
</style>
