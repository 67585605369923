<template>
  <div>
    <ul class="main-nav">
      <li class="home-link">
        <router-link 
          :to="{ name: 'homepage' }" 
          :class="{ 'router-link-exact-active router-link-active': isHomePageFlow }"
          exact
        >
          Home
        </router-link>
      </li>
      <li class="daily-cleansers-link">
        <router-link :to="{ name: 'daily-cleansers' }"
          >Daily <br />cleansers</router-link
        >
      </li>
      <li class="daily-moisturizers-link">
        <router-link :to="{ name: 'daily-moisturizers' }"
          >Daily <br />moisturizers</router-link
        >
      </li>
      <li class="special-benefits-link">
        <router-link :to="{ name: 'special-benefits' }"
          >Sun, Baby &amp; <br />
          Special benefits</router-link
        >
      </li>
      <li class="derma-control-link">
        <router-link
          :to="{ name: 'derma-control' }"
          :class="{ 'router-link-deactivated': sharedProduct }"
          >DERMACONTROL & RESTORADERM</router-link
        >
      </li>

      <li class="other-therapeutic-link">
        <router-link
          :class="{ 'router-link-active': sharedProduct }"
          :to="{ name: 'skin-concerns' }"
          >Therapeutic &amp; Restorative<br />
          Skin Concerns</router-link
        >
      </li>
      <li class="ctmp-link">
        <router-link 
        :to="{ name: 'ctmp' }"
          >CTMP</router-link
        >
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "MainNavigation",
  computed: {
    sharedProduct() {
      let routeName = this.$route.name;
      let lastScreen = this.$store.state.lastCategory;
      let sharedProducts = [
        "derma-control:soothing-wash-benefits",
        "derma-control:soothing-wash-features",
        "derma-control:restoraderm-eczema-benefits",
        "derma-control:restoraderm-eczema-features",
      ];
      if (lastScreen == "skin-concerns" && sharedProducts.includes(routeName)) {
        return true;
      }
      return false;
    },
    isHomePageFlow() {
      return this.$route.matched.some(route => route.name && route.name.includes('homepage:'));
    },
  },
  methods: {
    openModal(modal) {
      this.toggleModal(modal);
    },
  },
};
</script>

<style lang="scss">
@mixin nav-item-border {
  content: "";
  position: absolute;
  bottom: -73px;
  left: 0;
  width: 1px;
  height: 165%;
  background-color: rgba($color: $nav-item-border-color, $alpha: 0.3);
}
.main-nav {
  display: flex;
  padding: 0;
  list-style: none;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  margin-top: 20px;
  width: 100%;
  height: 70px;
  > li {
    flex-basis: auto;
    height: 100%;
    list-style-type: none;
    position: relative;
    display: inline-block;
    width: 100%;
    text-align: center;
    &:after {
      @include nav-item-border;
    }
    a {
      font-family: "Sofia Pro";
      text-decoration: none;
      font-size: 12px;
      line-height: 1.4;
      letter-spacing: -0.6px;
      text-transform: uppercase;
      color: $nav-main-color;
      display: inline-block;
      padding-top: 95px;
      padding-bottom: 14px;
      text-align: center;
      width: 100%;
    }
    &:first-child {
      &:after {
        display: none;
      }
    }
    > a.router-link-active:not(.router-link-deactivated) {
      color: $white;
      background-color: $main-font-color;
    }
    /* To avoid Home link to out from its box */
    &.home-link a.router-link-exact-active,
    &.home-link a {
      margin-top: 15px;
    }
    &.other-therapeutic-link a {
      padding-left: 0px;
      padding-right: 80px;
      padding-top: 79px;
    }
    &.ctmp-link {
      width: 70%;
      a {
        background: #20a7d4;
        padding-bottom: 30px;
        &.router-link-exact-active {
          background: $main-font-color;
        }
      }
    }
    &.derma-control-link {
      a {
        padding-top: 96px;
        padding-bottom: 14px;
      }
    }
    &.home-link {
      a {
        padding-bottom: 15px;
      }
    }
  }
}

.footer {
  ul {
    li {
      .router-link-active:not(.router-link-deactivated) + ul {
        display: block;
      }
    }
  }
}
</style>
