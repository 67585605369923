<template>
  <page-layout>
    <div class="content slide-swipe">
      <div class="heading">
        <h1 class="main-section-title">
          Daily oil-free facial moisturizer<br />
          spf 35
        </h1>
        <p class="description">
          Lightweight lotion hydrates and protects skin from sun damage
        </p>
      </div>
      <div class="back-button">
        <img
          src="@/assets/images/jpg-png/back-button.png"
          width="39.5"
          alt=""
          @click="goLastCategory"
          @touchend="goLastCategory"
        />
      </div>
      <div class="content-product-row">
        <div class="image-product">
          <img src="@/assets/images/jpg-png/dailyoffm-img-product.png" alt="" />
        </div>
        <div class="feature-icons">
          <img src="@/assets/images/jpg-png/sensitive-skin-icon.png" alt="" />
          <img src="@/assets/images/jpg-png/skin-oil-icon.png" alt="" />
          <img src="@/assets/images/jpg-png/hours-barrier-icon.png" alt="" />
        </div>
        <div class="feature-info">
          <h3 class="f-bold">For combination, sensitive skin</h3>
          <div class="feature-info-skin-oil">
            <p class="f-light">
              Broad spectrum SPF 35 formulated with<br />
              Avobenzone 2.8%, Hemosalate 9.5%, <br />
              Octisalate 4.8%, and Octocrylene 7%
            </p>
          </div>
          <div class="feature-info-hours-barrier">
            <p class="f-light">
              Nourishes skin with edelweiss flower, vitamin E,<br />
              niacinamide (vitamin B3) and panthenol (vitamin <br />
              B5) to strengthen the skin barrier and prevent <br />
              surface free radical damage
            </p>
          </div>
          <div class="feature-info-sensitive-skin">
            <p class="f-light">
              Provides instant and long-lasting 24-hour <br />
              hydration
            </p>
          </div>
        </div>
      </div>
      <div class="product-nav">
        <ul>
          <li>
            <router-link
              :to="{
                name: 'daily-moisturizers:dof-facial-moisturizer-benefits',
              }"
              >Key Benefits</router-link
            >
          </li>
          <li>
            <router-link
              :to="{
                name: 'daily-moisturizers:dof-facial-moisturizer-features',
              }"
              >Key Features</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/common/PageLayout.vue';

export default {
  components: {
    PageLayout,
  },
  mounted() {
    this.$route.params.onSwipeLeft = this.onSwipeLeft;
    this.$route.params.onSwipeRight = this.onSwipeRight;
  },
  methods: {
    onSwipeLeft() {
      return this.$router.push({
        name: 'daily-moisturizers:dof-facial-moisturizer-features',
      });
    },
    onSwipeRight() {
      return this.$router.push({
        name: 'daily-moisturizers:dof-facial-moisturizer-benefits',
      });
    },
  },
};
</script>

<style lang="scss">
.dof-facial-moisturizer-benefits {
  background: url(~@/assets/images/jpg-png/upper-bg-home.png) no-repeat;
  background-size: 100%;
  .content {
    margin-left: 50.5px;
    margin-top: 38px;
    .back-button {
      position: absolute;
      top: 139px;
    }
    &-product-row {
      display: flex;
      align-items: center;
      .image-product {
        margin-top: 2px;
        margin-left: 13px;
        img {
          width: 309px;
        }
      }
      .feature-icons {
        display: flex;
        flex-direction: column;
        margin-top: 85px;
        margin-left: 38px;
        margin-right: 47px;
        img {
          width: 100px;
          margin-bottom: 23px;
        }
      }
      .feature-info {
        position: relative;
        width: 326px;
        height: 360px;
        color: $main-font-color;
        h3 {
          position: absolute;
          width: 100%;
          top: -28px;
          left: -7px;
        }
        &-skin-oil {
          position: absolute;
          border-bottom: 1px solid rgb(200, 221, 234);
          letter-spacing: -0.5px;
          width: 100%;
          top: 34px;
          left: -6px;
          padding-bottom: 8px;
        }
        &-hours-barrier {
          position: absolute;
          border-bottom: 1px solid rgb(200, 221, 234);
          width: 100%;
          bottom: 61px;
          top: 160px;
          left: -7px;
          line-height: 18px;
          letter-spacing: -0.6px;
          padding-bottom: 30px;
          p {
            margin-bottom: 20px;
          }
          ul {
            margin: 0;
            padding: 0;
            li {
              font-family: 'Sofia Pro Light';
              list-style: none;
              margin-bottom: 5px;
              &:first-child {
                text-indent: -15px;
                margin-left: 13px;
                margin-bottom: 20px;
              }
              img {
                display: inline-block;
                margin-right: 3px;
              }
            }
          }
        }
        &-sensitive-skin {
          position: absolute;
          width: 100%;
          bottom: -2px;
          top: 295px;
          left: -7px;
          letter-spacing: -0.4px;
        }
      }
    }
    .product-nav {
      position: absolute;
      z-index: 9999;
      bottom: 127px;
      left: 119px;
      border: 1px solid $light-blue;
      box-shadow: 0px 9px 14px rgba($color: $gray-backdrop, $alpha: 0.06);
      border-radius: 8px;
      overflow: hidden;
      ul {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
        li {
          list-style: none;
          > a {
            font-size: 12.5px;
            color: $main-font-color;
            padding: 10px 12.5px;
            line-height: 1;
            display: inline-block;
            font-family: 'Sofia Pro Bold';
          }
          > a.router-link-exact-active {
            background: $light-blue-2;
          }
          &:first-child {
            border-right: 1px solid $light-blue;
          }
        }
      }
    }
  }
}
</style>
