<template>
  <div>
    <div class="secondary-nav">
      <nav>
        <ul>
          <li class="what-news" @click="openPDF()">Highlights</li>
          <li class="camp-wonder" @click="openModal('CampWonder')">Camp Wonder</li>
          <li class="references" @click="openModal('References')">References</li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import { sendTracking, openPDF } from '@/mixins/tracking.js'

export default {
  name: 'SecondaryNavigation',
  methods: {
    openModal(modalName) {
      this.toggleModal(modalName);
    },
    openPDF() {
      sendTracking(1116);
      const pdfFile = require("@/assets/pdf/20195-dry-skin-leave-behindr6.pdf");
      openPDF(pdfFile);
    },
  }
};
</script>

<style lang="scss">
.secondary-nav {
  background: #fff;
  position: absolute;
  right: 130px;
  top: 10px;
  border-radius: 8px;
  border: 1px solid $light-blue;
  width: 269px;
  box-shadow: 0px 9px 14px rgba($color: $gray-backdrop, $alpha: 0.06);
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    a {
      cursor: pointer;
      color: $main-font-color;
    }
    li {
      cursor: pointer;
      display: inline-block;
      color: $main-font-color;
      font-size: 10px;
      padding: 9px 16px;
      &.camp-wonder {
        border-right: 1px solid $light-blue;
        border-left: 1px solid $light-blue;
      }
    }
  }
}
</style>
