<template>
  <div class="content">
    <h1 class="main-section-title">
      Clinically proven to remove up to 97% of <br />
      pollution particles from the skin surface
    </h1>
    <div class="content-main">
      <div class="content-main-graphs">
        <div class="row row-1">
          <p class="f-medium">Before</p>
          <img
            src="@/assets/images/jpg-png/before-rinsing-treated.jpg"
            alt=""
          />

          <img
            src="@/assets/images/jpg-png/before-rinsing-not-treated.jpg"
            alt=""
          />
        </div>
        <div class="row row-2">
          <p class="f-medium">After</p>
          <img src="@/assets/images/jpg-png/after-deep-cleansing.jpg" alt="" />
          <img
            src="@/assets/images/jpg-png/after-deep-cleansing-not-treated.jpg"
            alt=""
          />
        </div>
      </div>
      <div class="content-main-figures">
        <div class="row row-1">
          <p class="figure main-section-title">-97%</p>
          <p class="description-parag">
            Pollution particle reduction with <br />Hydrating Foaming Cream
            Cleanser
          </p>
        </div>
        <div class="row row-2">
          <p class="figure main-section-title">-23%</p>
          <p class="description-parag">
            Pollution particle reduction <br />
            with water only
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.dailyfacialcleansermodal {
  .content {
    margin-top: 30px;
    margin-left: 40px;
    &-main {
      &-graphs {
        display: flex;
        margin-top: 30px;
        .row {
          display: flex;
          flex-direction: column;
          margin-right: 20px;
          margin-bottom: 20px;
          p {
            color: $gray-second-text;
            margin-bottom: 10px;
            text-align: center;
          }
          img {
            width: auto;
            height: 180px;
            margin-bottom: 20px;
          }
        }
      }
      &-figures {
        position: absolute;
        top: 140px;
        right: 17px;
        width: 270px;
        .figure {
          font-size: 64px;
          margin-bottom: 0;
        }
        .description-parag {
          margin-top: 0;
        }
        .row-2 {
          margin-top: 100px;
        }
      }
    }
  }
}
</style>
