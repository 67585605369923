<template>
  <div class="content">
    <h1 class="f-bold">
      SIGNIFICANTLY IMPROVES SENSITIVE SKIN SYMPTOMS LIKE BURNING OR STINGING FOR UP TO 24 HOURS
    </h1>
    <img src="@/assets/images/svg/itch-relief-gel-see-data-modal.svg" alt="" />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.itchreliefgelseedatamodal {
  .modal-layout__content {
    padding: 25px 38px;
    h1 {
      font-size: 26px;
      margin: 0;
      letter-spacing: 0.2px;
      text-transform: uppercase;
      line-height: 30px;
      margin-top: 4px;
    }
    img {
      width: 520px;
      display: block;
      margin: 0 auto;
      margin-top: 70px;
      margin-bottom: 0;
    }
  }
}
</style>
