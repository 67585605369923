<template>
  <div class="content">
    <h1 class="f-bold">
      Hydration Results 30 Minutes Post-Application of Gentle Skin Cleanser
    </h1>
    <div class="image-headers">
      <div class="image-headers-container">
        <div class="image-headers-first-col">
          Baseline
        </div>
        <div class="image-headers-second-col">
          30 Minutes
        </div>
      </div>
    </div>
    <img src="@/assets/images/jpg-png/gsc-headmap-hydratation.png" alt="" />
    <ul class="list-items">
      <li>
        Heatmaps were generated based on hydration results per participant.
      </li>
      <li>
        Measurements at 30 minutes were performed post-product application.
      </li>
    </ul>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.gscmodalhydrationstudy {
  .modal-layout__content {
    padding: 25px 38px;
    .image-headers {
      width: 75%;
      margin-left: 90px;
      margin-top: 15px;
      &-container {
        display: flex;
      }
      &-first-col {
        width: 50%;
        text-align: center;
        margin-right: 60px;
      }
      &-second-col {
        width: 50%;
        text-align: center;
      }
    }
    h1 {
      font-size: 26px;
      margin: 0;
      letter-spacing: 0.2px;
      text-transform: uppercase;
      line-height: 30px;
      margin-top: 4px;
    }
    h2 {
      text-align: center;
      font-size: 18px;
      letter-spacing: -0.95px;
      margin: 56px 0 27px -18px;
      color: $gray-second-text;
    }
    img {
      width: 591px;
      display: block;
      margin: 40px auto;
      margin-top: 0;
      margin-bottom: 0;
    }
    ul {
      margin: 0;
      padding: 0;
      img {
        width: 140px;
        margin-top: 12px;
      }
      li {
        margin: 0;
        padding: 0;
        list-style: none;
        position: relative;
        margin-left: 8px;
        &::before {
          content: '*';
          width: 10px;
          height: 10px;
          position: absolute;
          left: -10px;
        }
      }
    }
  }
}
</style>
