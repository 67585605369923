<template>
  <page-layout>
    <div class="content">
      <div class="main-heading">
        <h1>Sun, Baby & Special Benefits</h1>
      </div>
      <div class="secondary-heading">
        <h4 class="text-short-description">
          Cetaphil's Special Skin Benefits offer patients added skin benefits to
          help them manage <br />
          their sensitive skin
        </h4>
      </div>

      <div class="content-products" style="margin-top:35px">
        <!--Card 1-->
        <div class="products-card-one-upper" style="display:flex">
          <!--Product-1-->
          <div class="container-img-product">
            <a
              @click="
                openDetail('special-benefits:sheer-mineral-sun-screen-face')
              "
            >
              <img
                class="img-product"
                src="@/assets/images/jpg-png/smflsf50-img.png"
                height="119"
                style="margin-top:-13px; margin-left:-25px"
                alt=""
              />
              <img
                class="internal-icon"
                src="@/assets/images/jpg-png/int-link-icon.png"
                style="margin-bottom:-23px; margin-left:-32px; cursor:pointer;"
                alt=""
              />
            </a>
          </div>
          <!--Product-2-->
          <div class="container-img-product">
            <a @click="openDetail('special-benefits:sheer-mineral-stick')">
              <img
                class="img-product"
                src="@/assets/images/jpg-png/smsssp50-img.png"
                width="53"
                style="margin-top:-3px; margin-left:-20px"
                alt=""
              />
              <img
                class="internal-icon"
                src="@/assets/images/jpg-png/int-link-icon.png"
                style="width:19px; margin-bottom:-25px; margin-left:-35px; cursor:pointer;"
                alt=""
              />
            </a>
          </div>
          <!--Product-3-->
          <div class="container-img-product">
            <a
              @click="
                openLink(
                  'https://www.cetaphil.com/us/sunscreens/sheer-mineral-sunscreen-broad-spectrum-spf-30/302994111008.html'
                )
              "
              @touchend="
                openLink(
                  'https://www.cetaphil.com/us/sunscreens/sheer-mineral-sunscreen-broad-spectrum-spf-30/302994111008.html'
                )
              "
              target="_system"
            >
              <img
                class="img-product"
                src="@/assets/images/jpg-png/csf30ltn-img.png"
                width="44"
                style="margin-top:-15px;margin-left:-10px;"
                alt=""
              />
              <br>
              <img
                class="external-icon"
                src="@/assets/images/jpg-png/ext-link-icon.png"
                style="margin-top:-4px;margin-left:-5px;cursor:pointer;"
                alt=""
              />
            </a>
          </div>
          <!--Product-4-->
          <div class="container-img-product">
            <a
              @click="
                openLink(
                  'https://www.cetaphil.com/us/sunscreens/sheer-mineral-sunscreen-broad-spectrum-spf-50/302994112005.html'
                )
              "
              @touchend="
                openLink(
                  'https://www.cetaphil.com/us/sunscreens/sheer-mineral-sunscreen-broad-spectrum-spf-50/302994112005.html'
                )
              "
              target="_system"
            >
              <img
                class="img-product"
                src="@/assets/images/jpg-png/spf50-lotion.png"
                width="44"
                style="margin-top:-15px;margin-left:-10px;"
                alt=""
              />
              <br />
              <img
                class="external-icon"
                src="@/assets/images/jpg-png/ext-link-icon.png"
                style="margin-top:-5px;margin-left:-6px;cursor:pointer;"
                alt=""
              />
            </a>
          </div>
        </div>
        <div class="products-card-one-main" style="display:flex">
          <a href="#" class="archor-card-1"
            >Mineral Sunscreen for Sensitive Skin</a
          >
        </div>

        <!--Card 2-->
        <div class="products-card-two-upper" style="display:flex">
          <!--Product-1-->
          <div class="container-img-product">
            <a
              @click="
                openLink(
                  'https://www.cetaphil.com/us/moisturizers/facial-moisturizers-serums/deep-hydration-skin-restoring-water-gel/302990117073.html'
                )
              "
              @touchend="
                openLink(
                  'https://www.cetaphil.com/us/moisturizers/facial-moisturizers-serums/deep-hydration-skin-restoring-water-gel/302990117073.html'
                )
              "
              target="_system"
            >
              <img
                class="img-product"
                src="@/assets/images/jpg-png/water-gel.png"
                width="88"
                style="margin-top:35px; margin-left:-25px"
                alt=""
              />
              <br>
              <img
                class="external-icon"
                src="@/assets/images/jpg-png/ext-link-icon.png"
                style="margin-top:-5px; margin-left:2px; cursor:pointer;"
                alt=""
              />
            </a>
          </div>
          <!--Product-2-->
          <div class="container-img-product">
            <a
              @click="
                openLink(
                  'https://www.cetaphil.com/us/moisturizers/deep-hydration-48-hour-activation-serum/302990117004.html'
                )
              "
              @touchend="
                openLink(
                  'https://www.cetaphil.com/us/moisturizers/deep-hydration-48-hour-activation-serum/302990117004.html'
                )
              "
              target="_system"
            >
              <img
                class="img-product"
                src="@/assets/images/jpg-png/dhs48-img.png"
                width="37"
                style="margin-top:-30px; margin-left:-8px"
                alt=""
              />
              <br>
              <img
                class="external-icon"
                src="@/assets/images/jpg-png/ext-link-icon.png"
                style="margin-top:-5px; margin-left:-6px; cursor:pointer;"
                alt=""
              />
            </a>
          </div>
          <!--Product-3-->
          <div class="container-img-product">
            <a
              @click="
                openLink(
                  'https://www.cetaphil.com/us/moisturizers/deep-hydration-healthy-glow-daily-cream/302990117011.html'
                )
              "
              @touchend="
                openLink(
                  'https://www.cetaphil.com/us/moisturizers/deep-hydration-healthy-glow-daily-cream/302990117011.html'
                )
              "
              target="_system"
            >
              <img
                class="img-product"
                src="@/assets/images/jpg-png/dhhgdc-img.png"
                width="88"
                style="margin-top:39px; margin-left:-15px"
                alt=""
              />
              <img
                class="external-icon"
                src="@/assets/images/jpg-png/ext-link-icon.png"
                style="margin-top:-5px; margin-left:12px; cursor:pointer;"
                alt=""
              />
            </a>
          </div>
          <!--Product-4-->
          <div class="container-img-product">
            <a
              @click="
                openLink(
                  'https://www.cetaphil.com/us/eye-creams/deep-hydration-refreshing-eye-serum/302990100624.html'
                )
              "
              @touchend="
                openLink(
                  'https://www.cetaphil.com/us/eye-creams/deep-hydration-refreshing-eye-serum/302990100624.html'
                )
              "
              target="_system"
            >
              <img
                class="img-product"
                src="@/assets/images/jpg-png/dhres-img.png"
                width="36"
                style="margin-top:-28px; margin-left:-2px"
                alt=""
              />
              <img
                class="external-icon"
                src="@/assets/images/jpg-png/ext-link-icon.png"
                style="margin-top:-5px; margin-left:-1px; cursor:pointer;"
                alt=""
              />
            </a>
          </div>
        </div>
        <div class="products-card-two-main" style="display:flex">
          <a href="#" class="archor-card-2">Dry or Dehydrated Skin</a>
        </div>

        <!--Card 3-->
        <div class="products-card-three-upper" style="display:flex">
           <!--Product-1-->
          <div class="container-img-product">
            <a
              @click="
                openLink(
                  'https://www.cetaphil.com/us/products/skin-concern/anti-aging/healthy-renew-face-serum/302990260045.html'
                )
              "
              @touchend="
                openLink(
                  'https://www.cetaphil.com/us/products/skin-concern/anti-aging/healthy-renew-face-serum/302990260045.html'
                )
              "
              target="_system"
            >
              <img
                class="img-product"
                src="@/assets/images/jpg-png/face-serum-peptides.png"
                width="38"
                style="margin-top:-30px; margin-left:-20px"
                alt=""
              />
              <br>
              <img
                class="external-icon"
                src="@/assets/images/jpg-png/ext-link-icon.png"
                style="margin-top:-6px; margin-left:-20px; cursor:pointer;"
                alt=""
              />
            </a>
          </div>
          <!--Product-2-->
          <div class="container-img-product">
            <a
              @click="
                openLink(
                  'https://www.cetaphil.com/us/products/skin-concern/anti-aging/healthy-renew-eye-gel-serum/302990260021.html'
                )
              "
              @touchend="
                openLink(
                  'https://www.cetaphil.com/us/products/skin-concern/anti-aging/healthy-renew-eye-gel-serum/302990260021.html'
                )
              "
              target="_system"
            >
              <img
                class="img-product"
                src="@/assets/images/jpg-png/eye-gel-serum.png"
                width="38"
                style="margin-top:-22px; margin-left:-25px"
                alt=""
              />
              <img
                class="external-icon"
                src="@/assets/images/jpg-png/ext-link-icon.png"
                style="margin-top:-6px; margin-left:-36px; margin-bottom:-36px; cursor:pointer;"
                alt=""
              />
            </a>
          </div>
          <!--Product-3-->
          <div class="container-img-product">
            <a
              @click="
                openLink(
                  'https://www.cetaphil.com/us/products/skin-concern/anti-aging/healthy-renew-day-cream-spf-30/302994136001.html'
                )
              "
              @touchend="
                openLink(
                  'https://www.cetaphil.com/us/products/skin-concern/anti-aging/healthy-renew-day-cream-spf-30/302994136001.html'
                )
              "
              target="_system"
            >
              <img
                class="img-product"
                src="@/assets/images/jpg-png/day-cream-broad.png"
                width="30"
                style="margin-top:-8px; margin-left:-30px"
                alt=""
              />
              <br>
              <img
                class="external-icon"
                src="@/assets/images/jpg-png/ext-link-icon.png"
                style="margin-top:-4px; margin-left:-33px; cursor:pointer;"
                alt=""
              />
            </a>
          </div>
          <!--Product-4-->
          <div class="container-img-product">
            <a
              @click="
                openLink('https://www.cetaphil.com/us/products/skin-concern/anti-aging/healthy-renew-night-cream/302990260014.html')
              "
              @touchend="
                openLink('https://www.cetaphil.com/us/products/skin-concern/anti-aging/healthy-renew-night-cream/302990260014.html')
              "
              target="_system"
            >
              <img
                class="img-product"
                src="@/assets/images/jpg-png/night-cream.png"
                width="90"
                style="margin-top:35px; margin-left:-48px"
                alt=""
              />
              <br>
              <img
                class="external-icon"
                src="@/assets/images/jpg-png/ext-link-icon.png"
                style="margin-top:-4px; margin-left:-20px; cursor:pointer;"
                alt=""
              />
            </a>
          </div>
        </div>
        <div class="products-card-three-main" style="display:flex">
          <a href="#" class="archor-card-3">Healthy Renew</a>
        </div>

        <!--Card 4-->
        <div class="products-card-four-upper" style="display:flex">
          <!--Product-1-->
          <div class="container-img-product">
            <a
              @click="
                openLink(
                  'https://www.cetaphil.com/us/moisturizers/healthy-radiance-antioxidant-c-serum/302990116038.html'
                )
              "
              @touchend="
                openLink(
                  'https://www.cetaphil.com/us/moisturizers/healthy-radiance-antioxidant-c-serum/302990116038.html'
                )
              "
              target="_system"
            >
              <img
                class="img-product"
                src="@/assets/images/jpg-png/hraoxis-img.png"
                width="36"
                height="133"
                style="margin-top:-28px; margin-left:10px"
                alt=""
              />
              <img
                class="external-icon"
                src="@/assets/images/jpg-png/ext-link-icon.png"
                style="margin-top:-5px; margin-left:9px; cursor:pointer;"
                alt=""
              />
            </a>
          </div>
          <!--Product-2-->
          <div class="container-img-product">
            <a
              @click="
                openLink('https://www.cetaphil.com/us/moisturizers/healthy-radiance-whipped-day-cream-spf-30/302994118007.html')
              "
              @touchend="
                openLink('https://www.cetaphil.com/us/moisturizers/healthy-radiance-whipped-day-cream-spf-30/302994118007.html')
              "
              target="_system"
            >
              <img
                class="img-product"
                src="@/assets/images/jpg-png/hrwdc-img.png"
                width="90"
                style="margin-top:32px; margin-left:-15px"
                alt=""
              />
              <img
                class="external-icon"
                src="@/assets/images/jpg-png/ext-link-icon.png"
                style="margin-top:-4px; margin-left:10px; cursor:pointer;"
                alt=""
              />
            </a>
          </div>
          <!--Product-3-->
          <div class="container-img-product">
            <a
              @click="
                openLink(
                  'https://www.cetaphil.com/us/moisturizers/healthy-radiance-renewing-cream/302990116014.html'
                )
              "
              @touchend="
                openLink(
                  'https://www.cetaphil.com/us/moisturizers/healthy-radiance-renewing-cream/302990116014.html'
                )
              "
              target="_system"
            >
              <img
                class="img-product"
                src="@/assets/images/jpg-png/radiance-renewing-cream.png"
                width="88"
                style="margin-top:35px; margin-left:5px"
                alt=""
              />
              <img
                class="external-icon"
                src="@/assets/images/jpg-png/ext-link-icon.png"
                style="margin-top:-6px; margin-left:32px; cursor:pointer;"
                alt=""
              />
            </a>
          </div>
          <!--Product-4-->
          <div class="container-img-product">
            <a
              @click="
                openLink(
                  'https://www.cetaphil.com/us/cleansers/healthy-radiance-gentle-pha-exfoliating-cleanser/302990116021.html'
                )
              "
              @touchend="
                openLink(
                  'https://www.cetaphil.com/us/cleansers/healthy-radiance-gentle-pha-exfoliating-cleanser/302990116021.html'
                )
              "
              target="_system"
            >
              <img
                class="img-product"
                src="@/assets/images/jpg-png/hrgphaec-img.png"
                width="43"
                style="margin-top:-8px; margin-left:-2px"
                alt=""
              />
              <img
                class="external-icon"
                src="@/assets/images/jpg-png/ext-link-icon.png"
                style="margin-top:-6px; margin-left:-39px; margin-bottom:-36px; cursor:pointer;"
                alt=""
              />
            </a>
          </div>
        </div>
        <div class="products-card-four-main" style="display:flex">
          <a href="#" class="archor-card-4">Dull or Uneven Skin</a>
        </div>

        <!--Card 5-->
        <div class="products-card-five-upper" style="display:flex">
          <!--Product-1-->
          <div class="container-img-product">
            <a
              @click="
                openLink(
                  'https://www.cetaphil.com/us/baby-skincare/baby-daily-lotion/302993936039.html'
                )
              "
              @touchend="
                openLink(
                  'https://www.cetaphil.com/us/baby-skincare/baby-daily-lotion/302993936039.html'
                )
              "
              target="_system"
            >
              <img 
                class="img-product"
                src="@/assets/images/jpg-png/products/baby-skincare/Baby Daily Lotion_13-5oz-01@2x.png" 
                width="62" 
                style="margin-top:-45px; margin-left:-25px"
                alt=""
              >
              <img
                class="external-icon"
                src="@/assets/images/jpg-png/ext-link-icon.png"
                style="margin-top:-4px; margin-left:-12px; cursor:pointer;"
                alt=""
              />
            </a>
          </div>
          <!--Product-2-->
          <div class="container-img-product">
            <a
              @click="
                openLink('https://www.cetaphil.com/us/baby-skincare/baby-eczema-soothing-lotion/302994140053.html')
              "
              @touchend="
                openLink('https://www.cetaphil.com/us/baby-skincare/baby-eczema-soothing-lotion/302994140053.html')
              "
              target="_system"
            >
              <img 
                class="img-product"
                src="@/assets/images/jpg-png/products/baby-skincare/Baby Eczema Soothing Lotion_10oz-01@2x.png" 
                width="52.5" 
                style="margin-top:-45px;margin-left:-14px;"
                alt=""
              >
              <img
                class="external-icon"
                src="@/assets/images/jpg-png/ext-link-icon.png"
                style="margin-top:-4px; margin-left:-6px; cursor:pointer;"
                alt=""
              />
            </a>
          </div>
          <!--Product-3-->
          <div class="container-img-product">
            <a
              @click="
                openLink(
                  'https://www.cetaphil.com/us/baby-skincare/baby-gentle-wash/302993936091.html'
                )
              "
              @touchend="
                openLink(
                  'https://www.cetaphil.com/us/baby-skincare/baby-gentle-wash/302993936091.html'
                )
              "
              target="_system"
            >
              <img
                class="img-product"
                src="@/assets/images/jpg-png/products/baby-skincare/Baby Gentle Wash_7-8oz-01@2x.png" 
                width="65.5"
                style="margin-top:-50px;margin-left:-14px;"
                alt=""
              >
              <img
                class="external-icon"
                src="@/assets/images/jpg-png/ext-link-icon.png"
                style="margin-top:-6px; margin-left:2px; cursor:pointer;"
                alt=""
              />
            </a>
          </div>
          <!--Product-4-->
          <div class="container-img-product">
            <a
              @click="
                openLink(
                  'https://www.cetaphil.com/us/baby-skincare/baby-soothing-wash/302990200058.html'
                )
              "
              @touchend="
                openLink(
                  'https://www.cetaphil.com/us/baby-skincare/baby-soothing-wash/302990200058.html'
                )
              "
              target="_system"
            >
              <img
                class="img-product"
                src="@/assets/images/jpg-png/products/baby-skincare/Baby Soothing Wash_10oz-01@2x.png" 
                width="53" 
                style="margin-top:-45px;margin-left:-12px;"
                alt=""
              >
              <br>
              <img
                class="external-icon"
                src="@/assets/images/jpg-png/ext-link-icon.png"
                style="margin-top:-6px; margin-left:-4px; margin-bottom:-15px; cursor:pointer;"
                alt=""
              />
            </a>
          </div>
           <!--Product-5-->
          <div class="container-img-product">
            <a
              @click="
                openLink(
                  'https://www.cetaphil.com/us/baby-skincare/baby-wash-and-shampoo/302993936077.html'
                )
              " 
              @touchend="
                openLink(
                  'https://www.cetaphil.com/us/baby-skincare/baby-wash-and-shampoo/302993936077.html'
                )
              "
              target="_system"
            >
              <img 
                class="img-product"
                src="@/assets/images/jpg-png/products/baby-skincare/Baby Wash & Shampoo_13-5oz-01@2x.png" 
                width="62" 
                height="151" 
                style="margin-top:-43px; margin-left:-12px;"
                alt=""
              >
              <img
                class="external-icon"
                src="@/assets/images/jpg-png/ext-link-icon.png"
                style="margin-top:-6px; margin-left:4px; margin-bottom:-15px; cursor:pointer;"
                alt=""
              />
            </a>
          </div>
          <!--Product-6-->
          <div class="container-img-product">
            <a
              @click="
                openLink(
                  'https://www.cetaphil.com/us/baby-skincare/baby-soothe-and-protect-cream/302994103058.html'
                )
              " 
              @touchend="
                openLink(
                  'https://www.cetaphil.com/us/baby-skincare/baby-soothe-and-protect-cream/302994103058.html'
                )
              "
              target="_system"
            >
              <img
                class="img-product"
                src="@/assets/images/jpg-png/products/baby-skincare/Baby Sooth and Protect Cream_6oz-01@2x.png"
                width="49.5" 
                height="109" 
                style="margin-top:-2px; margin-left:-16px;"
                alt=""
              >
              <img
                class="external-icon"
                src="@/assets/images/jpg-png/ext-link-icon.png"
                style="margin-top:-6px; margin-left:-9px; margin-bottom:-15px; cursor:pointer;"
                alt=""
              />
            </a>
          </div> 
        </div>
        <div class="products-card-five-main" style="display:flex">
          <a href="#" class="archor-card-5">Baby's Sensitive Skin</a>
        </div>
      </div>
    </div>
  </page-layout>
</template>

<script>
import PageLayout from "@/components/common/PageLayout.vue";

export default {
  components: {
    PageLayout,
  },
  created() {
    this.setLastCategory("special-benefits");
  },
  methods: {
    openDetail(name) {
      return this.$router.push({ name });
    },
  },
};
</script>

<style lang="scss" scoped>
.special-benefits {
  background: url(~@/assets/images/jpg-png/upper-bg-home.png) no-repeat;
  background-size: 100%;
  .content {
    .main-heading {
      margin-left: 50px;
      margin-top: 45px;
      position: relative;
      h1 {
        font-size: 28px;
        font-family: "Sofia Pro bold";
        text-transform: uppercase;
        color: #004987;
        line-height: 0.95;
        letter-spacing: -0.6px;
        sup {
          font-size: 12px;
          vertical-align: top;
          margin-top: 5px;
          margin-left: 2px;
          display: inline-block;
        }
      }
      h2 {
        font-size: 23px;
        color: $white;
        text-transform: uppercase;
        line-height: 0.95;
        width: 200px;
        position: absolute;
        right: 89px;
        top: 43px;
        span {
          display: inline-block;
          margin-left: 28px;
          line-height: 1.1;
          letter-spacing: 1.5px;
        }
      }
    }
    .secondary-heading {
      .text-short-description {
        font-family: "Sofia Pro Light";
        font-size: 14px;
        font-weight: 300;
        margin-left: 50px;
        color: #004987;
        line-height: 1.5;
      }
    }
    .content-products {
      width: 900px;
      margin-top: 10px;
      position: relative;
      z-index: 9999;
      .products-card-one-upper,
      .products-card-two-upper,
      .products-card-three-upper,
      .products-card-four-upper,
      .products-card-five-upper {
        background: #e6f5fb;
        border: 1px solid #e6f5fb;
        border-radius: 8px;
        opacity: 1;
        position: absolute;
        left: 15px;
        height: 98px;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
        z-index: 1;
        .container-img-product {
          width: 124px;
          margin-left: 25px;
          img.img-product {
            margin-top: -25px;
          }
          .external-icon {
            width: 38px;
          }
          .internal-icon {
            width: 18px;
          }
        }
      }
      .products-card-one-upper {
        margin-top: 16px;
        width: 240px;
        left: 48px;
        .container-img-product {
          width: 55px;
        }
      }
      .products-card-two-upper {
        margin-top: 16px;
        margin-left: 330px;
        width: 268px;
        .container-img-product {
          width: 55px;
        }
      }
      .products-card-three-upper {
        margin-left: 655px;
        margin-top: 16px;
        width: 278px;
        .container-img-product {
          width: 55px;
        }
      }
      .products-card-four-upper {
        margin-left: 60px;
        margin-top: 239px;
        width: 396px;
      }
      .products-card-five-upper {
        margin-left: 511px;
        margin-top: 239px;
        width: 396px;
      }
      .products-card-one-main,
      .products-card-two-main,
      .products-card-three-main,
      .products-card-four-main,
      .products-card-five-main {
        display: flex;
        background: #fff;
        border: 1px solid #c8ddea;
        border-radius: 8px;
        opacity: 1;
        position: absolute;
        left: 15px;
        height: 125px;
        padding: 20px 10px 20px 10px;
        box-shadow: 0px 9px 14px #0324720d;
        .archor-card-1,
        .archor-card-2,
        .archor-card-3,
        .archor-card-4,
        .archor-card-5 {
          font-size: 13px;
          font-weight: 400;
          color: #004987;
        }
      }
      .products-card-one-main {
        width: 240px;
        margin-top: 20px;
        margin-left: 32px;
        .archor-card-1 {
          margin-top: 125px;
          margin-left: 15px;
        }
      }
      .products-card-two-main {
        margin-top: 20px;
        margin-left: 330px;
        width: 268px;
        .archor-card-2 {
          margin-top: 125px;
          margin-left: 66px;
        }
      }
      .products-card-three-main {
        margin-top: 20px;
        margin-left: 655px;
        width: 278px;
        .archor-card-3 {
          margin-top: 125px;
          margin-left: 94px;
        }
      }
      .products-card-four-main {
        left: 74px;
        margin-top: 238px;
        width: 398px;
        .archor-card-4 {
          margin-top: 125px;
          margin-left: 135px;
        }
      }
      .products-card-five-main {
        margin-left: 510px;
        margin-top: 238px;
        width: 398px;
        .archor-card-5 {
          margin-top: 125px;
          margin-left: 137px;
        }
      }
    }
  }
}
</style>
