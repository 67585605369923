<template>
  <page-layout>
    <div class="content slide-swipe">
      <div class="heading">
        <h1 class="main-section-title">
          Daily oil-free facial moisturizer<br />
          spf 35
        </h1>
        <p class="description">
          Designed for combination, sensitive skin
        </p>
      </div>
      <div class="back-button">
        <img
          src="@/assets/images/jpg-png/back-button.png"
          width="39.5"
          alt=""
          @click="goLastCategory"
          @touchend="goLastCategory"
        />
      </div>
      <div class="content-product-row">
        <div class="image-product">
          <img src="@/assets/images/jpg-png/dailyoffm-img-product.png" alt="" />
        </div>
        <div class="feature-info">
          <h3 class="f-bold">Key Features</h3>
          <div class="key-features-info">
            <ul>
              <li>
                <img
                  src="@/assets/images/jpg-png/polygon-mark.png"
                  width="8"
                  alt=""
                />Fragrance free, paraben free, oil free
              </li>
              <li>
                <img
                  src="@/assets/images/jpg-png/polygon-mark.png"
                  width="8"
                  alt=""
                />Hypoallergenic, Non Comedogenic
              </li>
              <li>
                <img
                  src="@/assets/images/jpg-png/polygon-mark.png"
                  width="8"
                  alt=""
                />Hydrates for 24 hours
              </li>
            </ul>
          </div>
          <div class="ingredients-info">
            <h3 class="f-bold">Ingredients</h3>
            <p>
              Avobenzone 2.8%, Homosalate 9.5%, Octisalate 4.8%, <br />
              Octocrylene 7% <br />
              <span class="f-bold">Inactive Ingredients:</span> Water, Glycerin,
              Dipropylene Glycol,<br />
              Panthenol (Vitamin B5), Ethylhexyl Methoxycrylene, Isopropyl<br />
              Palmitate, Silica, Niacinamide, Cetearyl Olivate, Cetearyl<br />
              Alcohol, Glyceryl Stearate, Potassium Cetyl Phosphate, <br />
              Sorbitan Olivate, Tocopheryl Acetate (Vitamin E Acetate), <br />
              Caprylyl Glycol, Acrylates/C10-30 Alkyl Acrylate <br />
              Crosspolymer, Palmitic Acid, Ethylhexylglycerin, <br />
              Leontopodium Alpinum (Edelweiss) Extract, Stearic Acid, 1,2<br />
              -Hexanediol, Oryza Sativa (Rice) Lees Extract, Propanediol, <br />
              Sodium Hydroxide, Adenosine, Potassium Sorbate, Sodium <br />
              Benzoate, Citric Acid, Myristic Acid, Buddleja Davidii<br />
              (Summer Lilac) Leaf Extract, Thymus Vulgaris (Thyme) Leaf
              <br />Extract, Tocopherol (Vitamin E)
            </p>
          </div>
        </div>
      </div>
      <div class="product-nav">
        <ul>
          <li>
            <router-link
              :to="{
                name: 'daily-moisturizers:dof-facial-moisturizer-benefits',
              }"
              >Key Benefits</router-link
            >
          </li>
          <li>
            <router-link
              :to="{
                name: 'daily-moisturizers:dof-facial-moisturizer-features',
              }"
              >Key Features</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/common/PageLayout.vue';

export default {
  components: {
    PageLayout,
  },
  mounted() {
    this.$route.params.onSwipeLeft = this.onSwipeLeft;
    this.$route.params.onSwipeRight = this.onSwipeRight;
  },
  methods: {
    onSwipeLeft() {
      return this.$router.push({
        name: 'daily-moisturizers:dof-facial-moisturizer-features',
      });
    },
    onSwipeRight() {
      return this.$router.push({
        name: 'daily-moisturizers:dof-facial-moisturizer-benefits',
      });
    },
  },
};
</script>

<style lang="scss">
.dof-facial-moisturizer-features {
  background: url(~@/assets/images/jpg-png/upper-bg-home.png) no-repeat;
  background-size: 100%;
  .content {
    margin-left: 50.5px;
    margin-top: 38px;
    .back-button {
      position: absolute;
      top: 139px;
    }
    &-product-row {
      display: flex;
      align-items: center;
      .image-product {
        margin-top: 18px;
        margin-right: 109px;
        margin-left: 13px;
        img {
          width: 309px;
        }
      }
      .feature-info {
        margin-top: -8px;
        color: $main-font-color;

        h3 {
          margin: 0;
          margin-bottom: 10px;
        }
        .key-features-info {
          border-bottom: 1px solid rgb(200, 221, 234);
          padding-bottom: 23px;
          padding-top: 8px;
          letter-spacing: -0.5px;
          ul {
            margin: 0;
            padding: 0;
            li {
              font-family: 'Sofia Pro Light';
              list-style: none;
              img {
                display: inline-block;
                margin-right: 10px;
              }
            }
          }
        }
        .ingredients-info {
          padding-top: 10px;
          p {
            font-family: 'Sofia Pro Light';
            letter-spacing: -0.5px;
            line-height: 17px;
          }
        }
      }
    }
    .product-nav {
      position: absolute;
      z-index: 9999;
      bottom: 127px;
      left: 119px;
      border: 1px solid $light-blue;
      box-shadow: 0px 9px 14px rgba($color: $gray-backdrop, $alpha: 0.06);
      border-radius: 8px;
      overflow: hidden;
      ul {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
        li {
          list-style: none;
          > a {
            font-size: 12.5px;
            color: $main-font-color;
            padding: 10px 12.5px;
            line-height: 1;
            display: inline-block;
            font-family: 'Sofia Pro Bold';
          }
          > a.router-link-exact-active {
            background: $light-blue-2;
          }
          &:first-child {
            border-right: 1px solid $light-blue;
          }
        }
      }
    }
  }
}
</style>
