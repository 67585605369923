<template>
  <div class="content">
    <div class="content-video" style="display:flex; justify-content:center; border-radius:30px">
        <video width="650" height="520" controls src="@/assets/video/video-camp-wonder.mp4" type="video/mp4"></video>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.videocampwondermodal > .modal-layout__content {
  border: 1px solid #fff;
  border-radius: 13px;
}
.video-campwonder-modal {
  .modal-layout {
    border: 1px solid #fff;
    &__backdrop {
      background-color: #00325760 0% 0% no-repeat padding-box;
      opacity: 1;
    }
    &__content {
      width: 885.5px;
      height: 760px;
      margin-top: 12px;
      border-radius: 20px;
      .content {
        display: flex;
        position: relative;
        &-video {
            width: 100%;
        }
      }
    }
  }
}
</style>
