<template>
  <div class="content">
    <div class="section-1 section" :class="{'active': activeContent == 'content-one'}">
      <gsc-modal-tab-1></gsc-modal-tab-1>
    </div>
    <div class="section-2 section" :class="{'active': activeContent == 'content-two'}">
      <gsc-modal-tab-2></gsc-modal-tab-2>
    </div>
    <div class="secondary-nav-modal">
        <ul>
          <li>
            <a @click="showContent('content-one')" :class="{'active': activeContent == 'content-one'}">Wiped-off</a>
          </li>
          <li>
            <a @click="showContent('content-two')" :class="{'active': activeContent == 'content-two'}">Rinsed-off</a>
          </li>
        </ul>
    </div>
  </div>
</template>

<script>
import GscModalTab1 from '@/components/common/GscModalTab1.vue';
import GscModalTab2 from '@/components/common/GscModalTab2.vue';
import { sendTracking } from '@/mixins/tracking.js'

export default {
    components: {
        GscModalTab1,
        GscModalTab2
    },
    data() {
      return {
        activeContent: 'content-one'
      }
    },
    methods: {
      showContent: function(content){
        this.activeContent = content
        if (content === 'content-one') {
          sendTracking(771)
        }else{
          sendTracking(772)
        }
      }
    }
};
</script>

<style lang="scss">
.gscmodal2 > .modal-layout__content {
    background: url(~@/assets/images/jpg-png/upper-bg-home.png) no-repeat;
    border: 1px solid #fff;
    border-radius: 8px;
    .content {
        margin: 18px;
        .section {
          display: none;
          &.active {
            display: block;
          }
        }
        h1 {
            font-size: 24px;
            margin-left: 10px;
        }
        .secondary-nav-modal {
            position: absolute;
            z-index: 9999;
            bottom: 80px;
            left: 350px;
            border: 1px solid $light-blue;
            box-shadow: 0px 9px 14px rgba($color: $gray-backdrop, $alpha: 0.06);
            border-radius: 8px;
            overflow: hidden;
            ul {
                display: flex;
                align-items: center;
                margin: 0;
                padding: 0;
                li {
                    list-style: none;
                        > a {
                            font-size: 12.5px;
                            color: $main-font-color;
                            padding: 10px 12.5px;
                            line-height: 1;
                            display: inline-block;
                            font-family: 'Sofia Pro Bold';
                    }
                    a.active {
                      background: $light-blue-2;
                    }
                }
            }
        }
    }

}
.gsc-modal2 {
  .modal-layout__close {
    top: 18px;
  }
  .modal-layout__content {
    width: 931px;
    height: 642px;
    top: 17px;
    left: 2px;
  }
  font-family: 'Sofia Pro';
  color: $main-font-color;
}
</style>