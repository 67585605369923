import Vue from 'vue'
import Vuex from 'vuex'
import { sendTracking } from '@/mixins/tracking.js'
import router from './router'
import LINKS_MAPPING from './linksMapping'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    modal: {
      component: undefined,
      isVisible: false
    },
    mainNav: {
      isVisible: false
    },
    lastCategory: "full-portfolio"
  },
  mutations: {
    toggleModal (state, payload) {
      if (payload) {
        if (payload === 'WhatNews') {
          sendTracking(1116)
        }
        if (payload === 'CampWonder') {
          sendTracking(1117)
        }
        if (payload === 'VideoCampWonderModal') {
          sendTracking(1118)
        }
        if (payload === 'References') {
          sendTracking(1119)
        }
        if (payload === 'GscModal2') {
          sendTracking(771)
        }
        if (payload === 'GscModal3') {
          sendTracking(773)
        }
        if (payload === 'MoisturizingCreamRestore') {
          sendTracking(837)
        }
        if (payload === 'MoisturizingCreamModalOne') {
          sendTracking(838)
        }
        if (payload === 'MoisturizingLotionModalTwo') {
          sendTracking(841)
        }
        if (payload === 'MoisturizingLotionModalThree') {
          sendTracking(842)
        }
        if (payload === 'HealingOintmentModalOne') {
          sendTracking(889)
        }
        if (payload === 'RoughBumpyModalOne') {
          sendTracking(892)
        }
        if (payload === 'DailyFacialCleanserModal') {
          sendTracking(1020)
        }
        if (payload === 'GscModalHydrationStudy') {
          sendTracking(1023)
        }
        if (payload === 'MoisturizingLotionModalHydrationStudy') {
          sendTracking(1024)
        }
        if (payload === 'GentleClearKeyBenefitsFace') {
          sendTracking(1027)
        }
        if (payload === 'DfcFfSeeDataModal') {
          sendTracking(1028)
        }
        if (payload === 'SoothingMoisturizingLotionModal') {
          sendTracking(1031)
        }
        if (payload === 'SoothingMoisturizingLotionHandToHandModal') {
          sendTracking(1032)
        }
        if (payload === 'ItchReliefGelSeeDataModal') {
          sendTracking(1034)
        }
        if (payload === 'ItchReliefGelSeeMoreDataModal') {
          sendTracking(1035)
        }
        if (payload === 'SkinBarrierHydrationModal') {
          sendTracking(1038)
        }
        if (payload === 'RestoradermFlareupSeeMoreDataModal') {
          sendTracking(1039)
        }
        if (payload === 'PatientRoutineModalOne') {
          sendTracking(1104)
        }
        if (payload === 'PatientRoutinePhotoModal1') {
          sendTracking(1105)
        }
        if (payload === 'PatientRoutineModalTwo') {
          sendTracking(1106)
        }
        if (payload === 'PatientRoutinePhotoModal2') {
          sendTracking(1107)
        }
        if (payload === 'PatientRoutineModalThree') {
          sendTracking(1108)
        }
        if (payload === 'PatientRoutinePhotoModal3') {
          sendTracking(1109)
        }
        
        state.modal.component = payload
        state.modal.isVisible = true
      } else {
        state.modal.isVisible = !state.modal.isVisible
        state.modal.component = undefined
      }
    },
    toggleMainNav (state, payload) {
      if (payload) {
        state.mainNav.isVisible = true
      } else {
        state.mainNav.isVisible = !state.mainNav.isVisible
      }
    },
    openLink (state, link) {
      sendTracking(LINKS_MAPPING[link])
      window.open(link, "_system");
    },
    setLastCategory(state, componentName) {
      state.lastCategory = componentName
    }
  },
  actions: {
    goLastCategory({ state }){
      router.push({ name: state.lastCategory })
    }
  }
})
