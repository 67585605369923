<template>
  <page-layout>
    <div class="content slide-swipe">
      <div class="heading">
        <h1 class="main-section-title">
          Hydrating foaming <br />
          cream cleanser
        </h1>
        <p class="description">
          Designed for dry to normal, sensitive skin
        </p>
      </div>
      <div class="back-button">
        <img
          src="@/assets/images/jpg-png/back-button.png"
          width="39.5"
          alt=""
          @click="goLastCategory"
          @touchend="goLastCategory"
        />
      </div>
      <div class="content-product-row">
        <div class="image-product">
          <img
            src="@/assets/images/jpg-png/hydrating-foaming-detail-image.png"
            alt=""
          />
        </div>
        <div class="feature-info">
          <h3 class="f-bold">Key Features</h3>
          <div class="key-features-info">
            <ul>
              <li>
                <img
                  src="@/assets/images/jpg-png/polygon-mark.png"
                  width="8"
                  alt=""
                /><span>Dermatologist tested </span>
              </li>
              <li>
                <img
                  src="@/assets/images/jpg-png/polygon-mark.png"
                  width="8"
                  alt=""
                />Leaves skin feeling hydrated after cleansing with
                <br />&nbsp;&nbsp;&nbsp;&nbsp;soothing prebiotic aloe
              </li>
              <li>
                <img
                  src="@/assets/images/jpg-png/polygon-mark.png"
                  width="8"
                  alt=""
                />Formulated with dermatologist-backed blend of glycerin,<br />
                &nbsp;&nbsp;&nbsp;&nbsp;niacinamide and panthenol
              </li>
              <li>
                <img
                  src="@/assets/images/jpg-png/polygon-mark.png"
                  width="8"
                  alt=""
                />Oil-free, soap free, paraben free
              </li>
              <li>
                <img
                  src="@/assets/images/jpg-png/polygon-mark.png"
                  width="8"
                  alt=""
                />Non-comedogenic, hypoallergenic
              </li>
            </ul>
          </div>
          <div class="ingredients-info">
            <h3 class="f-bold">Ingredients</h3>
            <p>
              Water, Sodium Cocoyl isethionate,
              <span class="f-bold">Glycerin</span>, Glycol Distearate,<br />
              Sodium Cocoyl Glutamate, Cocamidopropyl Betaine, <br />
              Sodium Lauroyl Sarcosinate, Cetearyl alcohol, Phenoxyethanol,<br />
              Acrylates/c10-30 alkyl Acrylate Crosspolymer, Arginine,
              <br />Propylene Glycol, Sodium Chloride, Xanthan Gum,<br />
              Ethylhexylglycerin, <span class="f-bold">Niacinamide</span>,
              <span class="f-bold">Panthenol</span>, Aloe Barbadensis <br />Leaf
              Juice powder, Pantolactone, Citric acid
            </p>
          </div>
        </div>
      </div>
      <div class="product-nav">
        <ul>
          <li>
            <router-link :to="{ name: 'daily-cleansers:hfc-cleanser-benefits' }"
              >Key Benefits</router-link
            >
          </li>
          <li>
            <router-link :to="{ name: 'daily-cleansers:hfc-cleanser-features' }"
              >Key Features</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/common/PageLayout.vue';

export default {
  components: {
    PageLayout,
  },
  mounted() {
    this.$route.params.onSwipeLeft = this.onSwipeLeft;
    this.$route.params.onSwipeRight = this.onSwipeRight;
  },
  methods: {
    onSwipeLeft() {
      return this.$router.push({
        name: 'daily-cleansers:hfc-cleanser-features',
      });
    },
    onSwipeRight() {
      return this.$router.push({
        name: 'daily-cleansers:hfc-cleanser-benefits',
      });
    },
  },
};
</script>

<style lang="scss">
.hfc-cleanser-features {
  background: url(~@/assets/images/jpg-png/upper-bg-home.png) no-repeat;
  background-size: 100%;
  .content {
    margin-left: 50.5px;
    margin-top: 40px;
    .main-section-title {
      margin-top: -2px;
    }
    .back-button {
      position: absolute;
      top: 165px;
    }
    &-product-row {
      display: flex;
      align-items: center;
      .image-product {
        margin-top: 55px;
        margin-right: 121px;
        margin-left: 3px;
        img {
          width: 309px;
        }
      }
      .feature-info {
        margin-top: 18px;
        color: $main-font-color;

        h3 {
          margin: 0;
          margin-top: 15px;
          margin-bottom: 10px;
        }
        .key-features-info {
          border-bottom: 1px solid rgb(200, 221, 234);
          padding-bottom: 30px;
          padding-top: 0px;
          ul {
            margin: 0;
            padding: 0;
            li {
              font-family: 'Sofia Pro Light';
              list-style: none;
              img {
                display: inline-block;
                margin-right: 10px;
              }
              &:first-child {
                text-indent: -18px;
                margin-left: 18px;
              }
            }
          }
        }
        .ingredients-info {
          padding-top: 5px;
          p {
            font-family: 'Sofia Pro Light';
            padding-bottom: 5px;
          }
        }
      }
    }
    .product-nav {
      position: absolute;
      z-index: 9999;
      bottom: 120px;
      left: 100px;
      border: 1px solid $light-blue;
      box-shadow: 0px 9px 14px rgba($color: $gray-backdrop, $alpha: 0.06);
      border-radius: 8px;
      overflow: hidden;
      ul {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
        li {
          list-style: none;
          > a {
            font-size: 12.5px;
            color: $main-font-color;
            padding: 10px 12.5px;
            line-height: 1;
            display: inline-block;
            font-family: 'Sofia Pro Bold';
          }
          > a.router-link-exact-active {
            background: $light-blue-2;
          }
          &:first-child {
            border-right: 1px solid $light-blue;
          }
        }
      }
    }
  }
}
</style>
