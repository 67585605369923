<template>
  <page-layout>
    <div class="content slide-swipe">
      <div class="heading">
        <h1 class="main-section-title">Healing ointment</h1>
        <p class="description">
          Helps heal and soothes extremely dry skin, chapped lips, cracked hands and
          heels <br />
          and other minor skin irritations
        </p>
      </div>
      <div class="back-button">
        <img
          src="@/assets/images/jpg-png/back-button.png"
          width="39.5"
          alt=""
          @click="goLastCategory"
          @touchend="goLastCategory"
        />
      </div>
      <div class="content-product-row">
        <div class="image-product">
          <img
            src="@/assets/images/jpg-png/healing-ointment-detail.png"
            alt=""
          />
        </div>
        <div class="feature-icons">
          <img src="@/assets/images/jpg-png/pretrolatum-71-5.png" alt="" />
          <img src="@/assets/images/jpg-png/skin-texture-89.png" alt="" />
          <img src="@/assets/images/jpg-png/hydrates-48-hours.png" alt="" />
        </div>
        <div class="feature-info">
          <h3 class="f-bold">For dry, chapped or irritated, sensitive skin</h3>
          <div class="feature-info-skin-oil">
            <p class="f-light">
              Formulated with 71.5% petrolatum to quickly <br />
              protect and help heal dry, irritated skin
            </p>
          </div>
          <div class="feature-info-hours-barrier">
            <p class="f-light">
              89% reported noticeable improvement in their <br />
              skin's texture after 1 week of use.
            </p>
            <img @click="openModal('HealingOintmentModalOne')" @touchend="openModal('HealingOintmentModalOne')"
              src="@/assets/images/jpg-png/see-data-btn.png"
              width="47.5"
              alt=""
            />
          </div>
          <div class="feature-info-sensitive-skin">
            <p class="f-light">
              Hydrates the skin's natural barrier for 48 hours, <br />
              while restoring and healing extremely dry, <br />
              chapped skin.
            </p>
          </div>
        </div>
      </div>
      <div class="product-nav">
        <ul>
          <li>
            <router-link :to="{ name: 'skin-concerns:healing-ointment-benefits' }"
              >Key Benefits</router-link
            >
          </li>
          <li>
            <router-link :to="{ name: 'skin-concerns:healing-ointment-features' }"
              >Key Features</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/common/PageLayout.vue';

export default {
  components: {
    PageLayout,
  },
  mounted(){
    this.$route.params.onSwipeLeft = this.onSwipeLeft;
    this.$route.params.onSwipeRight = this.onSwipeRight;
  },
  methods: {
    onSwipeLeft() {
      return this.$router.push({name: 'skin-concerns:healing-ointment-features'});
    },
    onSwipeRight() {
      return this.$router.push({name: 'skin-concerns:healing-ointment-benefits'});
    },
    openModal(modalName) {
      this.toggleModal(modalName);
    }
  }

};
</script>

<style lang="scss">
.healing-ointment-benefits {
  background: url(~@/assets/images/jpg-png/upper-bg-home.png) no-repeat;
  background-size: 100%;
  .content {
    margin-left: 50.5px;
    margin-top: 38px;
    .heading {
      .main-sectiont-title {
        margin-bottom: 25px;
      }
    }
    .back-button {
      position: absolute;
      top: 139px;
    }
    &-product-row {
      display: flex;
      align-items: center;
      .image-product {
        margin-top: 17px;
        margin-left: 13px;
        img {
          width: 309px;
        }
      }
      .feature-icons {
        display: flex;
        flex-direction: column;
        margin-top: 93px;
        margin-left: 38px;
        margin-right: 47px;
        img {
          width: 100px;
          margin-bottom: 30px;
        }
      }
      .feature-info {
        position: relative;
        width: 375px;
        height: 360px;
        color: $main-font-color;
        h3 {
          position: absolute;
          width: 100%;
          top: -27px;
          left: -6px;
        }
        &-skin-oil {
          position: absolute;
          border-bottom: 1px solid rgb(200, 221, 234);
          letter-spacing: -0.5px;
          width: 85%;
          top: 46px;
          left: -6px;
          padding-bottom: 24px;
        }
        &-hours-barrier {
          position: absolute;
          border-bottom: 1px solid rgb(200, 221, 234);
          width: 89%;
          bottom: 88px;
          left: -7px;
          letter-spacing: -0.5px;
          padding-bottom: 30px;
          p {
            margin-bottom: 20px;
          }
          img {
            position: absolute;
            right: -76px;
            width: 70px;
            top: 0px;
          }
        }
        &-sensitive-skin {
          position: absolute;
          width: 100%;
          bottom: -15px;
          left: -6px;
          letter-spacing: -0.5px;
        }
      }
    }
    .product-nav {
      position: absolute;
      z-index: 9999;
      bottom: 127px;
      left: 119px;
      border: 1px solid $light-blue;
      box-shadow: 0px 9px 14px rgba($color: $gray-backdrop, $alpha: 0.06);
      border-radius: 8px;
      overflow: hidden;
      ul {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
        li {
          list-style: none;
          > a {
            font-size: 12.5px;
            color: $main-font-color;
            padding: 10px 12.5px;
            line-height: 1;
            display: inline-block;
            font-family: 'Sofia Pro Bold';
          }
          > a.router-link-exact-active {
            background: $light-blue-2;
          }
          &:first-child {
            border-right: 1px solid $light-blue;
          }
        }
      }
    }
  }
}
</style>
