<template>
  <div class="content">
    <h1 class="f-bold uppercase">
      RELIEVE. REPAIR. PROTECT.<br />WITH THE NEW RESTORADERM <br />REGIMEN FROM CETAPHIL
    </h1>
    <div class="modal-info">
      <div class="content__products">
        <!-- First Product -->
        <div class="container first-product">
          <div class="image-container">
            <img
              class="img-1"
              @click="openDetail('derma-control:itch-relief-gel-benefits')"
              src="@/assets/images/jpg-png/restoraderm-itch-relief-gel.png"
            />
          </div>
          <div class="main-content">
            <div class="main-content__icon">
              <img class="icon-1" src="@/assets/images/svg/number-1.svg" />
            </div>
            <div class="main-content__text">
              <h3 class="colum-1-heading">
                RESTORADERM<br /> ITCH RELIEF GEL
              </h3>
              <p>
                Itch Reduction in as little as 30 seconds with unique cool touch applicator
              </p>
            </div>
          </div>
        </div>

        <!-- Second product -->
        <div class="container second-product">
          <div class="image-container">
            <img
              class="img-1"
              @click="openDetail('derma-control:restoraderm-flareup-benefits')"
              src="@/assets/images/jpg-png/restoraderm-flare-up-relief-cream.png"
            />
          </div>
          <div class="main-content">
            <div class="main-content__icon">
              <img
                class="icon-1"
                src="@/assets/images/svg/number-2.svg"
              />
            </div>
            <div class="main-content__text">
              <h3 class="colum-1-heading">
                RESTORADERM<br /> FLARE-UP RELIEF CREAM
              </h3>
              <p>
                Complete barrier repair in 5 <br />DAYS with daily use
              </p>
            </div>
          </div>
        </div>

        <!-- Third product -->
        <div class="container third-product">
          <div class="image-container">
            <img
              class="img-1"
              @click="
                openDetail(
                  'derma-control:restoraderm-eczema-benefits'
                )
              "
              src="@/assets/images/jpg-png/restoraderm-soothing-moisturizing-lotion.png"
            />
          </div>
          <div class="main-content">
            <div class="main-content__icon">
              <img
                class="icon-1"
                src="@/assets/images/svg/number-3.svg"
              />
            </div>
            <div class="main-content__text">
              <h3 class="colum-1-heading">
                RESTORADERM SOOTHING MOISTURIZING LOTION
              </h3>
              <p>
                NEW formula hydrates skin for up to 48 hours
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    openDetail(name) {
      this.toggleModal();
      return this.$router.push({ name });
    },
  },
};
</script>

<style lang="scss">
.whatnews > .modal-layout__content {
  width: 930px;
  background: url(~@/assets/images/jpg-png/bg-modal-cetaphil2.png) no-repeat;
  background-size: cover;
  border-radius: 8px;
  .modal-layout__close {
    background-image: url('~@/assets/images/svg/close-circle-outline.svg');
  }
  .content {
    margin: 18px;
    h1 {
      color: $white;
      font-size: 30px;
      line-height: 30px;
      text-align: center;
      margin: 0;
      padding-top: 20px;
      /* position: absolute;
      left: 40px;
      width: 100%;
      top: 15px; */
    }
    .modal-info {
      width: 100%;
      padding-top: 40px;

      .content__products {
        display: flex;
        align-items: flex-start;
        justify-content: center;

        .container {
          display: flex;
          position: relative;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          width: 31.3%;
          .image-container {
            height: 250px;
            margin-bottom: 30px;
            img {
              width: auto;
              height: 250px;
            }
          }
          .main-content {
            display: flex;
            align-items: flex-start;
            &__icon {
              margin-right: 10px;
              img {
                width: 50px;
              }
            }
            &__text {
              color: white;
              h3 {
                margin: 0;
                font-size: 14px;
              }
              p {
                font-size: 14px;
                margin: 0;
                margin-top: 10px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
