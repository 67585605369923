<template>
  <page-layout>
    <div class="content slide-swipe">
      <div class="heading">
        <h1 class="main-section-title">ROUGH &amp; BUMPY</h1>
        <p class="description">
          Clinically proven to smooth, soothe and reduce appearance of redness
          or discoloration in rough and bumpy skin
        </p>
      </div>
      <div class="back-button">
        <img
          src="@/assets/images/jpg-png/back-button.png"
          width="39.5"
          alt=""
          @click="goLastCategory"
          @touchend="goLastCategory"
        />
      </div>
      <div class="content-product-row">
        <div class="image-product">
          <img
            src="@/assets/images/jpg-png/products/skin-concern/details/rough-bumpy-detail.png"
            alt=""
          />
          <div class="product-nav">
            <ul>
              <li>
                <router-link
                  :to="{ name: 'skin-concerns:rough-bumpy-benefits' }"
                  >Key Benefits</router-link
                >
              </li>
              <li>
                <router-link
                  :to="{ name: 'skin-concerns:rough-bumpy-features' }"
                  >Key Features</router-link
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="feature-content">
          <h3 class="f-bold">
            For rough and bumpy, sensitive skin
          </h3>
          <div class="feature-content-inner">
            <!-- ROW 1 -->
            <div class="row">
              <img
                class="icon"
                src="@/assets/images/jpg-png/icons/icon-1.png"
                alt=""
              />
              <p class="f-light">
                Formulated with 20% urea to boost skin's moisture while gently
                exfoliating to remove built up keratin and dead skin cells.
              </p>
            </div>
            <!-- ROW 2 -->
            <div class="row">
              <img
                class="icon"
                src="@/assets/images/jpg-png/icons/icon-2.png"
                alt=""
              />
              <p class="f-light">
                93% reported skin felt significantly less rough after 1 month.
              </p>
              <a>
                <img
                  @click="openModal('RoughBumpyModalOne')"
                  class="see-data-1"
                  src="@/assets/images/jpg-png/see-data-img.png"
                  width="70"
                  alt=""
              /></a>
            </div>
            <!-- ROW 3 -->
            <div class="row">
              <img
                class="icon"
                src="@/assets/images/jpg-png/icons/icon-3.png"
                alt=""
              />
              <p class="f-light">
                Free from harsh acidic exfoliants like lactic acid and AHAs, so
                it is non-sensitizing and can be used every day
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </page-layout>
</template>

<script>
import PageLayout from "@/components/common/PageLayout.vue";

export default {
  components: {
    PageLayout,
  },
  mounted() {
    this.$route.params.onSwipeLeft = this.onSwipeLeft;
    this.$route.params.onSwipeRight = this.onSwipeRight;
  },
  methods: {
    onSwipeLeft() {
      return this.$router.push({ name: "skin-concerns:rough-bumpy-features" });
    },
    onSwipeRight() {
      return this.$router.push({ name: "skin-concerns:rough-bumpy-benefits" });
    },
    openModal(modalName) {
      this.toggleModal(modalName);
    },
  },
};
</script>

<style lang="scss">
.rough-bumpy-benefits {
  background: url(~@/assets/images/jpg-png/upper-bg-home.png) no-repeat;
  background-size: 100%;
  .content {
    .heading {
      position: absolute;
      top: 41px;
      left: 50px;
      h1 {
        margin-top: 0;
        margin-bottom: 22px;
        letter-spacing: -0.8px;
      }
    }
    .back-button {
      position: absolute;
      top: 151px;
      left: 50px;
    }
    .description {
      max-width: 450px;
    }
    &-product-row {
      display: flex;
      align-items: flex-start;
      position: relative;
      top: 176px;
      margin-left: 63px;
      h3 {
        margin: 0 0 6px 144px;
        color: #004987;
        letter-spacing: 0.15px;
      }
      .row {
        display: flex;
        align-items: center;
        color: $dark-blue;
        .icon {
          width: 108px;
          margin-right: 36px;
        }
        p {
          margin: 0;
          position: relative;
          width: 330px;
          letter-spacing: -0.45px;
          margin-right: 10px;
          line-height: 18px;
        }
        &:nth-child(2) {
          p {
            &::before,
            &::after {
              content: "";
              position: absolute;
              left: 0;
              width: 97%;
              background-color: $light-blue-2;
              height: 1px;
            }
            &::before {
              top: -54px;
            }
            &::after {
              bottom: -47px;
            }
          }
        }
      }
      .image-product {
        margin-top: 15px;
        width: 309px;
        margin-right: 34px;
        img {
          width: 100%;
        }
      }
      .feature-content {
        width: 553px;
        &-inner {
          height: 365px;
          display: flex;
          flex-flow: column;
          justify-content: space-between;
        }
      }
    }
    .product-nav {
      position: relative;
      z-index: 9999;
      border: 1px solid $light-blue;
      box-shadow: 0px 9px 14px rgba($color: $gray-backdrop, $alpha: 0.06);
      border-radius: 8px;
      overflow: hidden;
      width: 194px;
      margin: 20px auto;
      ul {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
        li {
          list-style: none;
          > a {
            font-size: 12px;
            color: $main-font-color;
            padding: 10px 13px;
            line-height: 1;
            display: inline-block;
            font-family: "Sofia Pro Bold";
          }
          > a.router-link-exact-active {
            background: $light-blue-2;
            font-family: "Sofia Pro Bold";
          }
          &:first-child {
            border-right: 1px solid $light-blue;
          }
        }
      }
    }
  }
}
</style>
