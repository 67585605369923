<template>
  <page-layout>
    <div class="container slide-swipe">
      <div class="heading">
        <h1 class="main-section-title">
          RESTORADERM<br>FLARE-UP RELIEF CREAM
        </h1>
        <p class="description">
          This rich cream is clinically proven to start repairing the skin barrier in 24 hours with<br />complete
          barrier repair in 5 days with daily use.
        </p>
      </div>
      <div class="back-button">
        <img src="@/assets/images/jpg-png/back-button.png" width="39.5" alt="" @click="goLastCategory"
          @touchend="goLastCategory" />
      </div>
      <div class="container-product-row">
        <div class="image-product">
          <img src="@/assets/images/jpg-png/restoraderm-flare-up-relief-cream-detail.png" alt="" />
        </div>
        <div class="feature-icons">
          <img src="@/assets/images/jpg-png/hours-barrier-icon.png" alt="" />
          <img src="@/assets/images/jpg-png/healthy-looking.png" alt="" />
          <img src="@/assets/images/jpg-png/itchy.png" alt="" />
        </div>
        <div class="feature-info">
          <h3 class="f-bold">
            For eczema symptoms, sensitive skin
            <img class="face-icon" src="@/assets/images/jpg-png/body-icon.png" alt="" />
          </h3>
          <div class="feature-info-skin-oil">
            <p class="f-bold">SYMPTOM RELIEF WITH JUST ONE USE</p>
            <p class="f-light">
              Clinically proven to start repairing the skin's barrier in 24 hours and relieve eczema symptoms after 1
              use
            </p>
          </div>
          <div class="feature-info-hours-barrier">
            <p class="f-bold">COMPLETE SKIN BARRIER REPAIR</p>
            <ul class="f-light">
              <li class="doble-line">
                <img src="@/assets/images/jpg-png/polygon-mark.png" width="8" alt="" />
                Skin barrier recovery in half the time versus untreated skin
              </li>
              <li class="doble-line">
                <img src="@/assets/images/jpg-png/polygon-mark.png" width="8" alt="" />
                Complete barrier repair/recovery in 5 days with daily use
              </li>
            </ul>
          </div>
          <div class="feature-info-sensitive-skin">
            <p class="f-bold">LONG TERM RELIEF FOR YOUR PATIENTS</p>
            <ul class="f-light">
              <li class="doble-line">
                <img src="@/assets/images/jpg-png/polygon-mark.png" width="8" alt="" />
                93% said product helped relieve skin redness and irritation due to eczema
              </li>
              <li class="doble-line">
                <img src="@/assets/images/jpg-png/polygon-mark.png" width="8" alt="" />
                92% said dry, itchy, eczema-prone skin felt soothed and protected overnight
              </li>
            </ul>
          </div>
        </div>
        <div class="feature-btn">
          <img class="see-data-2" src="@/assets/images/jpg-png/see-data-img.png" width="70" alt="" @click="openModal('SkinBarrierHydrationModal')" />
        </div>
      </div>
      <div class="product-nav">
        <ul>
          <li>
            <router-link :to="{ name: 'derma-control:restoraderm-flareup-benefits' }">Key Benefits</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'derma-control:restoraderm-flareup-features' }">Key Features</router-link>
          </li>
        </ul>
      </div>
      <div class="more-data">
        <div class="btn-special">
          <img
            src="@/assets/images/svg/see-more-data-button.svg"
            alt="hidratation study button"
            @click="openModal('RestoradermFlareupSeeMoreDataModal')"
            @touchend="openModal('RestoradermFlareupSeeMoreDataModal')"
          />
        </div>
      </div>
    </div>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/common/PageLayout.vue';
export default {
  components: {
    PageLayout,
  },
  mounted() {
    this.$route.params.onSwipeLeft = this.onSwipeLeft;
    this.$route.params.onSwipeRight = this.onSwipeRight;
  },
  methods: {
    onSwipeLeft() {
      return this.$router.push({
        name: 'derma-control:restoraderm-flareup-features',
      });
    },
    onSwipeRight() {
      return this.$router.push({
        name: 'derma-control:restoraderm-flareup-benefits',
      });
    },
    openModal(modalName) {
      this.toggleModal(modalName);
    }
  },
};
</script>

<style lang="scss">
.restoraderm-flareup-benefits {
  background: url(~@/assets/images/jpg-png/upper-bg-home.png) no-repeat;
  background-size: 100%;

  .container {
    margin-left: 50.5px;
    margin-top: 38px;

    .back-button {
      position: absolute;
      top: 180px;
    }

    &-product-row {
      display: flex;
      align-items: center;

      .image-product {
        margin-top: 20px;
        margin-left: 11px;

        img {
          width: 313px;
        }
      }

      .feature-icons {
        display: flex;
        flex-direction: column;
        margin-top: 65px;
        margin-left: 40px;
        margin-right: 47px;

        img {
          width: 100px;
          margin-bottom: 23px;
        }
      }

      .feature-info {
        position: relative;
        width: 326px;
        height: 360px;
        margin-top: -45px;
        color: $main-font-color;
        z-index: 9999;

        ul {
          margin: 0;
          padding: 0;

          li {
            list-style: none;
            margin-bottom: 5px;

            &.doble-line {
              text-indent: -15px;
              margin-left: 13px;
              margin-bottom: 10px;
            }

            img {
              display: inline-block;
              margin-right: 3px;
            }
          }
        }

        h3 {
          position: absolute;
          width: 100%;
          top: -25px;
          left: -7px;
        }

        &-skin-oil {
          position: absolute;
          border-bottom: 1px solid rgb(200, 221, 234);
          letter-spacing: -0.5px;
          width: 100%;
          top: 38px;
          left: -6px;
          padding-bottom: 11px;

          p {
            margin-top: 0px;
            margin-bottom: 10px;
          }
        }

        &-hours-barrier {
          position: absolute;
          border-bottom: 1px solid rgb(200, 221, 234);
          width: 100%;
          bottom: 75px;
          top: 160px;
          left: -7px;
          line-height: 18px;
          letter-spacing: -0.6px;
          padding-bottom: 30px;

          p {
            margin-top: 0px;
            margin-bottom: 10px;
          }
        }

        &-sensitive-skin {
          position: absolute;
          width: 100%;
          bottom: -2px;
          top: 300px;
          left: -7px;
          letter-spacing: -0.4px;

          p {
            margin-top: 0px;
            margin-bottom: 10px;
          }
        }

        .face-icon {
          width: 51.5px;
          position: absolute;
          right: -68px;
          top: 8px;
        }
      }
    }

    .product-nav {
      position: absolute;
      z-index: 9999;
      bottom: 127px;
      left: 119px;
      border: 1px solid $light-blue;
      box-shadow: 0px 9px 14px rgba($color: $gray-backdrop, $alpha: 0.06);
      border-radius: 8px;
      overflow: hidden;

      ul {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;

        li {
          list-style: none;

          >a {
            font-size: 12.5px;
            color: $main-font-color;
            padding: 10px 12.5px;
            line-height: 1;
            display: inline-block;
            font-family: 'Sofia Pro Bold';
          }

          >a.router-link-exact-active {
            background: $light-blue-2;
          }

          &:first-child {
            border-right: 1px solid $light-blue;
          }
        }
      }
    }

    .more-data {
      position: absolute;
      width: 100%;
      top: 575px;
      left: 542px;
      z-index: 1000;

      .btn-special {
        position: relative;

        img {
          width: 158px;
        }

        p {
          position: absolute;
          left: 31px;
          top: 13px;
          font-size: 14px;
          line-height: 0px;
          color: $white;
        }
      }
    }
  }
}
</style>
