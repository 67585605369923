<template>
  <page-layout>
    <div class="content slide-swipe">
      <div class="heading">
        <h1 class="main-section-title">Gentle Skin Cleanser</h1>
        <p class="description">
          Gently cleanses and preserves skin's moisture barrier
        </p>
      </div>
      <div class="back-button">
        <img
          src="@/assets/images/jpg-png/back-button.png"
          width="39.5"
          alt=""
          @click="goLastCategory"
          @touchend="goLastCategory"
        />
      </div>
      <div class="content-product-row">
        <div class="image-product">
          <img src="@/assets/images/jpg-png/gentlesc-img-product.png" alt="" />
        </div>
        <div class="feature-icons">
          <img src="@/assets/images/jpg-png/95-percent.png" alt="" />
          <img src="@/assets/images/jpg-png/drop-only.png" alt="" />
          <img src="@/assets/images/jpg-png/sensitive-skin-icon.png" alt="" />
        </div>
        <div class="feature-info">
          <h3 class="f-bold">
            For dry to normal, sensitive skin
            <img
              class="face-body-icons"
              src="@/assets/images/jpg-png/body-face-icons.png"
              alt=""
            />
          </h3>
          <div class="feature-info-skin-oil">
            <p class="f-light text-info">
              95% of subjects felt that it
              <span class="f-bold"
                >GENTLY CLEANSED<br />
                THEIR SKIN</span
              >
            </p>
          </div>
          <div class="feature-info-hours-barrier">
            <p class="f-light text-info">
              Clinically proven to
              <span class="f-bold">INCREASE SKIN HYDRATION</span><br />
              after just 1 use
            </p>
          </div>
          <div class="feature-info-sensitive-skin">
            <p class="f-light text-info">
              <span class="f-bold">PRESERVES SKIN BARRIER</span> even after<br />
              repeated washes
            </p>
          </div>
          <div class="feature-info-hidratation-study">
            <img
              src="@/assets/images/svg/hidratation-study-button.svg"
              alt="hidratation study button"
              @click="openModal('GscModalHydrationStudy')"
              @touchend="openModal('GscModalHydrationStudy')"
            />
          </div>
        </div>
        <div class="feature-btn">
          <img
            @click="openModal('GscModal2')"
            @touchend="openModal('GscModal2')"
            class="see-data-2"
            src="@/assets/images/jpg-png/see-data-img.png"
            width="70"
            alt=""
          />
          <img
            @click="openModal('GscModal3')"
            @touchend="openModal('GscModal3')"
            class="see-data-3"
            src="@/assets/images/jpg-png/see-data-img.png"
            width="70"
            alt=""
          />
        </div>
      </div>
      <div class="product-nav">
        <ul>
          <li>
            <router-link :to="{ name: 'daily-cleansers:gsc-benefits' }"
              >Key Benefits</router-link
            >
          </li>
          <li>
            <router-link :to="{ name: 'daily-cleansers:gsc-features' }"
              >Key Features</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/common/PageLayout.vue';

export default {
  components: {
    PageLayout,
  },
  mounted() {
    this.$route.params.onSwipeLeft = this.onSwipeLeft;
    this.$route.params.onSwipeRight = this.onSwipeRight;
  },
  methods: {
    onSwipeLeft() {
      return this.$router.push({ name: 'daily-cleansers:gsc-features' });
    },
    onSwipeRight() {
      return this.$router.push({ name: 'daily-cleansers:gsc-benefits' });
    },
    openModal(modalName) {
      this.toggleModal(modalName);
    },
  },
};
</script>

<style lang="scss">
.gsc-benefits {
  background: url(~@/assets/images/jpg-png/upper-bg-home.png) no-repeat;
  background-size: 100%;
  .content {
    margin-left: 50.5px;
    margin-top: 38px;
    .back-button {
      position: absolute;
      top: 135px;
    }
    &-product-row {
      display: flex;
      align-items: center;
      margin-top: 20px;
      .image-product {
        margin-top: -30px;
        img {
          width: 309px;
        }
      }
      .feature-icons {
        display: flex;
        flex-direction: column;
        margin-top: 40px;
        margin-left: 53px;
        margin-right: 47px;
        img {
          width: 100px;
          margin-bottom: 20px;
        }
      }
      .feature-info {
        z-index: 9999;
        color: $main-font-color;
        margin-top: 40px;
        position: relative;
        .face-body-icons {
          width: 111px;
          position: absolute;
          right: -100px;
          top: 8px;
        }
        h3 {
          margin: 0;
          margin-top: -1px;
          margin-left: -8px;
          margin-bottom: 35px;
          span {
            font-size: 10px;
            .sup-btn-description {
              margin-left: 19px;
              border: 1px solid rgb(200, 221, 234);
              border-radius: 30px;
              padding: 2px 8px 3px 8px;
            }
          }
        }
        &-skin-oil {
          margin-bottom: 35px;
          padding-bottom: 23px;
          border-bottom: 1px solid rgb(200, 221, 234);
        }
        &-hours-barrier {
          border-bottom: 1px solid rgb(200, 221, 234);
          padding-bottom: 24px;
          ul {
            margin: 0;
            padding: 0;
            li {
              font-family: 'Sofia Pro Light';
              list-style: none;
              margin-bottom: 15px;
              &:first-child {
                text-indent: -23px;
                margin-left: 22px;
              }
              img {
                display: inline-block;
                margin-right: 10px;
              }
            }
          }
        }
        &-sensitive-skin {
          margin-top: 40px;
        }
        &-hidratation-study {
          margin-left: -15px;
          margin-top: 38px;
          img {
            margin-left: -5px;
            width: 220px;
          }
        }
        .text-info {
          font-size: 14px;
          margin-top: 36px;
          margin-left: -8px;
        }
      }
      .feature-btn {
        display: flex;
        flex-direction: column;
        margin-top: 25px;
        margin-left: 13px;
        .see-data-1 {
          margin-top: 50px;
          margin-bottom: 50px;
        }
        .see-data-2 {
          margin-top: 105px;
          margin-bottom: 42px;
        }
      }
    }
    .product-nav {
      position: absolute;
      z-index: 9999;
      bottom: 140px;
      left: 100px;
      border: 1px solid $light-blue;
      box-shadow: 0px 9px 14px rgba($color: $gray-backdrop, $alpha: 0.06);
      border-radius: 8px;
      overflow: hidden;
      ul {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
        li {
          list-style: none;
          > a {
            font-size: 12.5px;
            color: $main-font-color;
            padding: 10px 12.5px;
            line-height: 1;
            display: inline-block;
            font-family: 'Sofia Pro Bold';
          }
          > a.router-link-exact-active {
            background: $light-blue-2;
          }
          &:first-child {
            border-right: 1px solid $light-blue;
          }
        }
      }
    }
  }
}
</style>
