<template>
  <page-layout>
    <div class="content slide-swipe">
      <div class="heading">
        <h1 class="main-section-title">RESTORADERM ITCH RELIEF GEL</h1>
        <p class="description">
          This lightweight gel is designed to provide instant and long-lasting relief for itchy, eczema prone skin.
        </p>
      </div>
      <div class="back-button">
        <img
          src="@/assets/images/jpg-png/back-button.png"
          width="39.5"
          alt=""
          @click="goLastCategory"
          @touchend="goLastCategory"
        />
      </div>
      <div class="content-product-row">
        <div class="image-product">
          <img
            src="@/assets/images/jpg-png/products/dermacontrol-and-restoraderm/details/restoraderm-itch-relief-gel-detail.png"
            alt=""
          />
          <div class="product-nav">
            <ul>
              <li>
                <router-link
                  :to="{ name: 'derma-control:itch-relief-gel-benefits' }"
                  >Key Benefits</router-link
                >
              </li>
              <li>
                <router-link
                  :to="{ name: 'derma-control:itch-relief-gel-features' }"
                  >Key Features</router-link
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="feature-content">
          <h3 class="f-bold">
            Designed for eczema-prone, sensitive skin
            <img
              class="face-body-icons"
              src="@/assets/images/jpg-png/body-icon.png"
              alt=""
            />
          </h3>
          <div class="feature-content-inner">
            <!-- ROW 1 -->
            <div class="row">
              <img
                class="icon"
                src="@/assets/images/jpg-png/icons/30-seconds.png"
                alt=""
              />
              <div class="info">
                <h4 class="f-bold">INSTANT AND LONG-LASTING ITCH RELIEF</h4>
                <ul class="f-light">
                  <li>Itch reduction in as little as 30 seconds with relief for up to 12 hours</li>
                  <li>Unique cool touch applicator instantly soothes skin</li>
                </ul>
              </div>
            </div>
            <!-- ROW 2 -->
            <div class="row">
              <img
                class="icon"
                src="@/assets/images/jpg-png/icons/healthy-looking.png"
                alt=""
              />
              <div class="info">
                <h4 class="f-bold">SOOTHES ECZEMA PRONE SKIN</h4>
                <p class="f-light">Significantly improves skin symptoms like burning or stinging for up to 24 hours</p>
              </div>
              <a>
                <img
                  @click="openModal('ItchReliefGelSeeDataModal')"
                  class="see-data-1"
                  src="@/assets/images/jpg-png/see-data-img.png"
                  width="70"
                  alt=""
              /></a>
            </div>
            <!-- ROW 3 -->
            <div class="row">
              <img
                class="icon"
                src="@/assets/images/jpg-png/icons/itchy.png"
                alt=""
              />
              <div class="info">
                <h4 class="f-bold">ITCH RELIEF FOR YOUR PATIENTS</h4>
                <ul class="f-light">
                  <li>91% said the product quickly soothed their itchy skin</li>
                  <li>88% said the product immediately helped calm their eczema-prone skin</li>
                </ul>
              </div>
            </div>
            <!-- ROW 4 -->
            <div class="btn-fucsia">
              <img
                src="@/assets/images/svg/head-to-head-data-button.svg"
                alt="hidratation study button"
                @click="openModal('ItchReliefGelSeeMoreDataModal')"
                @touchend="openModal('ItchReliefGelSeeMoreDataModal')"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </page-layout>
</template>

<script>
import PageLayout from "@/components/common/PageLayout.vue";

export default {
  components: {
    PageLayout,
  },
  mounted() {
    this.$route.params.onSwipeLeft = this.onSwipeLeft;
    this.$route.params.onSwipeRight = this.onSwipeRight;
  },
  methods: {
    onSwipeLeft() {
      return this.$router.push({ name: "derma-control:itch-relief-gel-features" });
    },
    onSwipeRight() {
      return this.$router.push({ name: "derma-control:itch-relief-gel-benefits" });
    },
    openModal(modalName) {
      this.toggleModal(modalName);
    },
  },
};
</script>

<style lang="scss">
.itch-relief-gel-benefits {
  background: url(~@/assets/images/jpg-png/upper-bg-home.png) no-repeat;
  background-size: 100%;
  .content {
    .heading {
      position: absolute;
      top: 41px;
      left: 50px;
      h1 {
        margin-top: 0;
        margin-bottom: 22px;
        letter-spacing: -0.8px;
      }
    }
    .back-button {
      position: absolute;
      top: 151px;
      left: 50px;
    }
    .description {
      max-width: 450px;
    }
    .btn-fucsia{
      z-index: 9999;
      img {
        margin-left: 117px;
        width: 220px;
      }
    }
    &-product-row {
      display: flex;
      align-items: flex-start;
      position: relative;
      top: 176px;
      margin-left: 61px;
      h3 {
        margin: 0 0 15px 15px;
        color: #004987;
        letter-spacing: 0.15px;
      }
      .row {
        display: flex;
        align-items: center;
        color: $dark-blue;
        .icon {
          width: 108px;
          margin-right: 25px;
        }
        p {
          margin: 0;
          position: relative;
          width: 330px;
          letter-spacing: -0.45px;
          margin-right: 10px;
          line-height: 18px;
        }
        &:nth-child(2) {
          p {
            &::before,
            &::after {
              content: "";
              position: absolute;
              left: 0;
              width: 97%;
              background-color: $light-blue-2;
              height: 1px;
            }
            &::before {
              top: -54px;
            }
            &::after {
              bottom: -24px;
            }
          }
        }
        h4{
          margin: 0 0 10px 0;
          // letter-spacing: -.8px;
        }
        .info{
          ul{
            margin: 0;
            padding: 0;
            width: 330px;
            li{
              list-style: none;
              background: url(~@/assets/images/jpg-png/polygon-mark.png) left 2px no-repeat;
              background-size: 8px;
              padding-left: 15px;
            }
          }
        }
      }
      .image-product {
        margin-top: 17px;
        width: 309px;
        margin-right: 34px;
        img {
          width: 100%;
        }
      }
      .feature-content {
        width: 553px;
        position: relative;
        .face-body-icons {
          width: 55px;
          position: absolute;
          right: 16px;
          top: 5px;
        }
        &-inner {
          height: 365px;
          display: flex;
          flex-flow: column;
          justify-content: space-between;
        }
      }
    }
    .product-nav {
      position: absolute;
      z-index: 9999;
      bottom: -14px;
      left: 58px;
      border: 1px solid $light-blue;
      box-shadow: 0px 9px 14px rgba($color: $gray-backdrop, $alpha: 0.06);
      border-radius: 8px;
      overflow: hidden;
      ul {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
        li {
          list-style: none;
          > a {
            font-size: 12.5px;
            color: $main-font-color;
            padding: 10px 12.5px;
            line-height: 1;
            display: inline-block;
            font-family: 'Sofia Pro Bold';
          }
          > a.router-link-exact-active {
            background: $light-blue-2;
          }
          &:first-child {
            border-right: 1px solid $light-blue;
          }
        }
      }
    }
  }
}
</style>
