<template>
  <page-layout>
    <div class="content slide-swipe">
      <div class="heading">
        <h1 class="main-section-title">Moisturizing Cream</h1>
        <p class="description">
          Ultra-rich and clinically tested to help skin repair after barrier
          damage
        </p>
      </div>
      <div class="back-button">
        <img
          src="@/assets/images/jpg-png/back-button.png"
          width="39.5"
          alt=""
          @click="goLastCategory"
          @touchend="goLastCategory"
        />
      </div>
      <div class="content-product-row">
        <div class="image-product">
          <img
            src="@/assets/images/jpg-png/moisturizing-cream-detail.png"
            alt=""
          />
        </div>
        <div class="feature-info">
          <h3 class="f-bold">Key Features</h3>
          <div class="key-features-info">
            <ul>
              <li>
                <img
                  src="@/assets/images/jpg-png/polygon-mark.png"
                  width="8"
                  alt=""
                />Provides immediate and long-lasting relief
              </li>
              <li>
                <img
                  src="@/assets/images/jpg-png/polygon-mark.png"
                  width="8"
                  alt=""
                />Proven safe and well tolerated
              </li>
              <li>
                <img
                  src="@/assets/images/jpg-png/polygon-mark.png"
                  width="8"
                  alt=""
                />No fragrances, sulfates, or parabens
              </li>
              <li>
                <img
                  src="@/assets/images/jpg-png/polygon-mark.png"
                  width="8"
                  alt=""
                />Non-comedogenic, Hypoallergenic
              </li>
            </ul>
          </div>
          <div class="ingredients-info">
            <h3 class="f-bold">Ingredients</h3>
            <p>
              Water, <span class="f-bold">Glycerin</span>, Petrolatum,
              Dicaprylyl Ether, Dimethicone, <br />
              Glyceryl Stearate, Cetyl Alcohol, Helianthus Annuus <br />
              (Sunflower) Seed Oil, Peg-30 Stearate,
              <span class="f-bold"
                >Panthenol, <br />
                Niacinamide</span
              >, Prunus Amygdalus Dulcis (Sweet Almond) Oil, <br />
              Tocopheryl Acetate, Pantolactone, Dimethiconol, Acrylates/<br />
              C10-30 Alkyl Acrylate Crosspolymer, Carbomer, Propylene <br />
              Glycol, Disodium Edta, Benzyl Alcohol, Phenoxyethanol, <br />
              Sodium Hydroxide, Citric Acid
            </p>
          </div>
        </div>
      </div>
      <div class="product-nav">
        <ul>
          <li>
            <router-link
              :to="{ name: 'daily-moisturizers:moisturizing-cream-benefits' }"
              >Key Benefits</router-link
            >
          </li>
          <li>
            <router-link
              :to="{ name: 'daily-moisturizers:moisturizing-cream-features' }"
              >Key Features</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/common/PageLayout.vue';

export default {
  components: {
    PageLayout,
  },
  mounted() {
    this.$route.params.onSwipeLeft = this.onSwipeLeft;
    this.$route.params.onSwipeRight = this.onSwipeRight;
  },
  methods: {
    onSwipeLeft() {
      return this.$router.push({
        name: 'daily-moisturizers:moisturizing-cream-features',
      });
    },
    onSwipeRight() {
      return this.$router.push({
        name: 'daily-moisturizers:moisturizing-cream-benefits',
      });
    },
  },
};
</script>

<style lang="scss">
.moisturizing-cream-features {
  background: url(~@/assets/images/jpg-png/upper-bg-home.png) no-repeat;
  background-size: 100%;
  .content {
    margin-left: 50.5px;
    margin-top: 38px;
    .heading {
      .description {
        sup {
          font-size: 55%;
        }
      }
    }
    .back-button {
      position: absolute;
      top: 139px;
    }
    &-product-row {
      display: flex;
      align-items: center;
      .image-product {
        margin-top: 94.5px;
        margin-right: 109px;
        margin-left: 13px;
        img {
          width: 309px;
        }
      }
      .feature-info {
        position: relative;
        margin-top: 70px;
        color: $main-font-color;
        width: 450px;
        height: 310px;
        h3 {
          position: absolute;
          width: 100%;
          top: -11px;
        }
        .key-features-info {
          border-bottom: 1px solid rgb(200, 221, 234);
          padding-bottom: 35px;
          letter-spacing: -0.5px;
          position: absolute;
          width: 92%;
          top: 58px;
          ul {
            margin: 0;
            padding: 0;
            margin-top: -6px;
            li {
              font-family: 'Sofia Pro Light';
              list-style: none;
              sup {
                font-size: 40%;
              }
              img {
                display: inline-block;
                margin-right: 10px;
              }
            }
          }
        }
        .ingredients-info {
          padding-top: 25px;
          position: absolute;
          width: 100%;
          top: 177px;
          h3 sup {
            font-size: 60%;
          }
          p {
            font-family: 'Sofia Pro Light';
            line-height: 1.1;
            margin-top: 25px;
            letter-spacing: -0.5px;
          }
        }
      }
    }
    .product-nav {
      position: absolute;
      z-index: 9999;
      bottom: 127px;
      left: 119px;
      border: 1px solid $light-blue;
      box-shadow: 0px 9px 14px rgba($color: $gray-backdrop, $alpha: 0.06);
      border-radius: 8px;
      overflow: hidden;
      ul {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
        li {
          list-style: none;
          > a {
            font-size: 12.5px;
            color: $main-font-color;
            padding: 10px 12.5px;
            line-height: 1;
            display: inline-block;
            font-family: 'Sofia Pro Bold';
          }
          > a.router-link-exact-active {
            background: $light-blue-2;
          }
          &:first-child {
            border-right: 1px solid $light-blue;
          }
        }
      }
    }
  }
}
</style>
