<template>
  <div class="content">
    <h1 class="f-bold uppercase">
      Clinically proven to remove up to 94% of skin impurities
    </h1>
    
    <div class="row">
      <div class="col-25">
        <img
          class="deep-cleanse"
          src="@/assets/images/jpg-png/deep-cleanse-dfc.jpg"
          width="200"
          alt=""
        />
        <img
          class="deep-cleanse"
          src="@/assets/images/jpg-png/deep-cleanse-control.jpg"
          width="200"
          alt=""
        />
      </div>

      <div class="col-50">
        <div class="pollution-info">
          <p class="figure main-section-title">-94%</p>
          <p>Pollution particles with Daily Facial Cleanser Fragance Free</p>
        </div>
        <div class="pollution-info">
          <p class="figure main-section-title">-31%</p>
          <p>Pollution particles with water only</p>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.dfcffseedatamodal > .modal-layout__content {
  background: url(~@/assets/images/jpg-png/upper-bg-home.png) no-repeat;
  border: 1px solid #fff;
  border-radius: 8px;
  .content {
    margin-left: 40px;
    .row {
      display: flex;
      margin-left: 130px;
      padding-top: 20px;
    }
    .col-25 {
      width:25%;
      justify-content: center;
      display: grid;
      img:nth-child(1) {
        margin-bottom: 60px;
      }
      .deep-cleanse {
        border-radius: 20px;
      }
    }
    .col-50 {
      width:50%;
      display: grid;
      .pollution-info:nth-child(1){
        padding-top: 30px;
        padding-bottom: 90px;
      }
      .figure {
          font-size: 64px;
          margin-bottom: 0;
        }
      .pollution-info{       
        p {
          padding-left: 50px;
          margin-top: 0;
        }
      }
    }
    h1 {
      font-size: 24px;
    }
  }
}
.dfcffseedatamodal {
  .modal-layout__close {
    top: 18px;
  }
  .modal-layout__content {
    width: 931px;
    height: 500px;
    top: 17px;
    left: 2px;
  }
  font-family: 'Sofia Pro';
  color: $main-font-color;
}
</style>
