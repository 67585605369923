<template>
  <page-layout>
    <div class="container slide-swipe">
      <div class="heading">
        <h1 class="main-section-title">RESTORADERM<br>FLARE-UP RELIEF CREAM</h1>
        <p class="description">
          For eczema-prone sensitive skin
        </p>
      </div>
      <div class="back-button">
        <img
          src="@/assets/images/jpg-png/back-button.png"
          width="39.5"
          alt=""
          @click="goLastCategory" @touchend="goLastCategory"
        />
      </div>
      <div class="container-product-row">
        <div class="image-product">
         <img src="@/assets/images/jpg-png/restoraderm-flare-up-relief-cream-detail.png" alt="" />
        </div>
        <div class="feature-info">
          <h3 class="f-bold">Key Features</h3>
          <div class="key-features-info">
            <ul>
              <li class="doble-line">
                <img
                  src="@/assets/images/jpg-png/polygon-mark.png"
                  width="8"
                  alt=""
                />Patented, Ad-Resyl<sup>&reg;</sup> is designed to help restore a damaged skin barrier 
              </li>
              <li class="doble-line">
                <img
                  src="@/assets/images/jpg-png/polygon-mark.png"
                  width="8"
                  alt=""
                />Proprietary Filaggrin Technology helps strengthen skin’s barrier
              </li>
              <li class="doble-line">
                <img
                  src="@/assets/images/jpg-png/polygon-mark.png"
                  width="8"
                  alt=""
                />Skin protectant, Colloidal Oatmeal soothes dry, itchy, irritated skin
              </li>
              <li class="doble-line">
                <img
                  src="@/assets/images/jpg-png/polygon-mark.png"
                  width="8"
                  alt=""
                />Fragrance free, paraben free, steroid free, phthalate free, BHA free, BHT free
              </li>
              <li>
                <img
                  src="@/assets/images/jpg-png/polygon-mark.png"
                  width="8"
                  alt=""
                />Hypoallergenic, non-comedogenic
              </li>
              <li>
                <img
                  src="@/assets/images/jpg-png/polygon-mark.png"
                  width="8"
                  alt=""
                />Dermatologist developed and tested
              </li>
            </ul>
          </div>
          <div class="ingredients-info">
            <h3 class="f-bold" style="margin-top:28px">Ingredients</h3>
            <p>
              <span class="f-bold">Active Ingredient:</span> Colloidal Oatmeal 2.0% <br />
              <span class="f-bold">Inactive Ingredients:</span> Water, Ethylhexyl Palmitate, Glycerin, Butyrospermum Parkii (Shea) Butter, Cetearyl Alcohol, Helianthus Annuus (Sunflower) Seed Oil, Dimethicone, Pentylene Glycol, Glyceryl Stearate, PEG-100 Stearate, Microcrystalline Cellulose, Sodium PCA, Arginine, Sodium Polyacrylate Starch, Caprylyl Glycol, Citric Acid, Sodium Phytate, Cellulose Gum, Ophiopogon Japonicus Root Extract, Xanthan Gum, Tocopheryl Acetate, Maltodextrin, Ceramide NP
            </p>
          </div>
        </div>  
      </div>
      <div class="product-nav">
        <ul>
          <li>
            <router-link :to="{ name: 'derma-control:restoraderm-flareup-benefits' }">Key Benefits</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'derma-control:restoraderm-flareup-features' }">Key Features</router-link>
          </li>
        </ul>
      </div>
    </div>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/common/PageLayout.vue'
export default {
  components: {
    PageLayout
  },
  mounted(){
    this.$route.params.onSwipeLeft = this.onSwipeLeft;
    this.$route.params.onSwipeRight = this.onSwipeRight;
  },
  methods: {
    onSwipeLeft() {
      return this.$router.push({name: 'derma-control:restoraderm-flareup-features'});
    },
    onSwipeRight() {
      return this.$router.push({name: 'derma-control:restoraderm-flareup-benefits'});
    }
  }
}
</script>

<style lang="scss">
.restoraderm-flareup-features {
  background: url(~@/assets/images/jpg-png/upper-bg-home.png) no-repeat;
  background-size: 100%;
  .container {
    margin-left: 50.5px;
    margin-top: 38px;
    .back-button {
      position: absolute;
      top: 180px;
    }
    &-product-row {
      display: flex;
      align-items: center;
      .image-product {
        margin-top: 65px;
        margin-right: 109px;
        margin-left: 11px;
        img {
          width: 313px;
        }
      }
      .feature-info {
        position: relative;
        margin-top: 90px;
        color: $main-font-color;
        width: 450px;
        height: 310px;
        h3 {
          position: absolute;
          width: 100%;
          top: -85px;
        }
        .key-features-info {
          border-bottom: 1px solid rgb(200, 221, 234);
          padding-bottom: 17px;
          letter-spacing: -0.5px;
          position: absolute;
          width: 92%;
          top: -18px;
          left: 5px;
          ul {
            margin: 0;
            padding: 0;
            margin-top: -6px;
            li {
              font-family: 'Sofia Pro Light';
              list-style: none;
              img {
                display: inline-block;
                margin-right: 10px;
              }
            }
            li.doble-line{
              text-indent: -18px;
              margin-left: 18px;
              span{
                text-indent: -18px;
              }
            }
          }
        }
        .ingredients-info {
          padding-top: 25px;
          position: absolute;
          width: 100%;
          top: 220px;
          z-index: 9999;
          p {
            font-family: 'Sofia Pro Light';
            line-height: 1.1;
            margin-top: -42px;
            letter-spacing: -0.5px;
          }
        }
      }
    }
    .product-nav {
      position: absolute;
      z-index: 9999;
      bottom: 127px;
      left: 119px;
      border: 1px solid $light-blue;
      box-shadow: 0px 9px 14px rgba($color: $gray-backdrop, $alpha: 0.06);
      border-radius: 8px;
      overflow: hidden;
      ul {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
        li {
          list-style: none;
          > a {
            font-size: 12.5px;
            color: $main-font-color;
            padding: 10px 12.5px;
            line-height: 1;
            display: inline-block;
            font-family: 'Sofia Pro Bold';
          }
          > a.router-link-exact-active {
            background: $light-blue-2;
          }
          &:first-child {
            border-right: 1px solid $light-blue;
          }
        }
      }
    }
  }
}
</style>
