<template>
  <div class="content">
    <h1 class="f-bold">
      Hydration Results 30 Minutes and 1 Week Post-Application of Moisturizing
      Lotion
    </h1>
    <img
      src="@/assets/images/jpg-png/moisturizing-lotion-modal-hydration-study.png"
      alt=""
    />
    <ul>
      <li>
        Heatmaps were generated based on hydration results per participant
      </li>
      <li>
        Measurements at 30 minutes were performed post-product application
      </li>
      <li>
        Measurements at 1 week were performed on participant's clean face after
        using the product every day for 1 week
      </li>
    </ul>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.moisturizinglotionmodalhydrationstudy {
  .modal-layout__content {
    padding: 25px 38px;
    h1 {
      font-size: 26px;
      margin: 0;
      letter-spacing: 0.2px;
      text-transform: uppercase;
      line-height: 30px;
      margin-top: 4px;
    }
    h2 {
      text-align: center;
      font-size: 18px;
      letter-spacing: -0.95px;
      margin: 56px 0 27px -18px;
      color: $gray-second-text;
    }
    img {
      width: 591px;
      display: block;
      margin: 40px auto;
    }
    ul {
      margin: 0 0 0 10px;
      padding: 0;
      font-size: 16px;
      li {
        margin: 0;
        padding: 0;
        list-style: none;
        position: relative;

        &::before {
          content: "*";
          width: 10px;
          height: 10px;
          position: absolute;
          left: -10px;
        }
      }
    }
  }
}
</style>
