<template>
    <page-layout>
      <div class="content">
        <div class="main-heading">
          <h1>Personalized Skincare Routines</h1>
        </div>
        <div class="secondary-heading">
          <h4 class="text-short-description">
            Cetaphil's expertly tailored formulas address a wide <br>
            range of skin conditions.
          </h4>
        </div>

        <div class="grid-container">
            <div class="grid-row">
                <div class="item" id="row1-col1"></div>
                <div class="item" id="row1-col2">
                    <img
                        src="@/assets/images/jpg-png/clenase-marker.png"
                        height="32"
                        alt="logo"
                    />
                </div>
                <div class="item" id="row1-col3">
                    <img
                        src="@/assets/images/jpg-png/treat-marker.png"
                        height="32"
                        alt="logo"
                    />
                </div>
                <div class="item" id="row1-col4">
                    <img
                        src="@/assets/images/jpg-png/moisturize-marker.png"
                        height="32"
                        alt="logo"
                    />
                </div>
                <div class="item" id="row1-col5">
                    <img
                        src="@/assets/images/jpg-png/protect-marker.png"
                        height="32"
                        alt="logo"
                    />
                </div>
                <div class="item" id="row1-col6"></div>
            </div>
    
            <div class="grid-row">
                <div class="item" id="row2-col1">
                    <img
                        @click="openDetail('daily-cleansers:dfc-ff-benefits')"
                        src="@/assets/images/svg/acne-prone-marker.svg"
                        height="108px"
                        alt=""
                    />
                </div>
                <div class="item" id="row2-col2">
                    <img
                        @click="openDetail('daily-cleansers:dfc-ff-benefits')"
                        src="@/assets/images/jpg-png/patient-routine-prd1.png"
                        height="86px"
                        alt=""
                    />
                    <img
                        @click="openDetail('derma-control:dco-rfw-benefits')"
                        src="@/assets/images/jpg-png/patient-routine-prd2.png"
                        height="82px"
                        style="margin-left:10px;"
                        alt=""
                    />
                </div>
                <div class="item" id="row2-col3">
                    <h3>Prescribed <br>acne rx</h3>
                </div>
                <div class="item" id="row2-col4">
                    <img
                        @click="openDetail('daily-moisturizers:lotion-details')"
                        src="@/assets/images/jpg-png/moisturizing-lotion-bottle.png"
                        height="94px"
                        alt=""
                    />
                </div>
                <div class="item" id="row2-col5">
                    <img
                        @click="openDetail('derma-control:dco-amspf30-benefits')"
                        src="@/assets/images/jpg-png/patient-routine-prd11.png"
                        style="margin-right:5px;"
                        height="86px"
                        width="33px"
                        alt=""
                    />
                    <img
                        @click="openDetail('daily-moisturizers:dof-facial-moisturizer-benefits')"
                        src="@/assets/images/jpg-png/patient-routine-prd12.png"
                        height="86px"
                        width="30px"
                        alt=""
                    />
                </div>
                <div class="item patient-photo-container" id="row2-col6">
                    <div>
                        <img
                            @click="openModal('PatientRoutineModalOne')"
                            @touchend="openModal('PatientRoutineModalOne')"
                            src="@/assets/images/svg/patient-journey-icon.svg"
                            height="48px"
                            alt=""
                        />
                        <p>Patient <br>Journey</p>
                    </div>
                    <div>
                        <img
                            @click="openModal('PatientRoutinePhotoModal1')"
                            @touchend="openModal('PatientRoutinePhotoModal1')"
                            src="@/assets/images/svg/patient-journey-photo.svg"
                            height="48px"
                            alt=""
                        />
                        <p>Patient <br>Photo</p>
                    </div>
                </div>
            </div>
    
            <div class="grid-row">
                <div class="item" id="row3-col1">
                    <img
                        @click="openDetail('daily-cleansers:dfc-ff-benefits')"
                        src="@/assets/images/svg/redness-prone-marker.svg"
                        height="108px"
                        style="margin-top:10px;"
                        alt=""
                    />
                </div>
                <div class="item" id="row3-col2" style="margin-left: 35px;">
                    <img
                        @click="openDetail('daily-cleansers:gsc-benefits')"
                        src="@/assets/images/jpg-png/gsc-img.png"
                        height="86px"
                        style="margin-right:-45px;z-index:100001;"
                        alt=""
                    />
                    <img
                        @click="
                            openLink(
                            'https://www.cetaphil.com/us/cleansers/foaming-face-wash-for-redness-prone-skin/302993889083.html'
                            )
                        "
                        @touchend="
                            openLink(
                                'https://www.cetaphil.com/us/cleansers/foaming-face-wash-for-redness-prone-skin/302993889083.html'
                            )
                        "
                        src="@/assets/images/jpg-png/patient-routine-prd3.png"
                        height="102px"
                        style="margin-left:18px;margin-top:-12px;"
                        alt=""
                    />
                </div>
                <div class="item" id="row3-col3">
                    <h3>Prescribed <br>rosacea Rx</h3>
                </div>
                <div class="item" id="row3-col4" style="margin-right:-32px;">
                    <img
                        @click="openDetail('daily-moisturizers:moisturizing-cream-benefits')"
                        src="@/assets/images/jpg-png/mc-product.png"
                        height="48px"
                        width="48px"
                        style="margin-right:5px;margin-top:38px;z-index:100001;"
                        alt=""
                    />
                    <a
                        @click="
                            openLink(
                            'https://www.cetaphil.com/us/moisturizers/redness-relieving-night-moisturizer/302993889045.html'
                            )
                        "
                        @touchend="
                            openLink(
                            'https://www.cetaphil.com/us/moisturizers/redness-relieving-night-moisturizer/302993889045.html'
                            )
                        "
                        target="_system"
                    >
                        <img
                            @click="openDetail('')"
                            src="@/assets/images/jpg-png/patient-routine-prd7.png"
                            height="93px"
                            style="margin-left:-28px;margin-top:-4px;"
                            alt=""
                        />
                    </a>
                </div>
                <div class="item" id="row3-col5" style="margin-left:-32px;">
                    <a
                        @click="
                            openLink(
                            'https://www.cetaphil.com/us/moisturizers/redness-relieving-daily-facial-moisturizer-spf-40/302995889005.html'
                            )
                        "
                        @touchend="
                            openLink(
                            'https://www.cetaphil.com/us/moisturizers/redness-relieving-daily-facial-moisturizer-spf-40/302995889005.html'
                            )
                        "
                        target="_system"
                    >
                        <img
                            @click="openDetail('')"
                            src="@/assets/images/jpg-png/redness-relieving-spf-40.png"
                            style="margin-right:8px;margin-top:14px;margin-left:40px;"
                            height="80px"
                            alt=""
                        />
                    </a>
                    <img
                        @click="openDetail('daily-moisturizers:dof-facial-moisturizer-benefits')"
                        src="@/assets/images/jpg-png/patient-routine-prd12.png"
                        height="86px"
                        width="30px"
                        style="z-index:100001"
                        alt=""
                    />
                </div>
                <div class="item patient-photo-container" id="row3-col6" style="padding-top:10px;">
                    <div>
                        <img
                            @click="openModal('PatientRoutineModalTwo')"
                            @touchend="openModal('PatientRoutineModalTwo')"
                            src="@/assets/images/svg/patient-journey-icon.svg"
                            height="48px"
                            alt=""
                        />
                        <p>Patient <br>Journey</p>
                    </div>
                    <div>
                        <img
                            @click="openModal('PatientRoutinePhotoModal2')"
                            @touchend="openModal('PatientRoutinePhotoModal2')"
                            src="@/assets/images/svg/patient-journey-photo.svg"
                            height="48px"
                            alt=""
                        />
                        <p>Patient <br>Photo</p>
                    </div>
                </div>
            </div>
    
            <div class="grid-row">
                <div class="item" id="row4-col1">
                    <img
                        @click="openDetail('daily-cleansers:dfc-ff-benefits')"
                        src="@/assets/images/svg/eczema-prone-marker.svg"
                        height="108px"
                        alt=""
                    />
                </div>
                <div class="item" id="row4-col2">
                    <img
                        @click="openDetail('daily-cleansers:gsc-benefits')"
                        src="@/assets/images/jpg-png/gsc-img.png"
                        height="86px"
                        alt=""
                    />
                    <img
                        @click="openDetail('derma-control:soothing-wash-benefits')"
                        src="@/assets/images/jpg-png/patient-routine-prd4.png"
                        height="85px"
                        style="margin-left:10px;"
                        alt=""
                    />
                </div>
                <div class="item" id="row4-col3">
                    <h3>Prescribed <br>Eczema rx</h3>
                </div>
                <div class="item" id="row4-col4">
                    <div class="prd-container">
                        <img
                            @click="openDetail('derma-control:itch-relief-gel-benefits')"
                            src="@/assets/images/jpg-png/patient-routine-prd8.png"
                            height="75px"
                            width="39px"
                            style="margin-top:20px;"
                            alt=""
                        />
                        <img
                            @click="openDetail('derma-control:restoraderm-flareup-benefits')"
                            src="@/assets/images/jpg-png/patient-routine-prd9.png"
                            height="84px"
                            width="46px"
                            style="margin-top:13px;margin-left:-2px;"
                            alt=""
                        />
                        <img
                            @click="openDetail('derma-control:restoraderm-eczema-benefits')"
                            src="@/assets/images/jpg-png/patient-routine-prd10.png"
                            style="margin-top:4px;margin-left:-2px;"
                            height="93px"
                            width="52px"
                            alt=""
                        />
                    </div>
                    <div class="prd-title-container">
                        <p style="margin-left: 3px;margin-right:12px;">Relieve</p>
                        <p style="margin-right:10px;">Soothe</p>
                        <p>Maintain</p>
                    </div>
                </div>
                <div class="item" id="row4-col5">
                    <img
                        @click="openDetail('daily-moisturizers:dof-facial-moisturizer-benefits')"
                        src="@/assets/images/jpg-png/patient-routine-prd12.png"
                        height="86px"
                        width="30px"
                        alt=""
                    />
                </div>
                <div class="item patient-photo-container" id="row4-col6">
                    <div>
                        <img
                            @click="openModal('PatientRoutineModalThree')"
                            @touchend="openModal('PatientRoutineModalThree')"
                            src="@/assets/images/svg/patient-journey-icon.svg"
                            height="48px"
                            alt=""
                        />
                        <p>Patient <br>Journey</p>
                    </div>
                    <div>
                        <img
                            @click="openModal('PatientRoutinePhotoModal3')"
                            @touchend="openModal('PatientRoutinePhotoModal3')"
                            src="@/assets/images/svg/patient-journey-photo.svg"
                            height="48px"
                            alt=""
                        />
                        <p>Patient <br>Photo</p>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </page-layout>
  </template>
  
  <script>
  import PageLayout from "@/components/common/PageLayout.vue";
  
  export default {
    components: {
      PageLayout,
    },
    created() {
      this.setLastCategory("ctmp");
    },
    methods: {
      openDetail(name) {
        return this.$router.push({ name });
      },
      openModal(modalName) {
        this.toggleModal(modalName);
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .ctmp {
    background: url(~@/assets/images/jpg-png/upper-bg-home.png) no-repeat;
    background-size: 100%;
    .content {
        .main-heading {
          margin-left: 50px;
          margin-top: 60px;
          position: relative;
          h1 {
            font-size: 28px;
            font-family: "Sofia Pro bold";
            text-transform: uppercase;
            color: $main-font-color;
            line-height: 0.95;
            letter-spacing: -0.6px;
          }
          h2 {
            font-size: 23px;
            color: $white;
            text-transform: uppercase;
            line-height: 0.95;
            width: 200px;
            position: absolute;
            right: 89px;
            top: 43px;
            span {
              display: inline-block;
              margin-left: 28px;
              line-height: 1.1;
              letter-spacing: 1.5px;
            }
          }
        }
        .secondary-heading {
            margin-top: -4px;
            .text-short-description {
              font-family: "Sofia Pro Light";
              font-size: 14px;
              font-weight: 300;
              margin-left: 50px;
              color: $main-font-color;
              line-height: 1.5;
            }
        }

        .grid-container {
            display: grid;
            width: 85%;
            margin-left: 50px;
            z-index: 1000;
            position: absolute;
            margin-top: -5px;
            .grid-row {
                display: grid;
                grid-template-columns: 1fr 2fr 2fr 2fr 2fr 2fr;
            }
            .item {
                display: flex;
                justify-content: center;
                height: 118px;
                align-items: center;
                margin-bottom: 12px;
                h3 {
                    text-align: center;
                    font-family: "Sofia Pro";
                    color: $main-font-color;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 16px;
                    text-transform: uppercase;
                }
                p {
                    font-size: 10px;
                    font-family: "Sofia Pro";
                    color: $main-font-color;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
                .prd-container {
                    display: flex;
                }
                .prd-title-container {
                    display: flex;
                }
                &.patient-photo-container {
                    display: flex !important;
                    justify-content: space-evenly;
                    margin-top: 4px;
                }
            }
            [id^="row1-col"] {
                height: 50px;
                margin-bottom: 2px;
            }
            #row4-col4 {
                height: 140px;
                display: block;
                padding-left: 13px;
            }
            [id^="row"][id$="-col1"] {
                margin-top: -5px;
                margin-right: -40px;
            }
            [id^="row"][id$="-col6"] {
                display: block;
                img {
                    display: block;
                    margin-left: auto;
                    margin-right: auto;
                }
                p {
                    font-family: "Sofia Pro";
                    text-transform: uppercase;
                    text-align: center;
                    font-size: 12px;
                    line-height: 22px;
                    margin-top: 5px;
                }
            }
        }
    }
  }
  </style>
  