<template>
  <page-layout>
    <div class="content slide-swipe">
      <div class="heading">
        <h1 class="main-title">Gentle Skin Cleanser</h1>
        <p class="description">
          Designed for dry to normal, sensitive skin
        </p>
      </div>
      <div class="back-button">
        <img
          src="@/assets/images/jpg-png/back-button.png"
          width="39.5"
          alt=""
          @click="goLastCategory"
          @touchend="goLastCategory"
        />
      </div>
      <div class="content-product-row">
        <div class="image-product">
          <img src="@/assets/images/jpg-png/gentlesc-img-product.png" alt="" />
        </div>
        <div class="feature-info">
          <h3 class="f-bold">Key Features</h3>
          <div class="key-features-info">
            <ul>
              <li>
                <img
                  src="@/assets/images/jpg-png/polygon-mark.png"
                  width="8"
                  alt=""
                /><span
                  >Soap-free, and gentle at removing dirt, makeup,<br />
                  and impurities</span
                >
              </li>
              <li>
                <img
                  src="@/assets/images/jpg-png/polygon-mark.png"
                  width="8"
                  alt=""
                />Proven safe and well tolerated
              </li>
              <li>
                <img
                  src="@/assets/images/jpg-png/polygon-mark.png"
                  width="8"
                  alt=""
                />No fragances, sulfates, or parabens
              </li>
              <li>
                <img
                  src="@/assets/images/jpg-png/polygon-mark.png"
                  width="8"
                  alt=""
                />Non-comedogenic, Hypoallergenic
              </li>
            </ul>
          </div>
          <div class="ingredients-info">
            <h3 class="f-bold">Ingredients</h3>
            <p>
              Water, <span class="f-bold">Glycerin,</span> Cetearyl Alcohol,
              <span class="f-bold">Panthenol, Niacinamide,</span> <br />
              Pantolactone, Xanthan Gum, Sodium Cocoyl Isethionate, <br />
              Sodium Benzoate, Citric Acid
            </p>
          </div>
        </div>
      </div>
      <div class="product-nav">
        <ul>
          <li>
            <router-link :to="{ name: 'daily-cleansers:gsc-benefits' }"
              >Key Benefits</router-link
            >
          </li>
          <li>
            <router-link :to="{ name: 'daily-cleansers:gsc-features' }"
              >Key Features</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/common/PageLayout.vue';

export default {
  components: {
    PageLayout,
  },
  mounted() {
    this.$route.params.onSwipeLeft = this.onSwipeLeft;
    this.$route.params.onSwipeRight = this.onSwipeRight;
  },
  methods: {
    onSwipeLeft() {
      return this.$router.push({ name: 'daily-cleansers:gsc-features' });
    },
    onSwipeRight() {
      return this.$router.push({ name: 'daily-cleansers:gsc-benefits' });
    },
  },
};
</script>

<style lang="scss">
.gsc-features {
  background: url(~@/assets/images/jpg-png/upper-bg-home.png) no-repeat;
  background-size: 100%;
  .content {
    margin-left: 50.5px;
    margin-top: 40px;
    .heading {
      .main-title {
        font-family: 'Sofia Pro Bold';
        font-size: 27.5px;
        text-transform: uppercase;
        color: $main-font-color;
        letter-spacing: -0.94px;
        line-height: 1.1;
        margin-top: -2px;
      }
    }
    .back-button {
      position: absolute;
      top: 135px;
    }
    &-product-row {
      display: flex;
      align-items: center;
      .image-product {
        margin-top: 77px;
        margin-right: 121px;
        img {
          width: 309px;
        }
      }
      .feature-info {
        margin-top: 18px;
        color: $main-font-color;

        h3 {
          margin: 0;
          margin-top: 15px;
          margin-bottom: 10px;
        }
        .key-features-info {
          border-bottom: 1px solid rgb(200, 221, 234);
          padding-bottom: 30px;
          padding-top: 0px;
          ul {
            margin: 0;
            padding: 0;
            li {
              font-family: 'Sofia Pro Light';
              list-style: none;
              img {
                display: inline-block;
                margin-right: 10px;
              }
              &:first-child {
                text-indent: -18px;
                margin-left: 18px;
              }
            }
          }
        }
        .ingredients-info {
          padding-top: 5px;
          p {
            font-family: 'Sofia Pro Light';
            padding-bottom: 5px;
          }
        }
      }
    }
    .product-nav {
      position: absolute;
      z-index: 9999;
      bottom: 140px;
      left: 100px;
      border: 1px solid $light-blue;
      box-shadow: 0px 9px 14px rgba($color: $gray-backdrop, $alpha: 0.06);
      border-radius: 8px;
      overflow: hidden;
      ul {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
        li {
          list-style: none;
          > a {
            font-size: 12.5px;
            color: $main-font-color;
            padding: 10px 12.5px;
            line-height: 1;
            display: inline-block;
            font-family: 'Sofia Pro Bold';
          }
          > a.router-link-exact-active {
            background: $light-blue-2;
          }
          &:first-child {
            border-right: 1px solid $light-blue;
          }
        }
      }
    }
  }
}
</style>
