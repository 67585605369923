<template>
  <div class="content">
    <div class="content__list" style="width: 92%;">
      <h1 class="f-bold uppercase">References</h1>
      <ol class="f-light" style="margin-left:10px">
        <li>Farage MA. The prevalence of sensitive skin. Front Med 2019; 6: 98.</li>
        <li>Chen W, et al. J Eur Acad Dermatol Venereol. 2020 Aug;34(8):1779-1788.</li>
        <li>Galderma DOF [Cetaphil Consumer History page – https://www.cetaphil.com].</li>
        <li>Galderma Data on File [Cetaphil Clinical Testing Summary].</li>
        <li>Moore DJ and Rawlings AV. Int J Cosmet Sci. 2017;39(4):366-372 </li>
        <li>van Smeden J and Bouwstra JA. Curr Probl Dermatol. 2016;49:8-26 </li>
        <li>Tanno O, et al. Br J Dermatol. 2000;143(3):524-531.</li>
        <li>Galderma Data on File [RD.27.SPR.203918].</li>
        <li> Galderma Data on File [RD.27.SPR.203917].</li>
        <li> Galderma Data on File [RD.53.SPR.203908].</li>
        <li> Galderma Data on File [RD.53.SPR.203914].</li>
        <li> Galderma Data on File [Claims Master Reference]. </li>
        <li> Galderma Data on File [Clear project Final Clinical Results on 5 Products].</li>
        <li> Galderma Data on File [GLI.04.US.SL.012].</li>
        <li> 
          Del Rosso JQ, Bikowski JB, Baum E, et al. A closer look at truncal acne vulgaris: prevalence,
          severity, and clinical significance. J Drugs Dermatol.<br/>
          2007;6(6):597-600.
        </li>
        <li> 
          Galderma Laboratories, L.P. Burden Summary Report: Combined Facial & Truncal Acne. March 2021.
        </li>
        <li>
           Huynh TT. Burden of disease: the psychosocial impact of rosacea on patient’s quality of life.
           Am Health Drugs Benefits. 2013;6(6):348-354.
        </li>
        <li> 
          Del Rosso JQ, Tanghetti EA, Baldwin HE, Rodriguez DA, Ferrusi IL. The Burden of illness of 
          erythematotelangiectatic rosacea and papulopustular rosacea: findings from a web-based survey. 
          J Clin Aesthet Dermatol. 2017;10(6):17-31.
        </li>
        <li> 
          Tan J, Steinhoff M, Harper J, Gieler U, Tan J. Beyond the visible: rosacea and psoriasis of the face. 
          The BMJ Hosted Content 2020. Accessed January 2022. https://hosted.bmj.com/rosaceabeyondthevisible.
        </li>
        <li> 
          Steinhoff M, Harper J, Gieler U, Tan J. Beyond the visible: rosacea and psoriasis of the face. 
          The BMJ Hosted Content 2020. Accessed January 2022. https://hosted.bmj.com/rosaceabeyondthevisible.
        </li>
        <li> 
          Weidinger S. Novak N. Atopic dermatitis. Lancet 2016. 387: 1109-1122.
        </li>
        <li> 
          Irish Skin Foundation’s ‘Living with Atopic Eczema Survey’ 2019. Murray G, Dolan M, Greenwood M, 
          McMahon D, Watson R, O’Kane M. How Does Eczema Impact Your Life? A Real World Survey in Ireland. 
          Irish Association of Dermatologists Burrow’s Cup – Oral Presentations. Irish Association of Dermatologists 
          Spring Meeting 2019. 
        </li>
        <li> 
          Yang EJ, Beck KM, Sekhon S, Bhutani T, Koo J. The impact of pediatric atopic dermatitis on families: 
          A review. Pediatric Dermatology 2018. 36: 66-71.
        </li>
        <li>Galderma Data on File. Fort Worth, TX: Galderma Laboratories, L.P.</li>
      </ol>
    </div>
    <div class="galderma-logo">
      <img src="@/assets/images/svg/galderma-black.svg" class="galderma-logo" width="180">
    </div>
    <div class="copyright">
      <p class="f-light">
        ©2023 Galderma Laboratories, L.P. All trademarks are the property of their respective owners.
      </p>
      <p class="f-light">
        Galderma Laboratories, L.P., 14501 N. Freeway, Fort Worth, TX 76177
        <span>US-CET-2100453</span> <span>12/23</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.references > .modal-layout__content {
    background: url(~@/assets/images/jpg-png/upper-bg-home.png) no-repeat;
    border: 1px solid #fff;
    border-radius: 8px;
    .content {
      margin: 18px;
      .galderma-logo {
        margin-top:257px;
        margin-left: 20px;
      }
      h1 {
        font-size: 24px;
        margin-left: 10px;
      }
    }
}
.references {
  .modal-layout__close {
    top: 18px;
  }
  .modal-layout__content {
    width: 900px;
    height: 600px;
    top: 5px;
    left: 2px;
  }
  font-family: 'Sofia Pro';
  color: $main-font-color;

  .content {
    &__list {
      position: absolute;
      top: 25px;
      left: 37px;
      h1 {
        margin: 0 0 17px 2px;
        font-size: 23px;
        letter-spacing: 1.2px;
      }
      ol {
        margin: 0 0 15px;
        padding: 0;
        counter-reset: item;
        > li {
          font-size: 11px;
          font-weight: 300;
          line-height: 1.3;
          list-style-position: inside;
          margin: 0;
          padding: 0 0 0 3px;
          text-indent: -2px;
          list-style-type: none;
          counter-increment: item;
        }
      }
      ol > li:before {
        display: inline-block;
        width: 15px;
        padding-right: 5px;
        font-weight: bold;
        text-align: right;
        content: counter(item) '.';
      }
    }
    .copyright {
      position: absolute;
      top: 545px;
      left: 60px;
      p {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 13px;
        letter-spacing: 0.07px;
        span {
          &:nth-child(1) {
            margin: 0 7px;
          }
        }
      }
    }
  }
}
</style>
