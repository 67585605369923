<template>
  <page-layout>
    <div class="content slide-swipe">
      <div class="heading">
        <h1 class="main-section-title">Moisturizing Cream</h1>
        <p class="description">
          Ultra-rich and clinically tested to help skin repair after barrier damage
        </p>
      </div>
      <div class="back-button">
        <img
          src="@/assets/images/jpg-png/back-button.png"
          width="39.5"
          alt=""
          @click="goLastCategory"
          @touchend="goLastCategory"
        />
      </div>
      <div class="content-product-row">
        <div class="image-product">
          <img
            src="@/assets/images/jpg-png/moisturizing-cream-detail.png"
            alt=""
          />
        </div>
        <div class="feature-icons">
          <img src="@/assets/images/jpg-png/hidration-94.png" alt="" />
          <img src="@/assets/images/jpg-png/48-hours.png" alt="" />
          <img src="@/assets/images/jpg-png/sensitive-skin-icon.png" alt="" />
        </div>
        <div class="feature-info">
          <h3 class="f-bold">
            For dry to very dry, sensitive skin
          </h3>
          <img
            class="face-body-icons"
            src="@/assets/images/jpg-png/body-face-icons.png"
            alt=""
          />
          <div class="feature-info-skin-oil">
            <p class="f-light">
              94% of subjects said Cetaphil Moisturizing Cream <span class="f-bold uppercase">leaves their skin softer</span> immediately after application 
            </p>
          </div>
          <div class="feature-info-hours-barrier">
            <p class="f-light">
              Increases skin hydration over 48 hours after a single application
            </p>
            <img
              @click="openModal('MoisturizingCreamRestore')"
              @touchend="openModal('MoisturizingCreamRestore')"
              class="see-data-btn"
              src="@/assets/images/jpg-png/see-data-btn.png"
              width="75"
              alt=""
            />
          </div>
          <div class="feature-info-sensitive-skin">
            <p class="f-light">
              <span class="f-bold uppercase"
                >Completely restores the skin barrier</span
              >
              after 1 week
            </p>
            <img
              @click="openModal('MoisturizingCreamModalOne')"
              @touchend="openModal('MoisturizingCreamModalOne')"
              class="see-data-btn"
              src="@/assets/images/jpg-png/see-data-btn.png"
              width="75"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="product-nav">
        <ul>
          <li>
            <router-link
              :to="{ name: 'daily-moisturizers:moisturizing-cream-benefits' }"
              >Key Benefits</router-link
            >
          </li>
          <li>
            <router-link
              :to="{ name: 'daily-moisturizers:moisturizing-cream-features' }"
              >Key Features</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/common/PageLayout.vue';

export default {
  components: {
    PageLayout,
  },
  mounted() {
    this.$route.params.onSwipeLeft = this.onSwipeLeft;
    this.$route.params.onSwipeRight = this.onSwipeRight;
  },
  methods: {
    onSwipeLeft() {
      return this.$router.push({
        name: 'daily-moisturizers:moisturizing-cream-features',
      });
    },
    onSwipeRight() {
      return this.$router.push({
        name: 'daily-moisturizers:moisturizing-cream-benefits',
      });
    },
    openModal(modalName) {
      this.toggleModal(modalName);
    },
  },
};
</script>

<style lang="scss">
.moisturizing-cream-benefits {
  background: url(~@/assets/images/jpg-png/upper-bg-home.png) no-repeat;
  background-size: 100%;
  .content {
    margin-left: 50.5px;
    margin-top: 38px;
    .heading {
      .description {
        sup {
          font-size: 55%;
        }
      }
    }
    .back-button {
      position: absolute;
      top: 139px;
    }
    &-product-row {
      display: flex;
      align-items: center;
      .image-product {
        margin-top: 19px;
        margin-left: 13px;
        img {
          width: 309px;
        }
      }
      .feature-icons {
        display: flex;
        flex-direction: column;
        margin-top: 114px;
        margin-left: 38px;
        margin-right: 47px;
        img {
          width: 100px;
          margin-bottom: 23px;
        }
      }
      .feature-info {
        position: relative;
        width: 326px;
        height: 360px;
        color: $main-font-color;
        .face-body-icons {
          width: 111px;
          position: absolute;
          right: -80px;
          top: 20px;
        }
        h3 {
          position: absolute;
          width: 100%;
          top: -4px;
          left: -6px;
          sup {
            font-size: 70%;
          }
        }
        .face-body-btns {
          position: absolute;
          display: flex;
          top: 20px;
          right: -54px;
          img:first-child {
            margin-right: 4px;
          }
        }
        &-skin-oil {
          position: absolute;
          border-bottom: 1px solid rgb(200, 221, 234);
          letter-spacing: -0.6px;
          width: 100%;
          top: 74px;
          left: -6px;
          padding-bottom: 22px;
          line-height: 1;
          sup {
            font-size: 60%;
          }
          .see-data-btn {
            position: absolute;
            right: -85px;
            top: -9px;
          }
        }
        &-hours-barrier {
          position: absolute;
          border-bottom: 1px solid rgb(200, 221, 234);
          width: 100%;
          bottom: 69px;
          left: -7px;
          letter-spacing: -0.3px;
          padding-bottom: 26px;
          sup {
            font-size: 60%;
          }
          .see-data-btn {
            position: absolute;
            right: -86px;
            top: 0px;
          }
          p {
            margin-bottom: 20px;
          }
          ul {
            margin: 0;
            padding: 0;
            li {
              font-family: 'Sofia Pro Light';
              list-style: none;
              margin-bottom: 5px;
              &:first-child {
                text-indent: -15px;
                margin-left: 13px;
                margin-bottom: 20px;
              }
              img {
                display: inline-block;
                margin-right: 3px;
              }
            }
          }
        }
        &-sensitive-skin {
          position: absolute;
          width: 100%;
          bottom: -19px;
          left: -7px;
          letter-spacing: -0.6px;
          sup {
            font-size: 60%;
          }
          .see-data-btn {
            position: absolute;
            right: -86px;
            top: 5px;
          }
        }
      }
    }
    .product-nav {
      position: absolute;
      z-index: 9999;
      bottom: 127px;
      left: 119px;
      border: 1px solid $light-blue;
      box-shadow: 0px 9px 14px rgba($color: $gray-backdrop, $alpha: 0.06);
      border-radius: 8px;
      overflow: hidden;
      ul {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
        li {
          list-style: none;
          > a {
            font-size: 12.5px;
            color: $main-font-color;
            padding: 10px 12.5px;
            line-height: 1;
            display: inline-block;
            font-family: 'Sofia Pro Bold';
          }
          > a.router-link-exact-active {
            background: $light-blue-2;
          }
          &:first-child {
            border-right: 1px solid $light-blue;
          }
        }
      }
    }
  }
}
</style>
