<template>
    <page-layout>
        <div class="content">
            <div class="heading">
                <h1 class="main-title">Proven Efficacy</h1>
                <h1 class="main-subtitle">Exceptional hydration. expertly tailored solutions</h1>
            </div>

            <div class="container">
                <div class="column frst-col">
                    <img 
                        src="@/assets/images/jpg-png/moisturizing-lotion-bottle.png"
                        height="268px"
                        alt="composition"
                    >
                </div>

                <div class="column scnd-col">
                    <div class="row">
                        <h2>Moisturizing Lotion</h2>
                        <p class="subtitle">Dry to normal, sensitive skin.<sup>13</sup></p>
                        <p class="desc">Lightweight & fast-absorbing, clinically<br>
                            proven to completely restore skin barrier.<sup>13</sup></p>
                    </div>
                    <div class="row img-txt-container">
                        <img 
                            src="@/assets/images/jpg-png/softer-icon.png"
                            alt="formulation 3"
                        >
                        <p>Provides IMMEDIATE RELIEF<br>
                            for sensitive skin<sup>18</sup></p>
                    </div>
                    <div class="row img-txt-container">
                        <img 
                            src="@/assets/images/jpg-png/48-hours-icon.png"
                            alt="formulation 3"
                        >
                        <p>Continuous, 48-HOUR HYDRATION<br>
                            after a single application<sup>18</sup></p>
                    </div>
                    <div class="row img-txt-container">
                        <img 
                            src="@/assets/images/jpg-png/barrier-icon.png"
                            alt="formulation 3"
                        >
                        <p>COMPLETELY RESTORES SKIN<br>
                            BARRIER after 1 week of daily use<sup>18</sup></p>
                    </div>
                </div>

                <div class="column three-col">
                    <h2>Hydration results 1 week<br>
                        post-application of Moisturizing Lotion<sup>14</sup></h2>
                    <img 
                        src="@/assets/images/jpg-png/hydration-results-week-1.png"
                        width="360px"
                        alt="composition"
                    >
                    <div class="results-footer">
                        <p>Baseline</p>
                        <p class="p-1">1 Week</p>
                    </div>
                </div>
            </div>

            <div class="buttons-container">
                <ul>
                    <li>
                        <router-link :to="{ name: 'homepage:exceptional-hydratation' }">
                            Gentle Skin Cleanser
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{ name: 'homepage:moisturizing-cream' }">
                            Moisturizing Cream
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{ name: 'homepage:moisturizing-lotion' }">
                            Moisturizing Lotion
                        </router-link>
                    </li>
                </ul>
            </div>

            <a @click="goPage('homepage:proven-efficacy')" class="link prev-link">   
                <img
                    src="@/assets/images/svg/prev-icon.svg"
                    alt="next icon"
                />
                <p class="f-bold">Proven Efficacy</p>
            </a>
        </div>
    </page-layout>
</template>

<script>
    import PageLayout from '@/components/common/PageLayout.vue';

    export default {
        components: {
            PageLayout,
        },
        mounted() {
            this.$route.params.onSwipeLeft = this.onSwipeLeft;
            this.$route.params.onSwipeRight = this.onSwipeRight;
        },
        methods: {
            goPage(name) {
                return this.$router.push({ name });
            },
            onSwipeLeft() {
                return this.$router.push({ name: 'daily-cleansers' });
            },
            onSwipeRight() {
                return this.$router.push({ name: 'homepage:proven-efficacy' });
            },
        }
    };
</script>

<style lang="scss">
    .moisturizing-lotion {
        background: url(~@/assets/images/jpg-png/upper-bg-home.png) no-repeat;
        background-size: 100%;
        .content {
            margin-left: 50.5px;
            margin-top: 60px;
            .heading {
                .main-title {
                    font-family: 'Sofia Pro Bold';
                    font-size: 27.5px;
                    text-transform: uppercase;
                    color: $main-font-color;
                    letter-spacing: -0.94px;
                    line-height: 1.1;
                    margin-top: 40px;
                }
                .main-subtitle {
                    font-family: 'Sofia Pro SemiBold';
                    font-size: 16px;
                    text-transform: uppercase;
                    color: $main-font-color;
                    font-weight: 700;
                }
                
            }
            .container {
                display: flex;
                justify-content: space-between;
                margin-left: -40px;
                .column {
                    flex: 1;
                    padding: 10px;
                    margin: 0 10px;
                }
                .row {
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 10px;
                }
                .frst-col {
                    justify-content: center;
                    display: flex;
                    margin-top: 50px;
                    img {
                        margin-right: 55px;
                    }
                }
                .scnd-col {
                    margin-left: -97px;
                    .img-txt-container {
                        display: -webkit-box;
                        margin-bottom: -5px;
                        img {
                            margin-top: 15px;
                            width: 44px
                        }
                        p {
                            color: $main-font-color;
                            font-size: 16px;
                            padding-left: 12px;
                            margin-bottom: -10px !important;
                            margin-top: 20px;
                        }
                    }
                    h2 {
                        color: $main-font-color;
                        font-size: 16px;
                        text-transform: uppercase;
                        margin-bottom: -8px;
                        font-family: 'Sofia Pro Bold';
                    }
                    p {
                        margin-bottom: -5px;
                        font-family: 'Sofia Pro Light';
                    }
                    .subtitle {
                        font-size: 16px;
                        color: $main-font-color;
                        font-weight: 500;
                        margin-bottom: 0px;
                        font-family: 'Sofia Pro Medium';
                    }
                    .desc {
                        font-size: 16px;
                        color: $main-font-color;
                        font-weight: 400;
                        line-height: 18px; 
                        font-family: 'Sofia Pro Light';
                    }
                }
                .three-col {
                    margin-right: 36px;
                    margin-top: -15px;
                    h2 {
                        color: $main-font-color;
                        font-size: 14px;
                        text-transform: uppercase;
                        text-align: center;
                        width: 365px;
                        font-family: 'Sofia Pro Bold';
                    }
                    .results-footer {
                        display: flex;
                        justify-content: space-around;
                        margin-top: -15px;
                        width: 110%;
                        margin-left: -12px;
                        p {
                            font-size: 14px;
                            color: #2F2F2F;
                            margin-left: -10px;
                            font-family: 'Sofia Pro';
                            margin-top: 20px;
                        }
                        .p-1 {
                            margin-right: 10px;
                        }
                    }
                }
                sup {
                    font-size: x-small;
                }
            }
            .link {
                p {
                    text-decoration: underline;
                    color: #63AF45;
                    font-size: 14px;
                }
                img {
                    padding-left: 10px;
                    padding-right: 10px;
                    padding-top: 5px;
                }
            }
            .prev-link {
                display: flex;
                justify-content: flex-start;
                margin-left: -8px;
                margin-top: 51px;
                
                position: absolute;
                z-index: 10000;
            }
            .buttons-container {
                position: absolute;
                z-index: 9999;
                bottom: 180px;
                left: 300px;
                border: 1px solid $light-blue;
                box-shadow: 0px 9px 14px rgba($color: $gray-backdrop, $alpha: 0.06);
                border-radius: 8px;
                overflow: hidden;
                ul {
                  display: flex;
                  align-items: center;
                  margin: 0;
                  padding: 0;
                  li {
                    list-style: none;
                    border-right: 1px solid #C7E5F4;
                    > a {
                      font-size: 12.5px;
                      color: $main-font-color;
                      padding: 10px 12.5px;
                      line-height: 1;
                      display: inline-block;
                      font-family: 'Sofia Pro Bold';
                    }
                    > a.router-link-exact-active {
                      background: $main-font-color;
                      color: #EEF6FF;
                    }
                    &:first-child {
                      border-right: 1px solid $light-blue;
                    }
                  }
                }
              }
        }
    }
</style>