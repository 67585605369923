<template>
  <div class="content">
    <h1 class="f-bold">
      Increases skin hydration up to 48 hours after<br />
      single application
    </h1>
    <h2>Hydration measurements vs control</h2>
    <img src="@/assets/images/svg/ml-modal-two-graph.svg" alt="" />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.moisturizinglotionmodaltwo {
  .modal-layout__content {
    padding: 25px 38px;
    h1 {
      font-size: 26px;
      margin: 0;
      letter-spacing: 0.23px;
      text-transform: uppercase;
      line-height: 31px;
      margin-top: 3px;
    }
    h2 {
      text-align: center;
      font-size: 18px;
      letter-spacing: -0.75px;
      margin: 31px 0 23px;
      color: $gray-second-text;
    }
    img {
      width: 576px;
      display: block;
      margin: 0 auto;
    }
  }
}
</style>
