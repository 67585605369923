<template>
  <page-layout>
    <div class="content slide-swipe">
      <div class="heading">
        <h1 class="main-title">Restoraderm soothing wash</h1>
        <p class="description">Designed for dry, stressed, sensitive skin</p>
      </div>
      <div class="back-button">
        <img
          src="@/assets/images/jpg-png/back-button.png"
          width="39.5"
          alt=""
          @click="goLastCategory" @touchend="goLastCategory"
        />
      </div>
      <div class="content-product-row">
        <div class="image-product">
          <img src="@/assets/images/jpg-png/soothing-wash-detail.png" alt="" />
        </div>
        <div class="feature-info">
          <h3 class="f-bold">Key Features</h3>
          <div class="key-features-info">
            <ul>
              <li>
                <img
                  src="@/assets/images/jpg-png/polygon-mark.png"
                  width="8"
                  alt=""
                />Fragrance free, paraben free, soap free, steroid free
              </li>
              <li>
                <img
                  src="@/assets/images/jpg-png/polygon-mark.png"
                  width="8"
                  alt=""
                />Non-comedogenic, hypoallergenic
              </li>
              <li>
                <img
                  src="@/assets/images/jpg-png/polygon-mark.png"
                  width="8"
                  alt=""
                />Patented Filaggrin Technology™
              </li>
              <li>
                <img
                  src="@/assets/images/jpg-png/polygon-mark.png"
                  width="8"
                  alt=""
                />Contains 9 moisturizers + Vitamin E & B3
              </li>
              <li>
                <img
                  src="@/assets/images/jpg-png/polygon-mark.png"
                  width="8"
                  alt=""
                />Received the NEA seal of acceptance
              </li>
            </ul>
          </div>
          <div class="ingredients-info">
            <h3 class="f-bold">Ingredients</h3>
            <p>
              Water, Sodium Trideceth Sulfate, Butyrospermum Parkii <br />
              (Shea) Butter, Glycerin, Helianthus Annuus (Sunflower) Seed <br />
              Oil, Sodium Lauroamphoacetate, Sodium Chloride, <br />
              Cocamide MEA, Citric Acid, Tocopheryl Acetate, Niacinamide, <br />
              Sodium PCA, 1,2 Hexanediol, Caprylyl Glycol, Guar <br />
              Hydroxypropyltrimonium Chloride, Potassium Sorbate, <br />
              Allantoin, Disodium EDTA, Colloidal Oatmeal, Arginine.
            </p>
          </div>
        </div>
      </div>
      <div class="product-nav">
        <ul>
          <li>
            <router-link :to="{ name: 'derma-control:soothing-wash-benefits' }"
              >Key Benefits</router-link
            >
          </li>
          <li>
            <router-link :to="{ name: 'derma-control:soothing-wash-features' }"
              >Key Features</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/common/PageLayout.vue';

export default {
  components: {
    PageLayout,
  },
  mounted(){
    this.$route.params.onSwipeLeft = this.onSwipeLeft;
    this.$route.params.onSwipeRight = this.onSwipeRight;
  },
  methods: {
    onSwipeLeft() {
      return this.$router.push({name: 'derma-control:soothing-wash-features'});
    },
    onSwipeRight() {
      return this.$router.push({name: 'derma-control:soothing-wash-benefits'});
    }
  }
};
</script>

<style lang="scss">
.soothing-wash-features {
  background: url(~@/assets/images/jpg-png/upper-bg-home.png) no-repeat;
  background-size: 100%;
  .content {
    margin-left: 50.5px;
    margin-top: 38px;
    .heading {
      .main-title {
        font-family: 'Sofia Pro Bold';
        font-size: 27.5px;
        text-transform: uppercase;
        color: $main-font-color;
        letter-spacing: -0.94px;
        line-height: 1.1;
        margin-top: 40px;
      }
      .description {
        sup {
          margin-top: 25px;
          font-size: 55%;
        }
      }
    }
    .back-button {
      position: absolute;
      top: 152px;
    }
    &-product-row {
      display: flex;
      align-items: center;
      .image-product {
        margin-top: 90px;
        margin-right: 109px;
        margin-left: 11px;
        img {
          width: 309px;
        }
      }
      .feature-info {
        position: relative;
        margin-top: -8px;
        color: $main-font-color;
        width: 450px;
        height: 310px;
        h3 {
          position: absolute;
          width: 100%;
          top: -2px;
          left: 3px;
        }
        .key-features-info {
          border-bottom: 1px solid rgb(200, 221, 234);
          padding-bottom: 26px;
          letter-spacing: -0.4px;
          position: absolute;
          width: 92%;
          top: 64px;
          line-height: 1.12;
          ul {
            margin: 0;
            padding: 0;
            margin-top: -6px;
            li {
              font-family: 'Sofia Pro Light';
              list-style: none;
              sup {
                font-size: 40%;
              }
              img {
                display: inline-block;
                margin-right: 10px;
              }
            }
          }
        }
        .ingredients-info {
          padding-top: 25px;
          position: absolute;
          width: 100%;
          top: 181px;
          h3 sup {
            font-size: 60%;
          }
          p {
            font-family: 'Sofia Pro Light';
            line-height: 1.1;
            margin-top: 32px;
            letter-spacing: -0.5px;
          }
        }
      }
    }
    .product-nav {
      position: absolute;
      z-index: 9999;
      bottom: 127px;
      left: 119px;
      border: 1px solid $light-blue;
      box-shadow: 0px 9px 14px rgba($color: $gray-backdrop, $alpha: 0.06);
      border-radius: 8px;
      overflow: hidden;
      ul {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
        li {
          list-style: none;
          > a {
            font-size: 12.5px;
            color: $main-font-color;
            padding: 10px 12.5px;
            line-height: 1;
            display: inline-block;
            font-family: 'Sofia Pro Bold';
          }
          > a.router-link-exact-active {
            background: $light-blue-2;
          }
          &:first-child {
            border-right: 1px solid $light-blue;
          }
        }
      }
    }
  }
}
</style>
