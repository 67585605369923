<template>
  <div :class="className">
    <div class="logo">
      <secondary-navigation></secondary-navigation>
      <img
        src="@/assets/images/svg/cetaphil-logo.svg"
        width="98.4"
        alt="logo"
        @click="goHome()"
      />
    </div>
    <main>
      <slot></slot>
    </main>
  </div>
</template>
<script>
import SecondaryNavigation from '@/components/common/SecondaryNavigation.vue';
export default {
  components: {
    SecondaryNavigation
  },
  props: {
    navbarType: {
      type: String,
      default() {
        return 'a';
      },
    },
  },
  data: function() {
    return {
      patient: this.$store.state.patient,
    };
  },
  created: function() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'togglePatient') {
        this.patient = state.patient;
      }
    });
  },
  methods: {
    goHome: function() {
      return this.$router.push('/');
    },
  },
  computed: {
    className: function() {
      return [
        'page',
        this.patient,
        this.$route.name
          .replace(/([a-z])([A-Z])/g, '$1-$2')
          .replace(/\s+/g, '-')
          .toLowerCase()
          .split(':'),
      ];
    },
  },
};
</script>
<style lang="scss">
.page {
  // height: 768px - 44px iDash Area;
  height: 724px;
  overflow: hidden;
  position: relative;
  font-family: 'Sofia Pro';
  a {
    text-decoration: none;
  }
  .logo {
    position: absolute;
    top: 45px;
    right: 55px;
    z-index: 2;
  }
}
</style>
