<template>
  <div :class="className" v-if="show">
    <div class="modal-layout__backdrop" @click="close"></div>
    <div class="modal-layout__content">
      <a class="modal-layout__close" @click="close"></a>
      <main>
        <slot> Please add some content to the modal. </slot>
      </main>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    onClose: Function,
    show: Boolean,
  },
  data: function() {
    return {};
  },
  methods: {
    close: function() {
      this.onClose();
    },
  },
  computed: {
    className: function() {
      return [
        "modal-layout",
        "modal-layout-" +
          this.$route.name
            .replace(/([a-z])([A-Z])/g, "$1-$2")
            .replace(/\s+/g, "-")
            .toLowerCase()
            .split(":"),
        this.$store.state.modal.component.toLowerCase(),
        this.show ? "modal-layout--active" : undefined,
      ];
    },
    currentPage() {
      return this.$route.name;
    },
  },
};
</script>
<style lang="scss">
.modal-layout {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  outline: 0;
  height: 768px;
  overflow: hidden;
  z-index: 9999999999;
  align-items: center;
  justify-content: center;
  &--active {
    display: flex;
  }
  &__close {
    position: absolute;
    top: 13px;
    right: 14px;
    z-index: 100;
    background: transparent
      url("~@/assets/images/svg/close-circle-outline-dark.svg") no-repeat;
    width: 30px;
    height: 30px;
    background-size: cover;
    .light {
      display: none;
    }
  }
  &__backdrop {
    z-index: 9999999999;
    background: #00325761 0% 0% no-repeat padding-box;
    opacity: 0.75;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
  }
  &__content {
    background: transparent linear-gradient(180deg, #e6f5fb 0%, #ffffff 100%) 0%
      0% no-repeat padding-box;
    width: 855px;
    height: 600px;
    position: relative;
    top: 0px;
    border: 0;
    left: 0px;
    font-family: "Sofia Pro";
    color: $dark-blue;
    border-radius: 5px;
    box-sizing: border-box;
  }
}
</style>
