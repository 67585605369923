<template>
  <div class="content">
    <h1 class="f-bold uppercase">Improves water retention for 48-hour hydration<br />when used without water</h1>
    <div class="content-chart" style="display:flex; justify-content:center;">
        <img src="@/assets/images/jpg-png/gsc-modal-2-chart-tab1.png" width="480" alt=""/>
    </div>
  </div>
</template>

<script>
export default {
};
</script>

<style lang="scss">
.gsc-modal-tab-1 {
  font-family: 'Sofia Pro';
  color: $main-font-color;
}
</style>