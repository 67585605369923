<template>
  <div class="content">
    <h1 class="f-bold">
      SIGNIFICANTLY HYDRATES THE SKIN IN AS LITTLE AS 1 HOUR AND FOR UP TO 48 HOURS AFTER A SINGLE APPLICATION 
    </h1>
    <img src="@/assets/images/svg/restoraderm-flareup-see-more-data.svg" alt="" />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.restoradermflareupseemoredatamodal {
  .modal-layout__content {
    padding: 25px 38px;
    h1 {
      font-size: 26px;
      margin: 0;
      letter-spacing: 0.2px;
      text-transform: uppercase;
      line-height: 30px;
      margin-top: 4px;
    }
    img {
      width: 500px;
      display: block;
      margin: 0 auto;
      margin-top: 40px;
      margin-bottom: 0;
    }
  }
}
</style>
