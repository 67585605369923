<template>
  <page-layout>
    <div class="container slide-swipe">
      <div class="heading">
        <h1 class="main-section-title">
          restoraderm<br>soothing moisturizing lotion
        </h1>
        <p class="description">
          This nourishing lotion is clinically proven to lock in moisture and hydrate itchy,<br>
          eczema prone skin for up to 48 hours.
        </p>
      </div>
      <div class="back-button">
        <img
          src="@/assets/images/jpg-png/back-button.png"
          width="39.5"
          alt=""
          @click="goLastCategory"
          @touchend="goLastCategory"
        />
      </div>
      <div class="container-product-row">
        <div class="image-product">
          <img
            src="@/assets/images/jpg-png/restoraderm-soothing-moisturizer-tag.png"
            alt=""
          />
        </div>
        <div class="feature-icons">
          <img src="@/assets/images/jpg-png/48-hours.png" alt="" />
          <img src="@/assets/images/jpg-png/healthy-looking.png" alt="" />
          <img src="@/assets/images/jpg-png/84-percent.png" alt="" />
        </div>
        <div class="feature-info">
          <h3 class="f-bold">For eczema symptoms, sensitive skin
            <img
              class="face-body-icons"
              src="@/assets/images/jpg-png/body-icon.png"
              alt=""
            />
          </h3>
          <div class="feature-info-skin-oil">
            <p class="f-light">
              <span class="f-bold">FAST AND LONG-LASTING HYDRATION</span><br><br>
              Clinically proven to lock in moisture and<br>hydrate itchy, eczema prone skin for up to 48 hours
            </p>
          </div>
          <div class="feature-info-hours-barrier">
            <p class="f-light">
              <span class="f-bold">SKIN BARRIER IMPROVEMENT</span><br><br>
              Significantly improves skin barrier function for up to 4 hours
            </p>
          </div>
          <div class="feature-info-sensitive-skin">
            <p class="f-light">
              <span class="f-bold">MORE TIME FLARE-FREE FOR PATIENTS</span><br><br>
              84% of subjects noticed fewer eczema<br>flare-ups after 4 weeks of use
            </p>
          </div>
          <div class="feature-info-hidratation-study">
            <img
              src="@/assets/images/svg/head-to-head-data-button.svg"
              alt="hidratation study button"
              @click="openModal('SoothingMoisturizingLotionHandToHandModal')"
              @touchend="openModal('SoothingMoisturizingLotionHandToHandModal')"
            />
          </div>
        </div>
        <div class="feature-btn">
          <img
            @click="openModal('SoothingMoisturizingLotionModal')"
            @touchend="openModal('SoothingMoisturizingLotionModal')"
            class="see-data-2"
            src="@/assets/images/jpg-png/see-data-img.png"
            width="70"
            alt=""
          />
        </div>
      </div>
      <div class="product-nav">
        <ul>
          <li>
            <router-link
              :to="{ name: 'derma-control:restoraderm-eczema-benefits' }"
              >Key Benefits</router-link
            >
          </li>
          <li>
            <router-link
              :to="{ name: 'derma-control:restoraderm-eczema-features' }"
              >Key Features</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/common/PageLayout.vue';
export default {
  components: {
    PageLayout,
  },
  mounted() {
    this.$route.params.onSwipeLeft = this.onSwipeLeft;
    this.$route.params.onSwipeRight = this.onSwipeRight;
  },
  methods: {
    onSwipeLeft() {
      return this.$router.push({
        name: 'derma-control:restoraderm-eczema-features',
      });
    },
    onSwipeRight() {
      return this.$router.push({
        name: 'derma-control:restoraderm-eczema-benefits',
      });
    },
    openModal(modalName) {
      this.toggleModal(modalName);
    },
  },
};
</script>

<style lang="scss">
.restoraderm-eczema-benefits {
  background: url(~@/assets/images/jpg-png/upper-bg-home.png) no-repeat;
  background-size: 100%;
  .container {
    margin-left: 50.5px;
    margin-top: 38px;
    .back-button {
      position: absolute;
      top: 180px;
    }
    &-product-row {
      display: flex;
      align-items: center;
      .image-product {
        margin-top: 10px;
        margin-left: 11px;
        img {
          width: 308px;
        }
      }
      .feature-icons {
        display: flex;
        flex-direction: column;
        margin-top: 65px;
        margin-left: 40px;
        margin-right: 47px;
        img {
          width: 100px;
          margin-bottom: 23px;
        }
      }
      .feature-info {
        position: relative;
        width: 326px;
        height: 360px;
        margin-top: -45px;
        color: $main-font-color;
        z-index: 9999;
        .face-body-icons {
          width: 55px;
          position: absolute;
          right: -94px;
          top: 5px;
        }
        h3 {
          position: absolute;
          width: 100%;
          top: -25px;
          left: -7px;
        }
        &-skin-oil {
          position: absolute;
          border-bottom: 1px solid rgb(200, 221, 234);
          letter-spacing: -0.5px;
          width: 100%;
          top: 38px;
          left: -6px;
          padding-bottom: 8px;
        }
        &-hours-barrier {
          position: absolute;
          border-bottom: 1px solid rgb(200, 221, 234);
          width: 100%;
          bottom: 66px;
          top: 178px;
          left: -7px;
          line-height: 18px;
          letter-spacing: -0.6px;
          padding-bottom: 30px;
          p {
            margin-bottom: 20px;
          }
          ul {
            margin: 0;
            padding: 0;
            li {
              font-family: 'Sofia Pro Light';
              list-style: none;
              margin-bottom: 5px;
              &:first-child {
                text-indent: -15px;
                margin-left: 13px;
                margin-bottom: 20px;
              }
              img {
                display: inline-block;
                margin-right: 3px;
              }
            }
          }
        }
        &-sensitive-skin {
          position: absolute;
          width: 100%;
          bottom: -2px;
          top: 300px;
          left: -7px;
          letter-spacing: -0.4px;
        }
        &-hidratation-study {
          margin-left: -15px;
          margin-top: 400px;
          img {
            margin-left: -5px;
            width: 220px;
          }
        }
      }
      .feature-btn {
        display: flex;
        flex-direction: column;
        margin-top: -115px;
        margin-left: 13px;
        .see-data-2 {
          margin-top: 225px;
          margin-bottom: 42px;
          margin-left: 10px;
        }
      }
    }
    .product-nav {
      position: absolute;
      z-index: 9999;
      bottom: 127px;
      left: 119px;
      border: 1px solid $light-blue;
      box-shadow: 0px 9px 14px rgba($color: $gray-backdrop, $alpha: 0.06);
      border-radius: 8px;
      overflow: hidden;
      ul {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
        li {
          list-style: none;
          > a {
            font-size: 12.5px;
            color: $main-font-color;
            padding: 10px 12.5px;
            line-height: 1;
            display: inline-block;
            font-family: 'Sofia Pro Bold';
          }
          > a.router-link-exact-active {
            background: $light-blue-2;
          }
          &:first-child {
            border-right: 1px solid $light-blue;
          }
        }
      }
    }
  }
}
</style>
